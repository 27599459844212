import Typography from '@mui/material/Typography';
import { IconButton, Grid, useMediaQuery, useTheme, InputAdornment, OutlinedInput } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import { Stack } from '@mui/system';
import { ArrowForwardIos } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'hooks/useTranslation';
import JobBasicDetail from './JobBasicDetailview';

export const JobDetailView: React.FC = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

    const spacingMD = matchDownMD ? 1 : 1.5;
    return (
        <Grid container sx={{ padding: 2 }}>
            <Grid item xs={12} rowSpacing={5}>
                <Grid container alignItems="center" justifyContent="space-between" spacing={matchDownMD ? 0.5 : 2}>
                    <Grid item rowSpacing={5}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <HomeIcon fontSize="small" />
                            <Typography variant="h4">Jobs</Typography>
                            <IconButton size="large" aria-label="go to shopping">
                                <ArrowForwardIos sx={{ width: '0.875rem', height: '0.875rem', fontWeight: 500, color: 'grey.500' }} />
                            </IconButton>
                            <Typography variant="h4">Information Technology</Typography>
                            <IconButton size="large" aria-label="go to shopping">
                                <ArrowForwardIos sx={{ width: '0.875rem', height: '0.875rem', fontWeight: 500, color: 'grey.500' }} />
                            </IconButton>
                            <Typography variant="h4">Senior Software Engineer</Typography>
                        </Stack>
                    </Grid>
                    <Grid item>
                        <Stack direction="row" alignItems="center" justifyContent="center" spacing={matchDownSM ? 0.5 : spacingMD}>
                            <OutlinedInput
                                id="input-search-header"
                                type="text"
                                placeholder={t('Search by Role, Title, Location, Industry')}
                                onChange={() => {}}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                                fullWidth
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
            <JobBasicDetail />
        </Grid>
    );
};
