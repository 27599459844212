import { Link } from 'react-router-dom';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Button, Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';

// project imports
import { DASHBOARD_PATH } from 'config';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';

// assets
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';

import image from 'assets/images/maintenance/page-not-found.svg';
import { useTranslation } from 'hooks/useTranslation';
import { HeaderWrapper } from '../landing';
import FooterSection from '../landing/FooterSection';
import AppBar from 'ui-component/extended/AppBar';

// styles
const CardMediaWrapper = styled('div')({
    maxWidth: 720,
    margin: '0 auto',
    position: 'relative'
});

const PageContentWrapper = styled('div')({
    maxWidth: 350,
    margin: '0 auto',
    textAlign: 'center'
});

const ConstructionCard = styled(Card)({
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 0
});

// const CardMediaBuild = styled('img')({
//     position: 'absolute',
//     top: 0,
//     left: 0,
//     width: '100%',
//     animation: '5s bounce ease-in-out infinite'
// });

// const CardMediaParts = styled('img')({
//     position: 'absolute',
//     top: 0,
//     left: 0,
//     width: '100%',
//     animation: '10s blink ease-in-out infinite'
// });

const SectionWrapper = styled('div')({
    paddingTop: 100,
    paddingBottom: 100
});

const UnderConstruction = () => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <>
            <HeaderWrapper id="home">
                <AppBar />
            </HeaderWrapper>
            <ConstructionCard>
                <CardContent>
                    <Grid container justifyContent="center" spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <CardMediaWrapper>
                                <CardMedia component="img" image={image} title={t('404 Not found')} />
                            </CardMediaWrapper>
                        </Grid>
                        <Grid item xs={12}>
                            <PageContentWrapper>
                                <Grid container spacing={gridSpacing}>
                                    <Grid item xs={12}>
                                        <Typography variant="h2" component="div">
                                            {t('Page not found')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body2">{t('This page might be deleted or not exist.')}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AnimateButton>
                                            <Button variant="contained" size="large" color="secondary" component={Link} to={DASHBOARD_PATH}>
                                                <HomeTwoToneIcon sx={{ fontSize: '1.3rem', mr: 0.75 }} /> Home
                                            </Button>
                                        </AnimateButton>
                                    </Grid>
                                </Grid>
                            </PageContentWrapper>
                        </Grid>
                    </Grid>
                </CardContent>
            </ConstructionCard>
            <SectionWrapper sx={{ bgcolor: theme.palette.mode === 'dark' ? 'background.default' : 'dark.900', pb: 0 }}>
                <FooterSection />
            </SectionWrapper>
        </>
    );
};

export default UnderConstruction;
