import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';
import Landing from 'views/pages/landing';
// import Register from 'views/pages/authentication/Register';
// import Language from 'ui-component/List/LanguageList';
// import Register3 from 'views/pages/authentication/Register3';
import ComingSoon1 from 'views/pages/maintenance/ComingSoon/ComingSoon1';

// login routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/Login3')));
// const AuthLogin2 = Loadable(lazy(() => import('views/pages/authentication/login')));
// // const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/Register3')));
// const AuthForgotPassword = Loadable(lazy(() => import('views/pages/authentication/ForgotPassword3')));
const ConversationalMockInterview = Loadable(lazy(() => import('views/pages/ConversationalMockInterview')));

const LoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/',
            element: <Landing />
        },
        {
            path: '/employer-login',
            element: <AuthLogin />
        },
        {
            path: '/jobseeker-login',
            element: <ComingSoon1 /> // <AuthLogin2 />
        },
        {
            path: '/all-supported-languages',
            element: <ComingSoon1 /> //<Language />
        },
        {
            path: '/employer/create',
            element: <ComingSoon1 /> // <Register />
        },
        {
            path: '/jobseeker/create',
            element: <ComingSoon1 /> //<Register3 />
        },
        {
            path: '/forgot',
            element: <ComingSoon1 /> // <AuthForgotPassword />
        },
        {
            path: '/interview/autopilot/:key',
            element: <ConversationalMockInterview />
        }
    ]
};

export default LoginRoutes;
