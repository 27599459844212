import React from 'react';
// material-ui
import { useTheme, styled } from '@mui/material/styles';

// project imports
// import AppBar from 'ui-component/extended/AppBar';
import JobCategoriesSection from './JobCategoriesSection';
import LanguageSection from './LanguageSection';
import StartupProjectSection from './StartupProjectSection';
import HeaderSectionLatest from './HeaderSectionLatest';
// import allAvailableLanguages from 'assets/images/landing/all-available-languages-background.svg';
import { BenefitsSection } from './BenefitsSection';
import { CarrerStagesSection } from './CarrerStagesSection';

// custom style
export const HeaderWrapper = styled('div')(({ theme }) => ({
    overflowX: 'hidden',
    overflowY: 'clip',
    background:
        theme.palette.mode === 'dark'
            ? theme.palette.background.default
            : `linear-gradient(360deg, ${theme.palette.grey[100]} 1.09%, ${theme.palette.background.paper} 100%)`,
    [theme.breakpoints.down('md')]: {}
}));

const SectionWrapper = styled('div')({
    paddingTop: 100,
    paddingBottom: 100
});

const Landing: React.FC = () => {
    const theme = useTheme();

    return (
        <>
            <HeaderWrapper id="home">
                {/* <AppBar /> */}
                <HeaderSectionLatest />
            </HeaderWrapper>

            <SectionWrapper
                sx={{
                    bgcolor: theme.palette.mode === 'dark' ? 'dark.dark' : 'background.default',
                    // background: `url(${allAvailableLanguages})`,
                    backgroundSize: 'cover'
                }}
            >
                <LanguageSection />
            </SectionWrapper>

            <SectionWrapper sx={{ bgcolor: theme.palette.mode === 'dark' ? 'background.default' : 'grey.100' }}>
                <CarrerStagesSection />
            </SectionWrapper>

            <SectionWrapper sx={{ bgcolor: theme.palette.mode === 'dark' ? 'dark.dark' : 'background.default' }} id="solutions">
                <BenefitsSection />
            </SectionWrapper>

            <SectionWrapper sx={{ py: 0 }}>
                <StartupProjectSection />
            </SectionWrapper>

            <SectionWrapper sx={{ bgcolor: theme.palette.mode === 'dark' ? 'dark.dark' : 'background.default' }}>
                <JobCategoriesSection />
            </SectionWrapper>
        </>
    );
};

export default Landing;
