export const useGetAvailableLanguages = () => {
    return [
        { locale: 'en', language: 'English' },
        { locale: 'hi', language: 'हिन्दी' },
        { locale: 'te', language: 'తెలుగు' },
        { locale: 'kn', language: 'ಕನ್ನಡ' },
        { locale: 'ta', language: 'தமிழ்' },
        { locale: 'bn', language: 'বাংলা' },
        { locale: 'gu', language: 'ગુજરાતી' },
        { locale: 'mr', language: 'मराठी' },
        { locale: 'or', language: 'ଓଡ଼ିଆ' },
        { locale: 'pa', language: 'ਪੰਜਾਬੀ' },
        { locale: 'ml', language: 'മലയാളം' }
    ];
};
