// material-ui
import { Typography, Grid } from '@mui/material';

const Logo = () => {
    return (
        <Grid container spacing={1} sx={{ display: 'flex' }}>
            <Grid item sx={{ paddingLeft: 0 }}>
                <img src="/icon.png" width={30} alt="intellirecruit logo" />
            </Grid>
            <Grid item>
                <Typography
                    variant="h3"
                    sx={{
                        textDecoration: 'none !important',
                        paddingTop: 0.7,
                        marginLeft: 1,
                        display: { xs: 'none', lg: 'block' },
                        color: 'inherit'
                    }}
                >
                    Intellirecruit
                </Typography>
            </Grid>
        </Grid>
    );
};

export default Logo;
