import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from 'store';
import { httpService } from 'utils/http-service';
import { Pagination } from 'types/pagination';
import { IJobListing } from 'types/jobs';
import { openSnackbar } from 'store/slices/snackbar';

const initialState = {
    jobs: [] as IJobListing[],
    pageInfo: {} as Pagination,
    loading: false,
    error: undefined as any
};

const slice = createSlice({
    name: 'jobs',
    initialState: initialState,
    reducers: {
        startLoading(state) {
            state.loading = true;
        },
        hasError(state, action) {
            state.error = action.payload.error;
        },
        getJobsSuccess(state, action) {
            state.error = undefined;
            state.jobs = action.payload.data;
            state.pageInfo = action.payload.pageInfo;
        },
        postJobSuccess(state, action) {
            state.error = undefined;
            state.jobs.push(action.payload);
        },
        stopLoading(state) {
            state.loading = false;
        }
    }
});

export const getJobs = (pageSize: number = 10, offset: number = 0) => {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await httpService.get(`/jobs/${pageSize}/${offset}`);
            dispatch(slice.actions.getJobsSuccess(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
        dispatch(slice.actions.stopLoading());
    };
};

export const createJob = (job: Partial<IJobListing>) => {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await httpService.post('/job', job);
            dispatch(slice.actions.postJobSuccess(response?.job));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Job Created successfully.',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Job Failed to save.',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    }
                })
            );
        }
        dispatch(slice.actions.stopLoading());
    };
};

export default slice.reducer;
