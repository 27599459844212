import * as React from 'react';
import { DragDropContext, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';

export type DraggableListProps<T> = {
    items: T[];
    onDragEnd: OnDragEndResponder;
    renderItem: (item: T, index: number) => React.ReactNode;
};

export const DraggableList = <T extends unknown>({ items, onDragEnd, renderItem }: DraggableListProps<T>) => {
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-list">
                {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        {items.map((item, index) => renderItem(item, index))}
                        {/* {provided.placeholder} */}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};
