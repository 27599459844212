import React, { FunctionComponent } from 'react';
import styles from './Assessment.module.css';
import HomeIcon from './assets/Home.svg';
import NextIcon from './assets/Next.svg';
import RectangleComponent from './RectangleComponent/RectangleComponent';
import AddNewAssessment from './AddNewAssessment/AddNewAssessment';

const Assessment: FunctionComponent = () => {
    return (
        <div className={styles.assessmentContainer}>
            <div className={styles.breadcrumb}>
                <div className={styles.frameParent}>
                    <div className={styles.homeParent}>
                        <img className={styles.homeIcon1} alt="" src={HomeIcon} />
                        <div className={styles.assessments}>Assessments</div>
                    </div>
                    <img className={styles.nextIcon1} alt="" src={NextIcon} />
                    <div className={styles.assessments}>Create</div>
                </div>
            </div>
            <div className={styles.contentContainer}>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <AddNewAssessment />
                        <RectangleComponent />
                        <RectangleComponent />
                    </div>
                    <div className={styles.column}>
                        <RectangleComponent />
                        <RectangleComponent />
                        <RectangleComponent />
                    </div>
                    <div className={styles.column}>
                        <RectangleComponent />
                        <RectangleComponent />
                    </div>
                </div>
                {/* Add more rows as needed */}
            </div>
        </div>
    );
};

export default Assessment;
