import { IMiniMenuSectionStyles } from './MiniMenuSection.types';

export const getMiniMenuSectionStyles = (): IMiniMenuSectionStyles => {
    return {
        button: { textDecoration: 'None', display: 'flex' },
        container: { borderRadius: 2, color: 'primary', mr: 1 },
        icon: { fontSize: '0.875rem', verticalAlign: 'text-top' },
        text: { mt: 0.5, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'block' }
    };
};
