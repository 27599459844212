import {
    Box,
    Button,
    Drawer,
    Grid,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
    Typography,
    styled,
    useMediaQuery,
    useTheme
} from '@mui/material';
import React, { useState, useEffect } from 'react';
// import MainCard from 'ui-component/cards/MainCard';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '@mui/icons-material/Search';
import { appDrawerWidth, gridSpacing } from 'store/constant';
import { JobListingShimmer } from './JobListingShimmer';
import { JobCard } from 'ui-component/cards/JobCard';
import PerfectScrollbar from 'react-perfect-scrollbar';
import useConfig from 'hooks/useConfig';
import JobFilter from './JobFilter';
import TableRowsIcon from '@mui/icons-material/TableRows';
import GridViewIcon from '@mui/icons-material/GridView';
import {
    amber,
    blue,
    blueGrey,
    brown,
    common,
    cyan,
    deepOrange,
    deepPurple,
    green,
    grey,
    indigo,
    lightBlue,
    lightGreen,
    lime,
    orange,
    pink,
    purple,
    red,
    teal,
    yellow
} from '@mui/material/colors';

/**
 * Job List Container
 */
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{ open?: boolean }>(({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shorter
    }),
    marginRight: -appDrawerWidth,
    [theme.breakpoints.down('xl')]: {
        paddingRight: 0,
        marginRight: 0
    },
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.shorter
        }),
        marginRight: 0
    })
}));

export const JobListing: React.FC = () => {
    const theme = useTheme();
    const { borderRadius } = useConfig();

    const [isLoading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(false);
    }, []);

    // drawer
    const [open, setOpen] = useState(isLoading);

    const handleDrawerOpen = () => {
        setOpen((prevState) => !prevState);
    };

    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
    const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));

    const spacingMD = matchDownMD ? 1 : 1.5;

    // filter
    const initialState = {
        search: '',
        sort: 'high'
    };
    const [filter, setFilter] = useState(initialState);

    // search filter
    // const handleSearch = async (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
    //     const newString = event?.target.value;
    //     setFilter({ ...filter, search: newString! });
    // };

    // sort options
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openSort = Boolean(anchorEl);
    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // const filterIsEqual = (a1: any, a2: any) => a1 === a2;

    const SortOptions = [
        {
            value: 'high',
            label: 'Salary: High To Low'
        },
        {
            value: 'low',
            label: 'Salary: Low To High'
        },
        {
            value: 'popularity',
            label: 'Popularity'
        },
        {
            value: 'new',
            label: 'Fresh Arrivals'
        }
    ];

    const sortLabel = SortOptions.filter((items) => items.value === filter.sort);

    // sort filter
    const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: string) => {
        setFilter({ ...filter, sort: index });
        setAnchorEl(null);
    };

    const [jobCardGrid, setJobCardGrid] = React.useState(false);

    const colors = [
        common,
        common,
        amber,
        blue,
        common,
        common,
        blueGrey,
        brown,
        common,
        common,
        common,
        common,
        cyan,
        deepOrange,
        deepPurple,
        green,
        common,
        common,
        grey,
        indigo,
        lightBlue,
        common,
        common,
        lightGreen,
        lime,
        orange,
        pink,
        common,
        common,
        red,
        purple,
        common,
        common,
        common,
        common,
        teal,
        yellow
    ];

    const jobsList = (
        <>
            {colors.map((_value: any, _index: any) => (
                <Grid item xs={12} sm={jobCardGrid ? 4 : 12}>
                    <JobCard color={_value} />
                </Grid>
            ))}
        </>
    );

    const handleFilter = (type: string, params: string, rating?: number) => {
        setLoading(true);
        switch (type) {
            case 'search':
                setFilter({ ...filter, search: params });
                break;
            case 'sort':
                setFilter({ ...filter, sort: params });
                break;
            case 'reset':
                setFilter(initialState);
                break;
            default:
            // no options
        }
    };

    return (
        // <MainCard>
        <Grid container spacing={2} sx={{ background: grey[100] }}>
            <Grid item xs={12}>
                <Grid container alignItems="center" justifyContent="space-between" spacing={matchDownMD ? 0.5 : 2}>
                    <Grid item>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography variant="h4">Jobs</Typography>
                            <IconButton size="large" aria-label="go to shopping">
                                <ArrowForwardIosIcon sx={{ width: '0.875rem', height: '0.875rem', fontWeight: 500, color: 'grey.500' }} />
                            </IconButton>
                            <Typography variant="h4">All</Typography>
                        </Stack>
                    </Grid>
                    <Grid item>
                        <Stack direction="row" alignItems="center" justifyContent="center" spacing={matchDownSM ? 0.5 : spacingMD}>
                            <TextField
                                sx={{ width: { xs: '250', md: 'auto' } }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon fontSize="small" />
                                        </InputAdornment>
                                    )
                                }}
                                placeholder="Search Jobs"
                                size="small"
                                onChange={() => {}}
                            />

                            {/* <Typography sx={{ pl: 1.5, fontSize: '1rem', color: 'grey.500', fontWeight: 400 }}>|</Typography> */}

                            {/* <Button
                                    disableRipple
                                    onClick={handleDrawerOpen}
                                    color="secondary"
                                    startIcon={<FilterAltIcon sx={{ fontWeight: 500, color: 'secondary.200' }} />}
                                >
                                    Filter
                                </Button> */}

                            <Typography sx={{ display: { xs: 'none', sm: 'flex' }, fontSize: '1rem', color: 'grey.500', fontWeight: 400 }}>
                                |
                            </Typography>
                            <Tooltip title="Change Layout" placement="bottom">
                                <IconButton color="inherit" aria-label="Grid" onClick={() => setJobCardGrid(!jobCardGrid)}>
                                    {jobCardGrid ? <TableRowsIcon /> : <GridViewIcon />}
                                </IconButton>
                            </Tooltip>
                            <Typography sx={{ display: { xs: 'none', sm: 'flex' }, fontSize: '1rem', color: 'grey.500', fontWeight: 400 }}>
                                |
                            </Typography>
                            <Stack direction="row" alignItems="center" justifyContent="center" sx={{ display: { xs: 'none', sm: 'flex' } }}>
                                <Typography variant="h5">Sort by: </Typography>
                                <Button
                                    id="demo-positioned-button"
                                    aria-controls="demo-positioned-menu"
                                    aria-haspopup="true"
                                    aria-expanded={openSort ? 'true' : undefined}
                                    onClick={handleClickListItem}
                                    sx={{ color: 'grey.500', fontWeight: 400 }}
                                    endIcon={<KeyboardArrowDownIcon />}
                                >
                                    {sortLabel.length > 0 && sortLabel[0].label}
                                </Button>
                                <Menu
                                    id="demo-positioned-menu"
                                    aria-labelledby="demo-positioned-button"
                                    anchorEl={anchorEl}
                                    open={openSort}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right'
                                    }}
                                >
                                    {SortOptions.map((option, index) => (
                                        <MenuItem
                                            sx={{ p: 1.5 }}
                                            key={index}
                                            selected={option.value === filter.sort}
                                            onClick={(event) => handleMenuItemClick(event, option.value)}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
            {/* <Grid item xs={12}>
                    <Divider sx={{ borderColor: 'grey.400' }} />
                </Grid> */}
            <Grid item xs={12}>
                <Box sx={{ display: 'flex' }}>
                    <Drawer
                        sx={{
                            mr: open ? 3 : 0,
                            height: matchDownLG ? '100vh' : 'auto',
                            flexShrink: 0,
                            zIndex: { xs: 1200, lg: open ? 1200 : -1 },
                            overflowX: 'hidden',
                            width: appDrawerWidth,
                            '& .MuiDrawer-paper': {
                                height: 'auto',
                                width: appDrawerWidth,
                                position: matchDownLG ? 'fixed' : 'relative',
                                border: 'none',
                                borderRadius: matchDownLG ? 0 : `${borderRadius}px`
                            }
                        }}
                        variant={matchDownLG ? 'temporary' : 'persistent'}
                        anchor="left"
                        open={open}
                        ModalProps={{ keepMounted: true }}
                        onClose={handleDrawerOpen}
                    >
                        {open && (
                            <PerfectScrollbar component="div">
                                <JobFilter filter={filter} handelFilter={handleFilter} />
                            </PerfectScrollbar>
                        )}
                    </Drawer>
                    <Main open={open}>
                        {/* <ProductFilterView
                                filter={filter}
                                filterIsEqual={filterIsEqual}
                                handelFilter={handelFilter}
                                initialState={initialState}
                            /> */}
                        <Grid container spacing={gridSpacing}>
                            {isLoading
                                ? [1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
                                      <Grid key={item} item xs={12} sm={6} md={4} lg={3} sx={{ cursor: 'pointer' }}>
                                          <JobListingShimmer />
                                      </Grid>
                                  ))
                                : jobsList}
                        </Grid>
                    </Main>
                </Box>
            </Grid>
        </Grid>
        // </MainCard>
    );
};
