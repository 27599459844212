"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetUserAccountDetailsQuery = void 0;
const GetUserAccount_generated_1 = require("../../schema/queries/GetUserAccount.generated");
const useGetUserAccountDetailsQuery = (variables) => {
    const { loading, data, error } = (0, GetUserAccount_generated_1.useGetUserAccountQuery)({
        variables: variables.variables,
        fetchPolicy: variables.fetchPolicy
    });
    return { loading, data, error };
};
exports.useGetUserAccountDetailsQuery = useGetUserAccountDetailsQuery;
