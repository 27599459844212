import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'hooks/useTranslation';
import PublicIcon from '@mui/icons-material/Public';
import GroupIcon from '@mui/icons-material/Group';
const AboutView: React.FC = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    return (
        <Grid item xs={12}>
            <Card>
                <CardContent>
                    <Grid container rowSpacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h2">{t('About the company')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container rowSpacing={1}>
                                <Typography variant="body2">
                                    Microsoft Corporation, commonly known as Microsoft, is an American multinational technology company that
                                    specialises in developing, manufacturing, licensing, and selling a wide range of software products and
                                    services. Founded by Bill Gates and Paul Allen on April 4, 1975, Microsoft has grown to become one of
                                    the world's largest and most influential technology companies. In recent years, Microsoft has been
                                    focusing on expanding its presence in the cloud computing market with its Azure platform. Azure provides
                                    a wide range of cloud-based services, including virtual machines, storage, databases, and artificial
                                    intelligence tools, catering to businesses of all sizes. Microsoft has also made significant investments
                                    in the development of cutting-edge technologies such as artificial intelligence, machine learning, mixed
                                    reality, and quantum computing.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={6}>
                                <Typography variant="h4" sx={{ marginTop: 5 }}>
                                    {t('Primary Industry')}
                                </Typography>
                                <Typography variant="body1" sx={{ marginBottom: 5 }}>
                                    {t('Information Technology')}
                                </Typography>
                                <Typography variant="h4">{t('Locations')}</Typography>
                                <Typography variant="body1">{t('Hyderabad, Telangana, India')}</Typography>
                                <Typography variant="body1">{t('Bangalore, Karnataka, India')}</Typography>
                                <Typography variant="body1">{t('Noida, Uttar Pradesh, India')}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h4" sx={{ marginTop: 8 }}>
                                    <PublicIcon
                                        fontSize="inherit"
                                        sx={{
                                            color: theme.palette.primary.main,
                                            mr: 2,
                                            fontSize: '1.8rem',
                                            verticalAlign: 'text-top'
                                        }}
                                    />
                                    https://microsoft.com
                                </Typography>
                                <Typography variant="h4" sx={{ marginTop: 4 }}>
                                    <GroupIcon
                                        sx={{
                                            color: theme.palette.primary.main,
                                            mr: 2,
                                            fontSize: '1.8rem',
                                            verticalAlign: 'text-top'
                                        }}
                                    />
                                    {t('1,00,000+ Employees')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};
export default AboutView;
