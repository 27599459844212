export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export enum IGestureType {
    Click = 'CLICK',
    DoubleTap = 'DOUBLE_TAP',
    LongPress = 'LONG_PRESS',
    Pinch = 'PINCH',
    Swipe = 'SWIPE',
    Tap = 'TAP',
    Zoom = 'ZOOM'
}
export type Scalars = {
    ID: {
        input: string;
        output: string;
    };
    String: {
        input: string;
        output: string;
    };
    Boolean: {
        input: boolean;
        output: boolean;
    };
    Int: {
        input: number;
        output: number;
    };
    Float: {
        input: number;
        output: number;
    };
    JSON: {
        input: any;
        output: any;
    };
};

export interface ITelemetryAction {
    actionType?: InputMaybe<IGestureType>;
    gestureIndex?: String;
}
export interface ITelemetryComponent {
    currentComponent?: String;
    nextComponent?: String;
    previousComponent?: String;
}
export interface ITelemetryError {
    errorCode?: String;
    errorMessage?: String;
    errorStack?: String;
}
export interface ITelemetryEvent {
    eventData?: String;
    subEventData?: String;
}

export interface ITelemetryViewData extends ITelemetryComponent, ITelemetryMetadata, ITelemetryScenario, ITelemetryEvent, IUtm {}
export interface ITelemetryErrorData
    extends ITelemetryComponent,
        ITelemetryMetadata,
        ITelemetryScenario,
        ITelemetryEvent,
        ITelemetryError {}
export interface ITelemetryActionData
    extends ITelemetryComponent,
        ITelemetryMetadata,
        ITelemetryScenario,
        ITelemetryEvent,
        ITelemetryAction {}

export interface ITelemetryScenario {
    scenario?: String;
    scenarioData?: String;
}

export interface ITelemetryData {
    currentComponent?: String;
    nextComponent?: String;
    previousComponent?: String;
    errorCode?: String;
    errorMessage?: String;
    errorStack?: String;
    eventData?: String;
    subEventData?: String;
    scenario?: String;
    scenarioData?: String;
    campaign?: String;
    content?: String;
    medium?: String;
    source?: String;
    term?: String;
    browserName?: String;
    browserVersion?: String;
    environment?: String;
    host?: String;
    name?: String;
    platform?: String;
    sessionId?: String;
    time?: String;
    version?: String;
}

export enum ITelemetryLevel {
    Action = 'ACTION',
    Error = 'ERROR',
    View = 'VIEW'
}

export interface ITelemetryPayload {
    action?: InputMaybe<ITelemetryAction>;
    component?: InputMaybe<ITelemetryComponent>;
    error?: InputMaybe<ITelemetryError>;
    event?: InputMaybe<ITelemetryEvent>;
    level?: InputMaybe<ITelemetryLevel>;
    metadata?: InputMaybe<ITelemetryMetadata>;
    scenario?: InputMaybe<ITelemetryScenario>;
    utm?: InputMaybe<IUtm>;
}

export interface ITelemetryMetadata {
    browserName?: String;
    browserVersion?: String;
    environment?: String;
    host?: String;
    name?: String;
    platform?: String;
    sessionId?: String;
    time?: String;
    version?: String;
}

export interface ITelemetryScenario {
    scenario?: String;
    scenarioData?: String;
}
export interface IUtm {
    campaign?: String;
    content?: String;
    medium?: String;
    source?: String;
    term?: String;
}
