/**
 * axios setup to use mock service
 */

import axios from 'axios';
import { getAuth } from 'firebase/auth';

const auth = getAuth();

const axiosServices = axios.create({ baseURL: process.env.REACT_APP_SERVICE_BASE_URL || 'https://api.intellirecruit.co.in' });

// interceptor for http
axiosServices.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services')
);

// Add a request interceptor to add the Firebase user token to the request headers
axiosServices.interceptors.request.use(async (config) => {
    // Retrieve the Firebase user token
    const userToken = await auth.currentUser?.getIdToken(/* forceRefresh */ false);

    // Initialize the headers object if it doesn't exist
    config.headers = config.headers || {};

    if (userToken) {
        // Add the Firebase user token to the request headers
        config.headers.Authorization = `Bearer ${userToken}`;
    }

    return config;
});

export default axiosServices;
