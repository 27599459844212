// third-party
import { FormattedMessage } from 'react-intl';

import { NavItemType } from 'types';

// assets
import { IconApps, IconBuildingCommunity, IconUsers, IconReportAnalytics } from '@tabler/icons';

// constant
const icons = {
    IconApps,
    IconBuildingCommunity,
    IconUsers,
    IconReportAnalytics
};

const application: NavItemType = {
    id: 'Organization',
    title: <FormattedMessage id="Organization" />,
    icon: icons.IconApps,
    type: 'group',
    children: [
        {
            id: 'manage-account',
            title: <FormattedMessage id="Manage Account" />,
            type: 'item',
            icon: icons.IconBuildingCommunity,
            url: '/manage-account'
        },
        {
            id: 'directory',
            title: <FormattedMessage id="Directory" />,
            type: 'collapse',
            icon: icons.IconUsers,
            children: [
                {
                    id: 'manage-users',
                    title: <FormattedMessage id="Users" />,
                    type: 'item',
                    // icon: icons.IconBuildingCommunity,
                    url: '/manage-users'
                },
                {
                    id: 'manage-groups',
                    title: <FormattedMessage id="Groups" />,
                    type: 'item',
                    // icon: icons.IconBuildingCommunity,
                    url: '/manage-groups'
                }
            ]
        },
        {
            id: 'reports',
            title: <FormattedMessage id="Reports" />,
            type: 'item',
            url: '/reports',
            icon: icons.IconReportAnalytics,
            breadcrumbs: false
        }
    ]
};

export default application;
