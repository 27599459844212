import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import HomeIcon from '../assets/Home.svg';
import NextIcon from '../assets/Next.svg';

const BreadcrumbContainer = styled(Box)(({ theme }) => ({
    backgroundColor: '#eef2f6',
    width: '100%',
    height: 46,
    display: 'flex',
    flexDirection: 'row',
    boxSizing: 'border-box',
    padding: '0px 16px 8px 0px',
    alignItems: 'center',
    justifyContent: 'flex-start', // Adjust this to 'space-between' or 'flex-start' based on your preference
    textAlign: 'left',
    fontSize: 12,
    color: '#29314f',
    fontFamily: 'Roboto'
}));

const HomeParent = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start', // Adjust this to 'space-between' or 'flex-start' based on your preference
    gap: 14
});

const HomeIconImg = styled('img')({
    position: 'relative',
    width: 16,
    height: 16,
    overflow: 'hidden',
    flexShrink: 0
});

const NextIconImg = styled('img')({
    position: 'relative',
    width: 24,
    height: 24,
    overflow: 'hidden',
    flexShrink: 0
});

const BreadcrumbComponent = () => {
    return (
        <BreadcrumbContainer>
            <HomeParent>
                <HomeIconImg alt="" src={HomeIcon} />
                <Typography>Assessments</Typography>
            </HomeParent>
            <NextIconImg alt="" src={NextIcon} />
            <Typography>Unititled Assessment</Typography>
        </BreadcrumbContainer>
    );
};

export default BreadcrumbComponent;
