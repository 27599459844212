import React from 'react';
import { Grid } from '@mui/material';
import { IAddCardProps } from './AddCard.types';
import { useAddCardStyles } from './AddCard.styles';
import { gridSpacing } from 'store/constant';
import Chip from 'ui-component/extended/Chip';

export const AddCard: React.FC<IAddCardProps> = ({ icon, primaryText, styles: styleOverrides }) => {
    const styles = useAddCardStyles();
    return (
        <Grid container spacing={gridSpacing} sx={styles.rootContainer}>
            <Grid item xs={12} sx={styles.gridItemRootContainer}>
                <Chip chipcolor="text.primary" label={primaryText} variant="outlined" clickable />
            </Grid>
        </Grid>
    );
};
