import { Container, Grid, Stack, Typography, Box } from '@mui/material';
import React from 'react';

import Avatar from 'ui-component/extended/Avatar';

import { motion } from 'framer-motion';
import { useTranslation } from 'hooks/useTranslation';
import { useGetJobCategories } from './hooks/GetJobCategories';
// import Chip from 'ui-component/extended/Chip';

import { generateColor } from 'utils/generateColor';

interface IJobCategoryProps {
    index: number;
    title: string;
    description: string;
    icon: any;
    color1: string;
    color2: string;
}
const JobCategoryIcon = ({ title, icon, description, color1, color2 }: IJobCategoryProps) => {
    return (
        <motion.div
            initial={{ x: 0 }}
            transition={{ type: 'spring', stiffness: 50, damping: 20 }}
            style={{
                display: 'inline-block',
                padding: 5
            }}
        >
            <Stack
                style={{
                    padding: '10px',
                    // backgroundImage: `linear-gradient(rgba(${generateColor(title).light}, 0.8), rgba(${color2}, 0.8))`,
                    // color: 'white !important',
                    borderRadius: '10px'
                }}
            >
                <Grid container alignItems="center">
                    <Grid item xs={2}>
                        <Box sx={{ top: 10, color: color2 }}>{icon}</Box>
                    </Grid>
                    <Grid item xs zeroMinWidth>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <Typography
                                    align="left"
                                    variant="h4"
                                    component="a"
                                    href={`/job-templates/search?searchText=${title}`}
                                    target="_blank"
                                    sx={{ color: color2 }}
                                >
                                    {title}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography align="left" variant="caption">
                                    {description}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Stack>
        </motion.div>
    );
};
const JobCategoriesSection: React.FC = () => {
    const { t } = useTranslation();

    const jobCategories = useGetJobCategories();

    return (
        <Container>
            <Grid container justifyContent="center" spacing={{ xs: 3, sm: 5 }}>
                <Grid item xs={12}>
                    <Typography variant="h2" sx={{ fontSize: { xs: '1.5rem', sm: '2.125rem' }, textAlign: 'center', mb: 5 }}>
                        {t('Job Templates from various categories')}
                    </Typography>
                    <Typography variant="caption" sx={{ textAlign: 'center', mb: 5 }}>
                        {t('* click to see AI Generated Job Templates')}
                    </Typography>

                    <Stack direction="row" spacing={2}>
                        <Grid container justifyContent={'center'}>
                            {jobCategories.map((jobCategory, index) => (
                                <Grid item xs={12} sm={4} key={index}>
                                    <motion.div
                                        initial={{ opacity: 0, translateY: 550 }}
                                        animate={{ opacity: 1, translateY: 0 }}
                                        transition={{ type: 'spring', stiffness: 150, damping: 30, delay: 0.6 }}
                                    >
                                        <JobCategoryIcon
                                            index={index}
                                            title={jobCategory.title}
                                            description={jobCategory.description}
                                            icon={jobCategory.icon}
                                            color1={jobCategory.color1}
                                            color2={jobCategory.color2}
                                        />
                                    </motion.div>
                                </Grid>
                            ))}
                        </Grid>
                    </Stack>
                    <Typography variant="caption" sx={{ textAlign: 'center', mb: 5, alignContent: 'center' }}>
                        {t('* Above are the sample job roles in each category')}
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
};

export default JobCategoriesSection;
