import React, { useState } from 'react';
import { Typography, IconButton, styled } from '@mui/material';
import EditIcon from '../assets/Edit.svg';
import ShareIcon from '../assets/Share.svg';
import DescriptionIcon from '../assets/description.svg';
import ViewIcon from '../assets/View.svg';
import { useNavigate } from 'react-router-dom';

const RectangleDiv = styled('div')({
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '8px',
    padding: '8px',
    borderRadius: '8px',
    backgroundColor: 'white',
    width: '100%'
});
const UntitledAssessmentText = styled(Typography)({
    fontSize: 'var(--h3-bold-size)',
    lineHeight: '28px',
    fontFamily: 'var(--h3-bold)',
    color: 'var(--dark-option-level-04)',
    textAlign: 'left'
});
const GroupParent = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    flex: '1'
});

const ActionContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    cursor: 'pointer'
});

const StyledIconButton = styled(IconButton)({
    padding: '8px'
});

const StyledTypography = styled(Typography)({
    variant: 'body1'
});

const IconTextContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '5px'
});

const CreateQuestionComponent: React.FC = () => {
    const history = useNavigate();
    const [editableTitle, setEditableTitle] = useState('Untitled Assessment');
    const [isEditing, setIsEditing] = useState(false);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleTitleChange = (event: React.ChangeEvent<HTMLDivElement>) => {
        setEditableTitle(event.target.textContent || '');
    };

    const handleTitleBlur = () => {
        setIsEditing(false);
    };

    const handleInstructionsClick = () => {
        history('/assessment/create');
    };

    const handleManagePermissionsClick = () => {
        // Handle click event for Manage Permissions
    };

    const handlePreviewClick = () => {
        // Handle click event for Preview
    };

    return (
        <RectangleDiv>
            <GroupParent>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <StyledIconButton onClick={handleEditClick}>
                        <img className="editIcon1" alt="" src={EditIcon} />
                    </StyledIconButton>
                    {isEditing ? (
                        <UntitledAssessmentText
                            contentEditable
                            suppressContentEditableWarning
                            onBlur={handleTitleBlur}
                            onInput={handleTitleChange}
                        >
                            {editableTitle}
                        </UntitledAssessmentText>
                    ) : (
                        <UntitledAssessmentText>{editableTitle}</UntitledAssessmentText>
                    )}
                </div>
            </GroupParent>
            <ActionContainer>
                <div className="instructionsParent" onClick={handleInstructionsClick}>
                    <IconTextContainer>
                        <StyledIconButton>
                            <img className="description-icon" alt="" src={DescriptionIcon} />
                        </StyledIconButton>
                        <StyledTypography className="instructions">Instructions</StyledTypography>
                    </IconTextContainer>
                </div>
                <div className="shareParent">
                    <IconTextContainer>
                        <StyledIconButton>
                            <img className="shareIcon1" alt="" src={ShareIcon} />
                        </StyledIconButton>
                        <StyledTypography className="share">Share</StyledTypography>
                    </IconTextContainer>
                </div>
                <div className="managePermissionsParent" onClick={handleManagePermissionsClick}>
                    <IconTextContainer>
                        <StyledTypography className="manage-permissions">Manage Permissions</StyledTypography>
                        <StyledIconButton>{/* Add the appropriate icon for Manage Permissions */}</StyledIconButton>
                    </IconTextContainer>
                </div>
                <div className="previewParent" onClick={handlePreviewClick}>
                    <IconTextContainer>
                        <StyledIconButton>
                            <img className="viewIcon1" alt="" src={ViewIcon} />
                        </StyledIconButton>
                        <StyledTypography className="preview">Preview</StyledTypography>
                    </IconTextContainer>
                </div>
            </ActionContainer>
        </RectangleDiv>
    );
};

export default CreateQuestionComponent;
