import React, { useState, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import {
    Container,
    Typography,
    Grid,
    Button,
    CardMedia,
    Link,
    Stack,
    useTheme,
    Stepper,
    StepLabel,
    Step,
    IconButton,
    Divider
} from '@mui/material';
import { useTranslation } from 'hooks/useTranslation';
import EntryLevelJobSeeker from 'assets/images/landing/illustrations/entry-level-job-seeker.png';
import MidLevelJobSeeker from 'assets/images/landing/illustrations/mid-level-job-seeker.png';
import ExperiencedJobSeeker from 'assets/images/landing/illustrations/experienced-job-seeker.png';
import CarrerChangersJobSeeker from 'assets/images/landing/illustrations/carrer-changers-job-seeker.png';
import AnimateButton from 'ui-component/extended/AnimateButton';
import EastIcon from '@mui/icons-material/East';
import { IRIcon } from 'ui-component/Icons';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
        }
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
        }
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1
    }
}));

const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage: 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
    }),
    ...(ownerState.completed && {
        backgroundImage: 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)'
    })
}));

function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {
        1: <IRIcon iconName="school" />,
        2: <IRIcon iconName="work" />,
        3: <IRIcon iconName="how_to_reg" />,
        4: <IRIcon iconName="sync_alt" />
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

export const CarrerStagesSection: React.FC = () => {
    const { t } = useTranslation();

    const steps = useMemo(
        () => [
            t('For Entry-Level Job Seekers'),
            t('For Mid-Career Professionals'),
            t('For Experienced Executives'),
            t('For Career Changers')
        ],
        [t]
    );

    const [activeTab, setActiveTab] = useState<number>(1);
    const theme = useTheme();

    const handleTabClick = (id: number) => {
        setActiveTab(id);
    };

    const handleNext = () => {
        const val = (activeTab + 1) % 5;
        setActiveTab(val === 0 ? 1 : val);
    };
    const handlePrev = () => {
        const val = (activeTab - 1) % 5;
        setActiveTab(val === 0 ? 4 : val);
    };

    const listSX = {
        display: 'flex',
        alignItems: 'center',
        gap: '0.7rem',
        padding: '10px 0',
        fontSize: '1rem',
        color: theme.palette.grey[900],
        svg: { color: theme.palette.secondary.main }
    };

    const data = useMemo(
        () => [
            {
                step: 1,
                audienceType: 'entry-level',
                title: t('Entry-Level Job Seekers'),
                image: EntryLevelJobSeeker,
                description: t(
                    'Just starting your professional journey? Explore features tailored to help you navigate entry-level positions and build a strong foundation.'
                ),
                benefits: [
                    {
                        title: t('Guided Application Process'),
                        description: t(
                            'Receive step-by-step guidance through the application process, helping you understand what employers are looking for.'
                        ),
                        icon: 'directions'
                    },
                    {
                        title: t('Skill Development Roadmap'),
                        description: t(
                            'Access a personalized roadmap for skill enhancement based on the requirements of entry-level positions.'
                        ),
                        icon: 'school'
                    },
                    {
                        title: t('Internship Opportunities'),
                        description: t(
                            'Discover internships that provide valuable industry experience and set the foundation for your career.'
                        ),
                        icon: 'work'
                    },
                    {
                        title: t('Resume Building Tools'),
                        description: t(
                            'Access templates and tools to create a standout entry-level resume that highlights your potential.'
                        ),
                        icon: 'description'
                    },
                    {
                        title: t('Mentorship Matching'),
                        description: t('Connect with mentors who can provide guidance as you embark on your professional journey.'),
                        icon: 'group'
                    }
                ],
                knowMore: {
                    title: t('Know More'),
                    link: 'https://blog.intellirecruit.co.in/product-listing-entry-level-jobseekers-benefits-intellirecruit'
                }
            },
            {
                step: 2,
                audienceType: 'mid-career',
                title: t('Mid-Career Professionals'),
                image: MidLevelJobSeeker,
                description: t(
                    'Looking to advance your career? Explore features designed to support mid-career professionals in their pursuit of growth and development.'
                ),
                benefits: [
                    {
                        title: t('Tailored Job Recommendations'),
                        description: t('Receive job suggestions that align with your experience and career trajectory.'),
                        icon: 'work'
                    },
                    {
                        title: t('Skill Gap Analysis'),
                        description: t(
                            'Identify gaps in your skills and receive recommendations for courses and training to bridge those gaps.'
                        ),
                        icon: 'insights'
                    },
                    {
                        title: t('Networking Assistance'),
                        description: t('Leverage connections and expand your professional network to tap into hidden opportunities.'),
                        icon: 'group_add'
                    },
                    {
                        title: t('Project Showcase'),
                        description: t('Highlight your significant projects and accomplishments to demonstrate your expertise.'),
                        icon: 'folder_special'
                    },
                    {
                        title: t('Industry Insights'),
                        description: t('Stay updated on industry trends and changes that can impact your career progression.'),
                        icon: 'trending_up'
                    }
                ],
                knowMore: {
                    title: t('Know More'),
                    link: 'https://blog.intellirecruit.co.in/product-listing-mid-career-professionals-benefits-intellirecruit'
                }
            },

            {
                step: 3,
                audienceType: 'experienced',
                title: t('Experienced Executives'),
                image: ExperiencedJobSeeker,
                description: t(
                    'Ready to lead at the highest level? Explore features tailored to experienced executives seeking top-tier leadership roles.'
                ),
                benefits: [
                    {
                        title: t('Executive Search'),
                        description: t('Access exclusive job opportunities tailored for experienced executives in leadership roles.'),
                        icon: 'business_center'
                    },
                    {
                        title: t('Brand Strengthening'),
                        description: t(
                            'Enhance your personal brand to align with leadership qualities and position yourself for top-tier roles.'
                        ),
                        icon: 'star'
                    },
                    {
                        title: t('Thought Leadership Promotion'),
                        description: t('Share your insights and expertise through articles, webinars, and speaking engagements.'),
                        icon: 'public'
                    },
                    {
                        title: t('Networking with Decision Makers'),
                        description: t('Connect with industry decision makers and influencers to explore executive-level opportunities.'),
                        icon: 'people_alt'
                    },
                    {
                        title: t('Salary Benchmarking'),
                        description: t('Gain insights into executive compensation trends to negotiate competitive packages.'),
                        icon: 'monetization_on'
                    }
                ],
                knowMore: {
                    title: t('Know More'),
                    link: 'https://blog.intellirecruit.co.in/product-listing-executives-benefits-intellirecruit'
                }
            },
            {
                step: 4,
                audienceType: 'career-changer',
                title: t('Career Changers'),
                image: CarrerChangersJobSeeker,
                description: t(
                    'Transitioning to a new path? Explore features designed to assist career changers in successfully navigating a new professional direction.'
                ),
                benefits: [
                    {
                        title: t('Transferable Skill Analysis'),
                        description: t('Identify skills from your previous roles that can be valuable in your new career path.'),
                        icon: 'swap_horiz'
                    },
                    {
                        title: t('Reskilling Roadmap'),
                        description: t('Receive a customized plan for reskilling or upskilling to make a successful transition.'),
                        icon: 'timeline'
                    },
                    {
                        title: t('Industry Transition Support'),
                        description: t('Access resources, mentors, and job opportunities specifically tailored to career changers.'),
                        icon: 'business'
                    },
                    {
                        title: t('Storytelling Techniques'),
                        description: t('Learn how to effectively communicate your career change narrative to potential employers.'),
                        icon: 'book'
                    },
                    {
                        title: t('Transition Success Metrics'),
                        description: t('Track your progress and measure your successful transition into your new career.'),
                        icon: 'trending_up'
                    }
                ],
                knowMore: {
                    title: t('Know More'),
                    link: 'https://blog.intellirecruit.co.in/product-listing-career-changers-intellirecruit'
                }
            }
        ],
        [t]
    );

    return (
        <Container
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <Grid container justifyContent={'center'} textAlign={'center'} spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h1">{t('Tailored Solutions for Every Career Stage')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" sx={{ fontSize: '1.4rem' }}>
                        {t('Empowering Job Seekers with Customized Features')}
                    </Typography>
                </Grid>

                <Grid item xs={12} sx={{ mt: 5, borderRadius: 4, pb: 2, display: { xs: 'none', sm: 'block' } }}>
                    <Grid container>
                        <Grid item xs={1}>
                            <IconButton>
                                <ArrowBackIos fontSize="medium" onClick={handlePrev} />
                            </IconButton>
                        </Grid>
                        <Grid item xs={10}>
                            <Stepper alternativeLabel activeStep={activeTab - 1} connector={<ColorlibConnector />}>
                                {steps.map((label, index) => (
                                    <Step key={label} sx={{ cursot: 'pointer' }}>
                                        <StepLabel StepIconComponent={ColorlibStepIcon} onClick={() => handleTabClick(index + 1)}>
                                            <Typography variant="body1">{label}</Typography>
                                        </StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton>
                                <ArrowForwardIos fontSize="medium" onClick={handleNext} />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>

                {data
                    ?.filter((section) => section.step === activeTab)
                    .map((section, index) => (
                        <Grid item xs={12} sx={{ mt: 8, display: { xs: 'none', sm: 'block' } }} key={`carrerSection-${index}`}>
                            <Grid container spacing={12}>
                                <Grid item xs={12} md={6}>
                                    <Stack sx={{ mb: 5, mx: 'auto' }}>
                                        <CardMedia component="img" image={section.image} alt={section?.title} />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid container spacing={2.5} textAlign={'left'}>
                                        <Grid item xs={12}>
                                            <Typography variant="h2" sx={{ fontSize: { xs: '1.5rem', sm: '2.125rem' }, mb: 2 }}>
                                                {section?.title}
                                            </Typography>
                                            <Typography
                                                variant="subtitle2"
                                                color="text.primary"
                                                sx={{
                                                    fontSize: '1rem',
                                                    zIndex: '99',
                                                    width: { xs: '100%', sm: '100%', md: 'calc(100% - 20%)' }
                                                }}
                                            >
                                                {section?.description}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                {section?.benefits.map((benefit, bIndex) => (
                                                    <Grid item xs={6} sx={{ p: 1, wordWrap: 'break-word' }} key={`benefit-${bIndex}`}>
                                                        <Typography sx={listSX}>
                                                            <IRIcon iconName={benefit?.icon ?? ''} size={20} />
                                                            {benefit?.title}
                                                        </Typography>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                            <Stack direction="row">
                                                <AnimateButton>
                                                    <Button
                                                        startIcon={<EastIcon />}
                                                        sx={{ boxShadow: 'none', my: 4, backgroundColor: theme.palette.primary[800] }}
                                                        variant="contained"
                                                        component={Link}
                                                        href={section?.knowMore?.link}
                                                        target="_blank"
                                                    >
                                                        {section?.knowMore?.title}
                                                    </Button>
                                                </AnimateButton>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}

                {data.map((section, index) => (
                    <Grid item xs={12} sx={{ mt: 8, display: { sm: 'none' } }} key={`carrerSection-${index}`}>
                        <Grid container spacing={12} textAlign={'center'} justifyContent={'center'}>
                            <Grid item xs={12}>
                                <Typography variant="h2" sx={{ fontSize: { xs: '1.5rem', sm: '2.125rem' }, mb: 2 }}>
                                    {section?.title}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6} sx={{ pt: '0 !important' }}>
                                <Stack>
                                    <CardMedia component="img" image={section.image} alt={section?.title} />
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6} sx={{ pt: '0 !important' }}>
                                <Grid container spacing={2.5} textAlign={'left'}>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="subtitle2"
                                            color="text.primary"
                                            sx={{
                                                fontSize: '1rem',
                                                zIndex: '99',
                                                width: { xs: '100%', sm: '100%', md: 'calc(100% - 20%)' }
                                            }}
                                        >
                                            {section?.description}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container textAlign={'center'} justifyContent={'center'}>
                                            {section?.benefits.map((benefit, bIndex) => (
                                                <Grid item xs={12} sx={{ p: 0.5, wordWrap: 'break-word' }} key={`benefit-${bIndex}`}>
                                                    <Typography sx={listSX}>
                                                        <IRIcon iconName={benefit?.icon ?? ''} size={20} />
                                                        {benefit?.title}
                                                    </Typography>
                                                </Grid>
                                            ))}
                                        </Grid>
                                        <Stack direction="row">
                                            <AnimateButton>
                                                <Button
                                                    startIcon={<EastIcon />}
                                                    sx={{ boxShadow: 'none', my: 4, backgroundColor: theme.palette.primary[800] }}
                                                    variant="contained"
                                                    component={Link}
                                                    href={section?.knowMore?.link}
                                                    target="_blank"
                                                >
                                                    {section?.knowMore?.title}
                                                </Button>
                                            </AnimateButton>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider sx={{ pb: 2 }} />
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};
