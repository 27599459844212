import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import ComingSoon1 from 'views/pages/maintenance/ComingSoon/ComingSoon1';

const LazyLoadedComparison = Loadable(lazy(() => import('views/pages/landing/Comparison')));
const Mission = Loadable(lazy(() => import('views/pages/landing/Mission')));

const ContactUs = Loadable(lazy(() => import('views/pages/landing/ContactUs')));
const Survey = Loadable(lazy(() => import('views/pages/landing/Survey')));

const LazyLoadedJobTemplateDetailView = Loadable(lazy(() => import('views/pages/landing/job-templates/ConnectedJobTemplateDetailView')));
const LazyLoadedConnectedJobTemplates = Loadable(lazy(() => import('views/pages/landing/job-templates/ConnectedJobTemplates')));
const LazyLoadedConnectedJobTemplatesSearchResults = Loadable(
    lazy(() => import('views/pages/landing/job-templates/search-results/ConnectedJobTemplateSearchResults'))
);

const GuestRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <MinimalLayout />
        </NavMotion>
    ),
    children: [
        {
            path: '/comparison-with-others',
            element: <LazyLoadedComparison />
        },
        {
            path: '/job-template/:id/:title',
            element: <LazyLoadedJobTemplateDetailView />
        },
        {
            path: '/job-templates',
            element: <LazyLoadedConnectedJobTemplates />
        },
        {
            path: '/job-templates/:category',
            element: <LazyLoadedConnectedJobTemplatesSearchResults />
        },
        {
            path: '/job-templates/:category',
            element: <LazyLoadedConnectedJobTemplatesSearchResults />
        },
        {
            path: '/job-templates/search',
            element: <LazyLoadedConnectedJobTemplatesSearchResults />
        },
        {
            path: '/jobs/create',
            element: <ComingSoon1 />
        },
        {
            path: '/compare',
            element: <LazyLoadedComparison />
        },
        {
            path: '/contact-us',
            element: <ContactUs />
        },
        {
            path: '/pilot-launch-survey',
            element: <Survey />
        },
        {
            path: '/mission',
            element: <Mission />
        }
    ]
};

export default GuestRoutes;
