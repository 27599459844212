import React from 'react';
import { styled, useTheme } from '@mui/system';
import { Outlet } from 'react-router-dom';
import AppBar from 'ui-component/extended/AppBar';
import { HeaderWrapper } from 'views/pages/landing';
import FooterSection from 'views/pages/landing/FooterSection';
import { Grid } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'hooks/useTranslation';
// import { useGoogleOneTapLogin } from 'react-google-one-tap-login';
// import useAuth from 'hooks/useAuth';

const SectionWrapper = styled('div')({
    paddingTop: 80
});

const OutletWrapper = styled('div')(({ theme }) => ({
    paddingTop: 75,
    paddingBottom: 40,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.grey[100],
    zIndex: 0
}));

const MinimalLayout: React.FC = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    // const { googleOneTapSignIn } = useAuth();

    // useGoogleOneTapLogin({
    //     onError: (error: any) => console.log(error),
    //     onSuccess: (response: any) => {
    //         console.log(response);
    //     },
    //     googleAccountConfigs: {
    //         client_id: process.env.REACT_APP_GOOGLE_SIGN_IN_CLIENT_ID ?? '',
    //         callback: googleOneTapSignIn
    //     }
    // });
    return (
        <Grid>
            <Helmet>
                <meta name="title" content={t('Intellirecruit, AI Powered recruitment, insights, Analytics')} />
                <meta
                    name="description"
                    content={t(
                        'Intellirecruit is an advanced job recruitment platform that utilizes artificial intelligence (AI) and automated systems to transform the hiring process. It works by employing AI-driven candidate screening and matching algorithms to analyze extensive candidate data and identify the most suitable candidates for specific job openings. This automated approach saves recruiters time and effort by  providing them with a shortlist of qualified candidates quickly. We support Blue Collar jobs, White collar jobs, Gold collar jobs, Grey collar jobs, Pink collar jobs, Green Collar Jobs'
                    )}
                />
                <meta name="keywords" content={t('Job, recruitment, Aritificial Intelligence, resume builder')} />
                <meta name="author" content="Intellirecruit" />
            </Helmet>
            <HeaderWrapper id="home">
                <AppBar />
            </HeaderWrapper>
            <OutletWrapper>
                <Outlet />
            </OutletWrapper>
            <SectionWrapper sx={{ bgcolor: theme.palette.mode === 'dark' ? 'background.default' : 'dark.900', pb: 0 }}>
                <FooterSection />
            </SectionWrapper>
        </Grid>
    );
};

export default MinimalLayout;
