import React, { useState } from 'react';
import { Select, FormControl, MenuItem, SelectChangeEvent } from '@mui/material';

interface TypeComponentProps {
    options: string[];
    onChange: (index: number, value: string) => void;
    onTypeChange: (type: string) => void; // Add onTypeChange prop
}

const TypeComponent: React.FC<TypeComponentProps> = ({ options, onChange, onTypeChange }) => {
    const [type, setType] = useState<string | ''>('radio');

    const handleChange = (event: SelectChangeEvent<string>) => {
        setType(event.target.value);
        onTypeChange(event.target.value); // Call onTypeChange when type changes
    };

    return (
        <FormControl
            sx={{
                position: 'relative',
                borderRadius: 8,
                backgroundColor: '#eef2f6',
                width: 206,
                height: 39,
                marginLeft: 'auto',
                marginRight: '20px' // Move TypeComponent to the right
            }}
        >
            <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={type}
                label="Type"
                onChange={handleChange}
                sx={{
                    '& .MuiSelect-select:focus': {
                        backgroundColor: '#eef2f6'
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none' // Hide the border
                    }
                }}
            >
                <MenuItem value={'radio'}>Radio</MenuItem>
                <MenuItem value={'multipleChoice'}>CheckBox</MenuItem>
                <MenuItem value={'short'}>Short</MenuItem>
                <MenuItem value={'long'}>Long</MenuItem>
            </Select>
        </FormControl>
    );
};

export default TypeComponent;
