import React from 'react';
import { Modal } from 'ui-component/extended/Modal/Modal';
import { Grid, IconButton, ListItem, Tooltip, Typography } from '@mui/material';
import Chip from 'ui-component/extended/Chip';
import PushPinIcon from '@mui/icons-material/PushPin';
import { DraggableList } from 'ui-component/DraaggableList';
import { DropResult, Draggable } from 'react-beautiful-dnd';
import { reorder } from 'ui-component/helper';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemAvatar from '@material-ui/core/ListItemAvatar';
// import ListItemText from '@material-ui/core/ListItemText';
// import Avatar from '@material-ui/core/Avatar';
// import InboxIcon from '@material-ui/icons/Inbox';

const skillsMockData = [
    {
        label: 'Leadership and team management',
        id: 1,
        isFeatured: true
    },
    {
        label: 'Strategic planning and execution',
        id: 2,
        isFeatured: true
    },
    {
        label: 'Financial acumen and budget management',
        id: 3,
        isFeatured: true
    },
    {
        label: 'Effective communication and presentation',
        id: 4,
        isFeatured: true
    },
    {
        label: 'Problem-solving and decision-making',
        id: 5,
        isFeatured: true
    },
    {
        label: 'Change management and adaptability',
        id: 6,
        isFeatured: true
    },
    {
        label: 'Relationship building and stakeholder management',
        id: 7,
        isFeatured: false
    },
    {
        label: 'Analytical and critical thinking',
        id: 8,
        isFeatured: false
    },
    {
        label: 'Project management',
        id: 9,
        isFeatured: false
    },
    {
        label: 'Risk assessment and mitigation',
        id: 10,
        isFeatured: false
    }
];

interface ISkill {
    label: string;
    id: number;
    isFeatured: boolean;
}

export const SkillModal: React.FC = () => {
    const [skills, setSkills] = React.useState([...skillsMockData]);

    const changeStatus = (id: number, isFeatured: boolean) => {
        for (var i = 0; i < skills.length; i++) {
            if (skills[i]?.id === id) {
                skills[i].isFeatured = !isFeatured;
            }
        }
        setSkills([...skills]);
    };

    const onDragEnd = ({ destination, source }: DropResult) => {
        // dropped outside the list
        if (!destination) return;

        const newItems = reorder(skills, source.index, destination.index);

        setSkills(newItems);
    };

    const renderItem = (item: ISkill, index: number) => {
        return (
            <Draggable draggableId={`${item.id}`} index={index}>
                {(provided, snapshot) => (
                    // <ListItem ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    //     <ListItemAvatar>
                    //         <Avatar>
                    //             <InboxIcon />
                    //         </Avatar>
                    //     </ListItemAvatar>
                    //     <ListItemText primary={item.label} />
                    // </ListItem>
                    <ListItem ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        {item.label}
                    </ListItem>
                )}
            </Draggable>
        );
    };

    return (
        <Modal title="Skills" maxWidth="lg">
            <Grid container spacing={2} sx={{ pb: 2 }}>
                <Grid item>
                    <Typography variant="h5">Featured Skills</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                {skills
                    .filter((skill) => skill.isFeatured === true)
                    .map((skill) => (
                        <Grid item>
                            <Chip label={skill.label} chipcolor="primary" clickable />
                            <Tooltip title="Remove Featured" placement="bottom">
                                <IconButton sx={{ transform: 'rotate(45deg) translate(-34px, 12px)' }}>
                                    <PushPinIcon color="primary" onClick={() => changeStatus(skill.id, true)} />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    ))}
            </Grid>

            <Grid container spacing={2} sx={{ pb: 2, pt: 2 }}>
                <Grid item>
                    <Typography variant="h5">Skills</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                {skills
                    .filter((skill) => skill.isFeatured === false)
                    .map((skill) => (
                        <Grid item>
                            <Chip label={skill.label} chipcolor="secondary" clickable />
                            <Tooltip title="Make Featured" placement="bottom">
                                <IconButton sx={{ transform: 'rotate(45deg) translate(-34px, 12px)' }}>
                                    <PushPinIcon color="secondary" onClick={() => changeStatus(skill.id, false)} />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    ))}
            </Grid>

            <Grid container>
                <DraggableList items={skills} onDragEnd={onDragEnd} renderItem={renderItem} />
            </Grid>
        </Modal>
    );
};
