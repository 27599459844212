import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { useTranslation } from 'hooks/useTranslation';
import Chip from 'ui-component/extended/Chip';

const ExtraDetailView: React.FC = () => {
    const { t } = useTranslation();
    return (
        <Grid item xs={12}>
            <Card>
                <CardContent>
                    <Grid container rowSpacing={2}>
                        <Grid item xs={12}>
                            <Grid container rowSpacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h3">{t('Role Overview')}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        {t(
                                            'Software engineers are responsible for designing, developing, testing, and maintaining software systems that meet specific requirements and solve complex problems.Software engineers are involved in the design and development of software applications. They work closely with stakeholders to understand their requirements and translate them into technical specifications. They write code using programming languages and frameworks to create functional software solutions. Software engineers are problem solvers. They analyse user needs and design software solutions that address those needs effectively. They break down complex problems into smaller, manageable components and develop algorithms and logical solutions to solve them.'
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h3">{t('Responsibilities')}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        <Typography variant="body2">
                                            Designing and Development: Software engineers are involved in the design and development of
                                            software applications.
                                        </Typography>
                                        <Typography variant="body2">
                                            Problem Solving: Software engineers analyse user needs and design software solutions to address
                                            complex problems.
                                        </Typography>
                                        <Typography variant="body2">
                                            Programming Languages and Technologies: Software engineers are proficient in various programming
                                            languages and technologies.
                                        </Typography>
                                        <Typography variant="body2">
                                            Testing and Debugging: Software engineers write and execute test cases to ensure software
                                            quality and reliability.
                                        </Typography>
                                        <Typography variant="body2">
                                            Collaboration and Teamwork: Software engineers work in teams and collaborate with other
                                            professionals.
                                        </Typography>
                                        <Typography variant="body2">
                                            Continuous Learning: Software engineers continuously update their skills to adapt to new
                                            technologies.
                                        </Typography>
                                        <Typography variant="body2">
                                            Documentation and Communication: Software engineers document their code and communicate
                                            technical concepts effectively.
                                        </Typography>
                                        <Typography variant="body2">
                                            Problem Identification and Resolution: Software engineers identify and resolve issues in
                                            existing software systems.
                                        </Typography>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h3">{t('Decisions')}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        <Typography variant="body2">
                                            Selecting appropriate technologies and tools for software development.
                                        </Typography>
                                        <Typography variant="body2">
                                            Providing technical recommendations for solving complex problems.
                                        </Typography>
                                        <Typography variant="body2">
                                            Identifying areas for process improvement and implementing necessary changes.
                                        </Typography>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h3">{t('Requirements')}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        <Typography variant="body2">
                                            Strong proficiency in one or more programming languages such as Java, C++, or Python.
                                        </Typography>
                                        <Typography variant="body2">
                                            Experience with software development methodologies and best practices
                                        </Typography>
                                        <Typography variant="body2">Solid understanding of data structures and algorithms.</Typography>
                                        <Typography variant="body2">Excellent problem-solving and debugging skills.</Typography>
                                        <Typography variant="body2">Ability to work collaboratively in a team environment.</Typography>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h3">{t('Preferred')}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        <Typography variant="body2">
                                            Experience with web application development frameworks (e.g., React )
                                        </Typography>
                                        <Typography variant="body2">
                                            Knowledge of cloud platforms and services (e.g., AWS, Azure)
                                        </Typography>
                                        <Typography variant="body2">Understanding of agile development methodologies.</Typography>
                                        <Typography variant="body2">Experience with automated testing frameworks.</Typography>
                                        <Typography variant="body2">
                                            Understanding of security best practices in software development.
                                        </Typography>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h3">{t('Education')}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        <Typography variant="body2">
                                            Required: Bachelor's degree in Computer Science, Software Engineering, or a related field.
                                        </Typography>
                                        <Typography variant="body2">
                                            Preferred to have: Master's degree in Computer Science, Software Engineering, or a related field
                                        </Typography>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h3">{t('Skills')}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container rowSpacing={2}>
                                        <Grid item xs={2}>
                                            <Typography variant="h4">{t('Key Skills')}</Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Chip label={'Prgramming'} sx={{ mr: 1, mb: 1 }} />
                                            <Chip label={'Software Development'} sx={{ mr: 1, mb: 1 }} />
                                            <Chip label={'Databases'} sx={{ mr: 1, mb: 1 }} />
                                            <Chip label={'Data Structures'} sx={{ mr: 1, mb: 1 }} />
                                            <Chip label={'Algorithms'} sx={{ mr: 1, mb: 1 }} />
                                            <Chip label={'Problem Solving'} sx={{ mr: 1, mb: 1 }} />
                                            <Chip label={'SQL'} sx={{ mr: 1, mb: 1 }} />
                                            <Chip label={'Version Control'} sx={{ mr: 1, mb: 1 }} />
                                            <Chip label={'Communication'} sx={{ mr: 1, mb: 1 }} />
                                            <Chip label={'Problem Solving'} sx={{ mr: 1, mb: 1 }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container rowSpacing={2}>
                                        <Grid item xs={2}>
                                            <Typography variant="h4">{t('Good To Have')}</Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Chip label={'Containerisation'} variant="outlined" sx={{ mr: 1, mb: 1 }} />
                                            <Chip label={'Web Development'} variant="outlined" sx={{ mr: 1, mb: 1 }} />
                                            <Chip label={'Agile'} variant="outlined" sx={{ mr: 1, mb: 1 }} />
                                            <Chip label={'Testing'} variant="outlined" sx={{ mr: 1, mb: 1 }} />
                                            <Chip label={'Security'} variant="outlined" sx={{ mr: 1, mb: 1 }} />
                                            <Chip label={'Dev Ops'} variant="outlined" sx={{ mr: 1, mb: 1 }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};
export default ExtraDetailView;
