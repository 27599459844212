import { ApolloLink, createHttpLink, ApolloClient, InMemoryCache } from '@apollo/client';
import { getUserToken } from 'utils/getToken';
import { v4 as uuid } from 'uuid';
import { Headers } from 'constant';
import config from 'config';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

class GraphqlClient {
    headerLink = setContext(async (_, { headers }) => {
        const token = await getUserToken();
        return {
            headers: {
                [Headers.Authorization]: `Bearer ${token}`,
                [Headers.AcceptLanguage]: config.locale,
                ...headers
            }
        };
    });

    networkDurationLink = new ApolloLink((operation, forward) => {
        const clientRequestId = uuid();
        const startTime = Date.now();
        const operationName = operation.operationName;

        operation.setContext({
            startTime,
            operationName,
            clientRequestId
        });

        return forward(operation);
    });

    errorLink = onError(({ networkError, graphQLErrors }) => {
        if (networkError) {
            console.log(`[Network error]: ${networkError}`);
        }

        if (graphQLErrors) {
            graphQLErrors.forEach(({ message, locations, path }) =>
                console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
            );
        }
    });

    httpLink = createHttpLink({
        uri: process.env.REACT_APP_SERVICE_API_URL
    });

    getClient = () => {
        const cache = new InMemoryCache();

        // Check if there is cached data in local storage
        const cachedData = localStorage.getItem('ircache');
        if (cachedData) {
            cache.restore(JSON.parse(cachedData));
        }

        const client = new ApolloClient({
            cache,
            link: ApolloLink.from([this.errorLink, this.headerLink, this.networkDurationLink, this.httpLink])
        });

        // Save the cache to local storage whenever a query is executed
        client.onResetStore(() => {
            localStorage.setItem('ircache', JSON.stringify(cache.extract()));
            return new Promise<void>((resolve) => resolve());
        });

        return client;
    };
}

export const graphqlClient = new GraphqlClient();
