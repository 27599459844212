import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ShareIcon from '@mui/icons-material/Share';
import Stack from '@mui/material/Stack';
import Thankyou from 'assets/images/landing/thank-you.svg';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { useTranslation } from 'hooks/useTranslation';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    TelegramIcon,
    TelegramShareButton,
    LinkedinShareButton,
    LinkedinIcon,
    WhatsappShareButton,
    WhatsappIcon
} from 'react-share';
import { CardMedia } from '@mui/material';
import { useLocation } from 'react-router-dom';
import useConfig from 'hooks/useConfig';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}));

export const SharePlate = ({ onClose, data }: { onClose: () => void; data: any }) => {
    const location = useLocation();
    const config = useConfig();
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('referralCode');
    const shareUrl = data ? `${window.location.href}?hl=${config.locale}&referralCode=${data.userReferralCode}` : window.location.href;
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation();

    const handleClick = () => {
        navigator.clipboard
            .writeText(shareUrl)
            .then(() => {
                setOpen(true);
            })
            .catch((error) => {
                console.error('Failed to copy to clipboard:', error);
            });
    };

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <>
            <Card sx={{ width: 550 }}>
                <CardHeader
                    action={
                        <IconButton aria-label={t('close')} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    }
                    subheader={t('Thank you for sharing')}
                    title={t('We grow by helping others grow.')}
                />
                {data ? (
                    <Typography variant="h1" align="center" display={'flex'} flexDirection={'column'}>
                        <Typography variant="h3" marginBottom={5} align="center">{t('Here is your Referral Code')}</Typography>
                        {data.userReferralCode}
                    </Typography>
                ) : (
                    <CardMedia
                        component="img"
                        height="50"
                        image={Thankyou}
                        alt={t('Thank you')}
                        sx={{
                            overflow: 'hidden',
                            width: '200px',
                            height: '230px',
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}
                    />
                )}
                <Typography paddingX={'10px'} display={'flex'}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <IconButton aria-label={t('share')}>
                            <ShareIcon />
                        </IconButton>
                        {t('Share with')}
                    </Box>
                    <Typography variant="body1" marginLeft={'20px'} display={'flex'} flexDirection={'row'} color="text.secondary">
                        <Stack direction="row" spacing={2}>
                            <Item>
                                <EmailShareButton url={shareUrl}>
                                    <EmailIcon size={40} round={true} />
                                </EmailShareButton>
                            </Item>
                            <Item>
                                <FacebookShareButton url={shareUrl}>
                                    <FacebookIcon size={40} round={true} />
                                </FacebookShareButton>
                            </Item>
                            <Item>
                                <TelegramShareButton url={shareUrl}>
                                    <TelegramIcon size={40} round={true} />
                                </TelegramShareButton>
                            </Item>
                            <Item>
                                <LinkedinShareButton url={shareUrl}>
                                    <LinkedinIcon size={40} round={true} />
                                </LinkedinShareButton>
                            </Item>
                            <Item>
                                <WhatsappShareButton url={shareUrl}>
                                    <WhatsappIcon size={40} round={true} />
                                </WhatsappShareButton>
                            </Item>
                        </Stack>
                    </Typography>
                </Typography>
                <Typography display={'flex'} justifyContent={'center'} variant="overline">
                    {t('or')}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', margin: '10px' }}>
                    <Button variant="outlined" onClick={handleClick}>
                        <ContentCopyIcon fontSize="small" />
                        {t('Copy URL ')}
                    </Button>
                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                            {t('URL Copied')}
                        </Alert>
                    </Snackbar>
                </Box>
            </Card>
        </>
    );
};

const ShareCard = () => {
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <>
            <IconButton aria-label="save for later" onClick={handleOpen}>
                <ShareIcon />
            </IconButton>
            <Dialog sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                <SharePlate onClose={handleClose} data={''} />
            </Dialog>
        </>
    );
};

export default ShareCard;
