import { Typography, Grid, IconButton, useTheme } from '@mui/material';
import useConfig from 'hooks/useConfig';
import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { useProfileStyles } from './Profile.styles';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

export const CareerPreferences: React.FC = () => {
    const theme = useTheme();
    const { borderRadius } = useConfig();
    const styles = useProfileStyles(theme, borderRadius);

    return (
        <MainCard>
            <Grid container>
                <Grid item xs={10}>
                    <Typography variant="h4">Carrer Preferrences</Typography>
                </Grid>
                <Grid item xs={2}>
                    <IconButton color="primary" aria-label="Edit Current Industry">
                        <ModeEditIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={styles.basicInformationGrid}>
                <Typography color={'text.secondary'}>Current Industry</Typography>
                <Typography color={'text.primary'}>IT Industry</Typography>
            </Grid>
            <Grid item xs={12} sx={styles.basicInformationGrid}>
                <Typography color={'text.secondary'}>Preffered Department</Typography>
                <Typography color={'text.primary'}>Engineering</Typography>
            </Grid>
            <Grid item xs={12} sx={styles.basicInformationGrid}>
                <Typography color={'text.secondary'}>Preferred Job Type</Typography>
                <Typography color={'text.primary'}>Engineering</Typography>
            </Grid>
            <Grid item xs={12} sx={styles.basicInformationGrid}>
                <Typography color={'text.secondary'}>Preffered Employment Type</Typography>
                <Typography color={'text.primary'}>Full Time</Typography>
            </Grid>
            <Grid item xs={12} sx={styles.basicInformationGrid}>
                <Typography color={'text.secondary'}>Preferred Shift</Typography>
                <Typography color={'text.primary'}>9 AM - 5 PM</Typography>
            </Grid>
            <Grid item xs={12} sx={styles.basicInformationGrid}>
                <Typography color={'text.secondary'}>Preferred Location</Typography>
                <Typography color={'text.primary'}>Bengaluru, Karnataka</Typography>
            </Grid>
            <Grid item xs={12} sx={styles.basicInformationGrid}>
                <Typography color={'text.secondary'}>Expected Salary</Typography>
                <Typography color={'text.primary'}>42 Million</Typography>
            </Grid>
        </MainCard>
    );
};
