import React, { FunctionComponent } from 'react';
import styles from './CreateAssessment.module.css';
import HomeIcon from '../assets/Home.svg';
import NextIcon from '../assets/Next.svg';
import EditIcon from '../assets/Edit.svg';
import DescriptionIcon from '../assets/description.svg';
import ShareIcon from '../assets/Share.svg';
import ViewIcon from '../assets/View.svg';

const CreateAssessmentComponent: FunctionComponent = () => {
    return (
        <div>
            <div className={styles.breadcrumb}>
                <div className={styles.frameParent}>
                    <div className={styles.homeParent}>
                        <img className={styles.homeIcon1} alt="" src={HomeIcon} />
                        <div className={styles.assessments}>Assessments</div>
                    </div>
                    <img className={styles.nextIcon1} alt="" src={NextIcon} />
                    <div className={styles.assessments}>Unititled Assessment</div>
                </div>
            </div>
            <div className={styles.rectangleDiv}>
                <div className={styles.groupParent}>
                    <div className={styles.editParent}>
                        <img className={styles.editIcon1} alt="" src={EditIcon} />
                        <b className={styles.unititledAssessment}>Unititled Assessment</b>
                    </div>
                </div>
                <div className={styles.groupParent}>
                    <div className={styles.shareParent}>
                        <img className={styles.shareIcon1} alt="" src={ShareIcon} />
                        <div className={styles.share}> Share</div>
                    </div>
                    <div className={styles.instructionsParent}>
                        <div className={styles.instructions}>Instructions</div>
                        <img className={styles.descriptionIcon1} alt="" src={DescriptionIcon} />
                    </div>
                    <div className={styles.managePermissionsParent}>
                        <div className={styles.managePermissions}>Manage Permissions</div>
                        <img className={styles.friendsIcon1} alt="" src="" />
                    </div>
                    <div className={styles.previewParent}>
                        <div className={styles.preview}>Preview</div>
                        <img className={styles.viewIcon1} alt="" src={ViewIcon} />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default CreateAssessmentComponent;
