import { Button, Container, Grid, Stack, Typography } from '@mui/material';
import React from 'react';

import { motion } from 'framer-motion';
import Chip from 'ui-component/extended/Chip';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'hooks/useTranslation';

const languagesData = [
    {
        id: 22,
        language: 'English',
        jobSearch: 'Easy Job Search',
        countries: ['United States', 'United Kingdom', 'Canada', 'Australia', 'India', 'Nigeria', 'South Africa', 'New Zealand']
    },

    {
        id: 38,
        language: 'Hindi',
        jobSearch: 'आसान नौकरी खोज',
        countries: ['India']
    },

    {
        id: 95,
        language: 'Telugu',
        jobSearch: 'కష్టాల్ని ఉత్తేజించదగిన ఉద్యోగ శోధన',
        countries: ['India (Andhra Pradesh, Telangana)']
    },

    {
        id: 93,
        language: 'Tamil',
        jobSearch: 'எளிய வேலை தேடுக',
        countries: ['India (Tamil Nadu)', 'Sri Lanka']
    },

    {
        id: 48,
        language: 'Kannada',
        jobSearch: 'ಸುಲಭವಾಗಿ ನೌಕರಿ ಹುಡುಕಿ',
        countries: ['India (Karnataka)']
    },

    {
        id: 63,
        language: 'Malayalam',
        jobSearch: 'എളുപ്പത്തിൽ ജോലി തിരയുക',
        countries: ['India (Kerala)']
    },

    {
        id: 9,
        language: 'Bengali',
        jobSearch: 'সহজ চাকরি অনুসন্ধান',
        countries: ['Bangladesh', 'India (West Bengal)']
    },

    {
        id: 33,
        language: 'Gujarati',
        jobSearch: 'આસાન નોકરી શોધ',
        countries: ['India (Gujarat)']
    },

    {
        id: 66,
        language: 'Marathi',
        jobSearch: 'सोपी नोकरी शोधा',
        countries: ['India (Maharashtra)']
    },

    {
        id: 75,
        language: 'Punjabi',
        jobSearch: 'ਆਸਾਨ ਨੌਕਰੀ ਖੋਜ',
        countries: ['India (Punjab)', 'Pakistan']
    },
    {
        id: 1,
        language: 'Afrikaans',
        jobSearch: 'Maklike Werk Soek',
        countries: ['South Africa', 'Namibia']
    },
    {
        id: 2,
        language: 'Albanian',
        jobSearch: 'Kërkimi i Lehtë i Punes',
        countries: ['Albania', 'Kosovo', 'North Macedonia']
    },
    {
        id: 3,
        language: 'Amharic',
        jobSearch: 'በቀጥታ የሚሻማ ማዕከል',
        countries: ['Ethiopia']
    },
    {
        id: 4,
        language: 'Arabic',
        jobSearch: 'البحث عن وظيفة سهل',
        countries: ['Saudi Arabia', 'Egypt', 'Iraq', 'United Arab Emirates', 'Morocco', 'Jordan']
    },
    {
        id: 5,
        language: 'Armenian',
        jobSearch: 'Աշխատանքի առեւտրային որոնում',
        countries: ['Armenia']
    },
    {
        id: 6,
        language: 'Azerbaijani',
        jobSearch: 'Asan İş Axtar',
        countries: ['Azerbaijan']
    },
    {
        id: 7,
        language: 'Basque',
        jobSearch: 'Lan Bilatzea Erraza',
        countries: ['Spain (Basque Country)']
    },
    {
        id: 8,
        language: 'Belarusian',
        jobSearch: 'Пошук працы лёгкі',
        countries: ['Belarus']
    },
    {
        id: 10,
        language: 'Bosnian',
        jobSearch: 'Laka Potraga za Poslom',
        countries: ['Bosnia and Herzegovina']
    },
    {
        id: 11,
        language: 'Bulgarian',
        jobSearch: 'Лесно търсене на работа',
        countries: ['Bulgaria']
    },
    {
        id: 12,
        language: 'Catalan',
        jobSearch: 'Cerca de Feina Fàcil',
        countries: ['Spain (Catalonia)']
    },
    {
        id: 13,
        language: 'Cebuano',
        jobSearch: 'Huros Trabaho nga Sayon',
        countries: ['Philippines (Visayas and Mindanao regions)']
    },
    {
        id: 14,
        language: 'Chichewa (Nyanja)',
        jobSearch: 'Kukamanga Kazi Yosavuta',
        countries: ['Malawi']
    },
    {
        id: 15,
        language: 'Chinese (Simplified)',
        jobSearch: '简单找工作',
        countries: ['China', 'Singapore']
    },
    {
        id: 16,
        language: 'Chinese (Traditional)',
        jobSearch: '簡單找工作',
        countries: ['Taiwan', 'Hong Kong']
    },
    {
        id: 17,
        language: 'Corsican',
        jobSearch: 'Ricerca di Lavoru Facile',
        countries: ['France (Corsica)']
    },
    {
        id: 18,
        language: 'Croatian',
        jobSearch: 'Lako Traženje Posla',
        countries: ['Croatia']
    },
    {
        id: 19,
        language: 'Czech',
        jobSearch: 'Snadné Hledání Práce',
        countries: ['Czech Republic']
    },
    {
        id: 20,
        language: 'Danish',
        jobSearch: 'Let Søgning efter Arbejde',
        countries: ['Denmark']
    },
    {
        id: 21,
        language: 'Dutch',
        jobSearch: 'Gemakkelijk Werk Zoeken',
        countries: ['Netherlands', 'Belgium']
    },
    {
        id: 23,
        language: 'Esperanto',
        jobSearch: 'Facila Laborserĉado',
        countries: ['Constructed International Auxiliary Language']
    },
    {
        id: 24,
        language: 'Estonian',
        jobSearch: 'Lihtne Tööotsing',
        countries: ['Estonia']
    },
    {
        id: 25,
        language: 'Filipino (Tagalog)',
        jobSearch: 'Madaling Paghahanap ng Trabaho',
        countries: ['Philippines']
    },
    {
        id: 26,
        language: 'Finnish',
        jobSearch: 'Helppo Työn Haku',
        countries: ['Finland']
    },
    {
        id: 27,
        language: 'French',
        jobSearch: "Recherche Facile d'Emploi",
        countries: ['France', 'Canada', 'Belgium', 'Switzerland', 'Senegal']
    },
    {
        id: 28,
        language: 'Frisian',
        jobSearch: 'Gemiene Wurk Sykje',
        countries: ['Netherlands (Friesland province)']
    },
    {
        id: 29,
        language: 'Galician',
        jobSearch: 'Búsqueda do Traballo Fácil',
        countries: ['Spain (Galicia)']
    },
    {
        id: 30,
        language: 'Georgian',
        jobSearch: 'მარტივი ნამუშევარის ძიება',
        countries: ['Georgia']
    },
    {
        id: 31,
        language: 'German',
        jobSearch: 'Einfache Jobsuche',
        countries: ['Germany', 'Austria', 'Switzerland', 'Luxembourg']
    },
    {
        id: 32,
        language: 'Greek',
        jobSearch: 'Εύκολη Αναζήτηση Εργασίας',
        countries: ['Greece', 'Cyprus']
    },
    {
        id: 34,
        language: 'Haitian Creole',
        jobSearch: 'Rechèch Travay Fasil',
        countries: ['Haiti']
    },
    {
        id: 35,
        language: 'Hausa',
        jobSearch: 'Fasaƙar Nauyi a Najeriya',
        countries: ['Nigeria', 'Niger']
    },
    {
        id: 36,
        language: 'Hawaiian',
        jobSearch: 'ʻimi Pono Pono Loa',
        countries: ['United States (Hawaii)']
    },
    {
        id: 37,
        language: 'Hebrew',
        jobSearch: 'חיפוש עבודה קלה',
        countries: ['Israel']
    },
    {
        id: 39,
        language: 'Hmong',
        jobSearch: 'Nkag Siab Ntawm Cov Ntaub Ntawv',
        countries: ['China (Hmong people)', 'Vietnam (Hmong people)']
    },
    {
        id: 40,
        language: 'Hungarian',
        jobSearch: 'Könnyű Munkakeresés',
        countries: ['Hungary']
    },
    {
        id: 41,
        language: 'Icelandic',
        jobSearch: 'Auðvelt Atvinnumörkun',
        countries: ['Iceland']
    },
    {
        id: 42,
        language: 'Igbo',
        jobSearch: 'Ikwupụta Ọrụ Rụọ Ọma',
        countries: ['Nigeria']
    },
    {
        id: 43,
        language: 'Indonesian',
        jobSearch: 'Pencarian Pekerjaan Mudah',
        countries: ['Indonesia']
    },
    {
        id: 44,
        language: 'Irish',
        jobSearch: 'Lorg Post éasca',
        countries: ['Ireland']
    },
    {
        id: 45,
        language: 'Italian',
        jobSearch: 'Ricerca Facile di Lavoro',
        countries: ['Italy', 'Switzerland', 'San Marino']
    },
    {
        id: 46,
        language: 'Japanese',
        jobSearch: '簡単な仕事の検索',
        countries: ['Japan']
    },
    {
        id: 47,
        language: 'Javanese',
        jobSearch: 'Gampang Gumantos Pekerjaan',
        countries: ['Indonesia (Java island)']
    },
    {
        id: 49,
        language: 'Kazakh',
        jobSearch: 'Жұмыс іздеу қарапайым',
        countries: ['Kazakhstan']
    },
    {
        id: 50,
        language: 'Khmer',
        jobSearch: 'ស្វែងរកការងារយ៉ាងថ្មី',
        countries: ['Cambodia']
    },
    {
        id: 51,
        language: 'Kinyarwanda (Rwanda)',
        jobSearch: 'Kuvugurura Akazi Kagura',
        countries: ['Rwanda']
    },
    {
        id: 52,
        language: 'Korean',
        jobSearch: '쉬운 구직',
        countries: ['South Korea', 'North Korea']
    },
    {
        id: 53,
        language: 'Kurdish',
        jobSearch: 'Lêkolîna Kêrhatî',
        countries: ['Iraq', 'Iran', 'Turkey', 'Syria']
    },
    {
        id: 54,
        language: 'Kyrgyz',
        jobSearch: 'Колдонуудагы Колдонмо Колдонуу',
        countries: ['Kyrgyzstan']
    },
    {
        id: 55,
        language: 'Lao',
        jobSearch: 'ການຄົ້ນຫາວຽກໃບທີ່ໄດ້ໄວ້',
        countries: ['Laos']
    },
    {
        id: 56,
        language: 'Latin',
        jobSearch: 'Facile Quaerere Opus',
        countries: ['Vatican City (Latin is the official language)']
    },
    {
        id: 57,
        language: 'Latvian',
        jobSearch: 'Viegla Darba Meklēšana',
        countries: ['Latvia']
    },
    {
        id: 58,
        language: 'Lithuanian',
        jobSearch: 'Lengva Darbo Paieška',
        countries: ['Lithuania']
    },
    {
        id: 59,
        language: 'Luxembourgish',
        jobSearch: 'Einfach Aarbechtssich',
        countries: ['Luxembourg']
    },
    {
        id: 60,
        language: 'Macedonian',
        jobSearch: 'Лесно пребарување на работа',
        countries: ['North Macedonia']
    },
    {
        id: 61,
        language: 'Malagasy',
        jobSearch: 'Fampiroboroboana Asa Fahazoan',
        countries: ['Madagascar']
    },
    {
        id: 62,
        language: 'Malay',
        jobSearch: 'Cari Kerja Mudah',
        countries: ['Malaysia', 'Brunei', 'Indonesia']
    },
    {
        id: 64,
        language: 'Maltese',
        jobSearch: 'Tiftix Ħames',
        countries: ['Malta']
    },
    {
        id: 65,
        language: 'Maori',
        jobSearch: 'Rapu Mahi Hē',
        countries: ['New Zealand']
    },
    {
        id: 67,
        language: 'Mongolian',
        jobSearch: 'Ажил Хайхын Хялбар Чухал',
        countries: ['Mongolia']
    },
    {
        id: 68,
        language: 'Myanmar (Burmese)',
        jobSearch: 'အလုပ်ကြည့်ခြင်းကို အဆင့်အတန်းကျင်ကြပါ',
        countries: ['Myanmar']
    },
    {
        id: 69,
        language: 'Nepali',
        jobSearch: 'सजिलो काम खोज',
        countries: ['Nepal']
    },
    {
        id: 70,
        language: 'Norwegian',
        jobSearch: 'Enkel Jobbsøk',
        countries: ['Norway']
    },
    {
        id: 71,
        language: 'Pashto',
        jobSearch: 'د ښه وظیفه اندازه',
        countries: ['Afghanistan', 'Pakistan']
    },
    {
        id: 72,
        language: 'Persian (Farsi)',
        jobSearch: 'جستجوی آسان شغل',
        countries: ['Iran', 'Afghanistan', 'Tajikistan']
    },
    {
        id: 73,
        language: 'Polish',
        jobSearch: 'Łatwe Szukanie Pracy',
        countries: ['Poland']
    },
    {
        id: 74,
        language: 'Portuguese',
        jobSearch: 'Pesquisa Fácil de Emprego',
        countries: ['Brazil', 'Portugal', 'Mozambique', 'Angola']
    },
    {
        id: 76,
        language: 'Romanian',
        jobSearch: 'Căutare Ușoară a Locurilor de Muncă',
        countries: ['Romania', 'Moldova']
    },
    {
        id: 77,
        language: 'Russian',
        jobSearch: 'Простой поиск работы',
        countries: ['Russia', 'Kazakhstan', 'Belarus', 'Kyrgyzstan']
    },
    {
        id: 78,
        language: 'Samoan',
        jobSearch: 'Olioli Faiaoga Galue',
        countries: ['Samoa']
    },
    {
        id: 79,
        language: 'Scots Gaelic',
        jobSearch: 'Lorg Obair Shocair',
        countries: ['Scotland']
    },
    {
        id: 80,
        language: 'Serbian',
        jobSearch: 'Лако Тражење Посла',
        countries: ['Serbia']
    },
    {
        id: 81,
        language: 'Sesotho',
        jobSearch: 'Ho Hlahloba Kopo',
        countries: ['Lesotho', 'South Africa']
    },
    {
        id: 82,
        language: 'Shona',
        jobSearch: 'Kugadzirisa Chigadziriso Chakawanda',
        countries: ['Zimbabwe']
    },
    {
        id: 83,
        language: 'Sindhi',
        jobSearch: 'آسان ملازمت جي تلاش',
        countries: ['Pakistan']
    },
    {
        id: 84,
        language: 'Sinhala',
        jobSearch: 'හොඳම රැකියාව සොයන්න',
        countries: ['Sri Lanka']
    },
    {
        id: 85,
        language: 'Slovak',
        jobSearch: 'Ľahké Hľadanie Práce',
        countries: ['Slovakia']
    },
    {
        id: 86,
        language: 'Slovenian',
        jobSearch: 'Enostavno Iskanje Službe',
        countries: ['Slovenia']
    },
    {
        id: 87,
        language: 'Somali',
        jobSearch: 'Raadi Shaqo Kugu Heli',
        countries: ['Somalia']
    },
    {
        id: 88,
        language: 'Spanish',
        jobSearch: 'Búsqueda Fácil de Empleo',
        countries: ['Spain', 'Mexico', 'Argentina', 'Colombia', 'Peru', 'Venezuela', 'Chile']
    },
    {
        id: 89,
        language: 'Sundanese',
        jobSearch: 'Gampang Gumantos Pamaén',
        countries: ['Indonesia (West Java)']
    },
    {
        id: 90,
        language: 'Swahili',
        jobSearch: 'Utafutaji wa Kazi Rahisi',
        countries: ['Tanzania', 'Kenya', 'Uganda', 'Rwanda']
    },
    {
        id: 91,
        language: 'Swedish',
        jobSearch: 'Enkel Jobbsökning',
        countries: ['Sweden']
    },
    {
        id: 92,
        language: 'Tajik',
        jobSearch: 'Ҷустуҷӯи кори осон',
        countries: ['Tajikistan']
    },
    {
        id: 94,
        language: 'Tatar',
        jobSearch: 'Колган аракет урыны',
        countries: ['Russia (Tatarstan)']
    },
    {
        id: 96,
        language: 'Thai',
        jobSearch: 'ค้นหางานง่าย',
        countries: ['Thailand']
    },
    {
        id: 97,
        language: 'Turkish',
        jobSearch: 'Kolay İş Arama',
        countries: ['Turkey', 'Cyprus', 'Germany']
    },
    {
        id: 98,
        language: 'Turkmen',
        jobSearch: 'Ýörite Iş Gözleýän',
        countries: ['Turkmenistan']
    },
    {
        id: 99,
        language: 'Ukrainian',
        jobSearch: 'Простий пошук роботи',
        countries: ['Ukraine']
    },
    {
        id: 100,
        language: 'Urdu',
        jobSearch: 'آسان ملازمت کی تلاش',
        countries: ['Pakistan']
    },
    {
        id: 101,
        language: 'Uyghur',
        jobSearch: 'ئاسان يەر يىغىش ئىزدەش',
        countries: ['China (Xinjiang region)']
    },
    {
        id: 102,
        language: 'Uzbek',
        jobSearch: 'Ishni qidirishni oson',
        countries: ['Uzbekistan']
    },
    {
        id: 103,
        language: 'Vietnamese',
        jobSearch: 'Tìm Việc Làm Dễ Dàng',
        countries: ['Vietnam']
    },
    {
        id: 104,
        language: 'Welsh',
        jobSearch: 'Chwilio am Waith Hawdd',
        countries: ['Wales']
    },
    {
        id: 105,
        language: 'Xhosa',
        jobSearch: 'Ukucinga Umsebenzi Osula',
        countries: ['South Africa']
    },
    {
        id: 106,
        language: 'Yiddish',
        jobSearch: 'פּראָבירן אַרבעט זוכן',
        countries: ['Israel', 'United States']
    },
    {
        id: 107,
        language: 'Yoruba',
        jobSearch: 'Ra Ara Ise Rẹ Rẹ',
        countries: ['Nigeria', 'Benin', 'Togo']
    },
    {
        id: 108,
        language: 'Zulu',
        jobSearch: 'Ukucinga Umsebenzi Osula',
        countries: ['South Africa']
    }
];

interface IJobCategoryProps {
    index: number;
    countries: Array<string>;
    jobSearchText: string;
    language?: string;
}

const LanguageText = ({ jobSearchText, language }: IJobCategoryProps) => {
    return (
        <motion.div
            initial={{ x: 0 }}
            transition={{ type: 'spring', stiffness: 50, damping: 20 }}
            style={{
                display: 'inline-block',
                padding: 4,
                cursor: 'pointer'
            }}
        >
            <Chip label={`${jobSearchText} (${language ? language : ''})`} title={jobSearchText} size="medium" />
        </motion.div>
    );
};

const LangugageSection: React.FC = () => {
    const navigate = useNavigate(); // Call useNavigate here

    const handleChipClick = () => {
        navigate('/all-supported-languages');
    };
    const { t } = useTranslation();
    return (
        <Container>
            <Grid container justifyContent="center" spacing={{ xs: 3, sm: 5 }} sx={{ marginTop: 'auto', marginBottom: 'auto' }}>
                <Grid item xs={12} zeroMinWidth>
                    <Typography variant="h1" sx={{ textAlign: 'center', mb: 2 }}>
                        {t('We support Job postings from 100+ languages')}
                    </Typography>

                    <Typography variant="h4" sx={{ textAlign: 'center', mb: 5 }}>
                        {t('Leave the old way of finding naukri, get intelligent with Intellirecruit.')}
                    </Typography>

                    <Stack direction="row" spacing={2}>
                        <Grid container justifyContent={'center'}>
                            {[...languagesData.slice(0, 15)].map((language, index) => (
                                <Grid item>
                                    <motion.div
                                        initial={{ opacity: 0, translateY: 550 }}
                                        animate={{ opacity: 1, translateY: 0 }}
                                        transition={{ type: 'spring', stiffness: 150, damping: 30, delay: 0.6 }}
                                    >
                                        <LanguageText
                                            index={index}
                                            key={index}
                                            jobSearchText={language.jobSearch}
                                            countries={language.countries}
                                            language={language.language}
                                        />
                                    </motion.div>
                                </Grid>
                            ))}
                            <Grid item>
                                <motion.div
                                    initial={{ opacity: 0, translateY: 550 }}
                                    animate={{ opacity: 1, translateY: 0 }}
                                    transition={{ type: 'spring', stiffness: 150, damping: 30, delay: 0.6 }}
                                >
                                    <LanguageText index={1000} jobSearchText={'90+ More Language'} countries={[]} language="s" />
                                </motion.div>
                            </Grid>
                        </Grid>
                    </Stack>
                    <Grid container justifyContent="center" alignItems={'center'} sx={{ m: 5 }}>
                        <Grid item>
                            {/* <Button variant="contained" onClick={handleChipClick} disableElevation>
                                {t('Browse all supported languages')}
                            </Button> */}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default LangugageSection;
