import { Grid, Typography, Stack, Button } from '@mui/material';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import PersonIcon from '@mui/icons-material/Person';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import React, { useMemo } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { getMiniMenuSectionStyles } from './MiniMenuSection.styles';
import { useNavigation } from 'hooks/useNavigation';

export const MiniMenuSection: React.FC = () => {
    const { t } = useTranslation();
    const styles = getMiniMenuSectionStyles();
    const { navigateTo } = useNavigation();

    const actions = useMemo(
        () => [
            {
                name: 'jobs',
                displayName: t('Find Work'),
                Icon: BusinessCenterIcon,
                link: '/jobs'
            },
            {
                name: 'companies',
                displayName: t('Companies'),
                Icon: CorporateFareIcon,
                link: '/companies'
            },
            {
                name: 'applications',
                displayName: t('Applications'),
                Icon: WorkHistoryIcon,
                link: '/applications'
            },
            {
                name: 'profile',
                displayName: t('Profile'),
                Icon: PersonIcon,
                link: '/portfolio'
            }
        ],
        [t]
    );

    return (
        <Grid container spacing={5}>
            {actions.map((action) => (
                <Grid item>
                    <Button sx={styles.button} onClick={() => navigateTo(action.link)}>
                        <Stack sx={styles.container}>
                            <action.Icon color="primary" fontSize="inherit" sx={styles.icon} />
                        </Stack>
                        <Typography variant="subtitle1" color="text.primary" sx={styles.text}>
                            {action.displayName}
                        </Typography>
                    </Button>
                </Grid>
            ))}
        </Grid>
    );
};
