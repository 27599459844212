import React, { FunctionComponent } from 'react';
import styles from './AssessmentDescription.module.css';
import HomeIcon from '../assets/Home.svg';
import NextIcon from '../assets/Next.svg';
import EditIcon from '../assets/Edit.svg';
import MagicIcon from '../assets/🦆 icon _auto fix high_.svg';

const AssessmentDescriptionComponent: FunctionComponent = () => {
    return (
        <div>
            <div className={styles.breadcrumb}>
                <div className={styles.frameParent}>
                    <div className={styles.homeParent}>
                        <img className={styles.homeIcon1} alt="" src={HomeIcon} />
                        <div className={styles.assessments}>Assessments</div>
                    </div>
                    <img className={styles.nextIcon1} alt="" src={NextIcon} />
                    <div className={styles.assessments}>Unititled Assessment</div>
                </div>
            </div>
            <div className={styles.rectangleDiv}>
                <div className={styles.groupParent}>
                    <div className={styles.editParent}>
                        <img className={styles.editIcon1} alt="" src={EditIcon} />
                        <b className={styles.unititledAssessment}>Unititled Assessment</b>
                    </div>
                    <div className={styles.groupDiv}>
                        <div className={styles.descriptionParent}>
                            <div className={styles.description}>Description</div>
                            <div className={styles.autoGenerateParent}>
                                <div className={styles.autoGenerate}>Auto Generate</div>
                                <img className={styles.iconAutoFixHigh2} alt="" src={MagicIcon} />
                            </div>
                        </div>
                        <div className={styles.weAreExcitedContainer}>
                            <p className={styles.weAreExcited1}>
                                We are excited to invite experienced software engineers to participate in our comprehensive assessment
                                designed to evaluate your technical skills and problem-solving abilities. As a software engineer at our
                                company, you will play a crucial role in developing cutting-edge software solutions that drive our products
                                forward. This assessment aims to provide us with insights into your coding proficiency, software design
                                acumen, and your approach to addressing real-world programming challenges.
                            </p>
                        </div>
                    </div>
                    <div className={styles.groupParent1}>
                        <div className={styles.instructionsParent}>
                            <div className={styles.description}>Instructions</div>
                            <div className={styles.autoGenerateGroup}>
                                <div className={styles.autoGenerate}>Auto Generate</div>
                                <img className={styles.iconAutoFixHigh2} alt="" src={MagicIcon} />
                            </div>
                        </div>
                        <div className={styles.beforeYouBeginContainer}>
                            <p className={styles.blankLine}>Before You Begin:</p>
                            <p className={styles.blankLine}>&nbsp;</p>
                            <ul className={styles.environmentFindAQuietAnd}>
                                <li className={styles.timeTheTestIsTimedMake}>
                                    <span>Environment: Find a quiet and well-lit space where you can focus without interruptions.</span>
                                </li>
                                <li className={styles.timeTheTestIsTimedMake}>
                                    <span>
                                        Time: The test is timed. Make sure you have set aside the appropriate amount of time as specified.
                                    </span>
                                </li>
                                <li className={styles.timeTheTestIsTimedMake}>
                                    <span>
                                        Devices: Use a desktop or laptop computer for the best experience. Ensure a stable internet
                                        connection.
                                    </span>
                                </li>
                                <li className={styles.timeTheTestIsTimedMake}>
                                    <span>
                                        Materials: You will need a pen or pencil and scratch paper for calculations. Have these ready before
                                        starting.
                                    </span>
                                </li>
                            </ul>
                            <p className={styles.weAreExcited1}>&nbsp;</p>
                            <p className={styles.blankLine}>Test Instructions:</p>
                            <p className={styles.weAreExcited1}>&nbsp;</p>
                            <ul className={styles.environmentFindAQuietAnd}>
                                <li className={styles.timeTheTestIsTimedMake}>
                                    <span>
                                        Login: Access the testing platform using the provided credentials. If this is your first time,
                                        follow the account setup instructions.
                                    </span>
                                </li>
                                <li className={styles.timeTheTestIsTimedMake}>
                                    <span>
                                        Navigation: The test consists of multiple-choice questions. Use the "Next" and "Previous" buttons to
                                        navigate between questions. Do not refresh the page, as your progress may be lost.
                                    </span>
                                </li>
                                <li className={styles.timeTheTestIsTimedMake}>
                                    <span>
                                        Time Management: Keep an eye on the timer displayed on the screen. You must complete the test within
                                        the allocated time. Unanswered questions will be considered incorrect, so attempt every question
                                        even if you're unsure.
                                    </span>
                                </li>
                                <li className={styles.timeTheTestIsTimedMake}>
                                    <span>
                                        Question Types: The test includes a variety of question types, such as numerical reasoning, logical
                                        puzzles, and pattern recognition. Read each question carefully before answering.
                                    </span>
                                </li>
                                <li className={styles.timeTheTestIsTimedMake}>
                                    <span>
                                        Answering: Select the best answer for each question. Only one answer is correct. There is no
                                        negative marking for incorrect answers, so it's better to guess if you're unsure.
                                    </span>
                                </li>
                                <li className={styles.timeTheTestIsTimedMake}>
                                    <span>
                                        Review: Before submitting your test, you will have the chance to review your answers. Use this time
                                        to double-check your choices.
                                    </span>
                                </li>
                                <li className={styles.timeTheTestIsTimedMake}>
                                    <span>
                                        Submission: Once you are satisfied with your answers, click the "Submit" button. You will receive a
                                        confirmation message.
                                    </span>
                                </li>
                                <li className={styles.timeTheTestIsTimedMake}>
                                    <span>
                                        Feedback: You will be informed about the next steps after the assessment. If you are selected for
                                        further evaluation, you will be contacted for an interview.
                                    </span>
                                </li>
                            </ul>
                            <p className={styles.weAreExcited1}>&nbsp;</p>
                            <p className={styles.blankLine}>Important Notes:</p>
                            <p className={styles.blankLine}>&nbsp;</p>
                            <ul className={styles.environmentFindAQuietAnd}>
                                <li className={styles.timeTheTestIsTimedMake}>
                                    <span>
                                        Honesty: This assessment is meant to reflect your true abilities. Do not use external resources or
                                        assistance during the test.
                                    </span>
                                </li>
                                <li className={styles.timeTheTestIsTimedMake}>
                                    <span>
                                        Time Limits: Manage your time wisely. If you get stuck on a question, move on and return to it later
                                        if time allows.
                                    </span>
                                </li>
                                <li className={styles.timeTheTestIsTimedMake}>
                                    <span>
                                        Technical Issues: If you encounter any technical problems during the test, please contact our
                                        support team immediately using the provided contact information.
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        Focus: Minimize distractions and stay focused throughout the test. Your performance is important, so
                                        give it your best effort.
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AssessmentDescriptionComponent;
