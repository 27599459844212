import React from 'react';
import { Box, styled } from '@mui/material';
import QuestionComponent from './QuestionComponent/QuestionComponent';
import BreadcrumbComponent from './BreadCrumb/BreadCrumbComponent';

const PageContainer = styled(Box)({
    backgroundColor: 'white',
    minHeight: '2000px'
});
const MainComponent: React.FC = () => {
    return (
        <PageContainer>
            <BreadcrumbComponent />
            <QuestionComponent />
        </PageContainer>
    );
};

export default MainComponent;
