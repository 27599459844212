import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import Assessment from 'views/Assessment/Assessment';
import CreateAssessmentComponent from 'views/Assessment/CreateAssessment/CreateAssessmentComponent';
import AssessmentDescriptionComponent from 'views/Assessment/AssessmentDescription/AssessmentDescriptionComponent';
import MainComponent from 'views/Assessment/MainComponent';
// import TypeComponent from 'views/Assessment/TypeComponent/TypeComponent';

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const JobListingsPage = Loadable(lazy(() => import('views/recruiter/job-listings')));
const AppKanbanBacklogs = Loadable(lazy(() => import('views/pages/job/job-listings')));
const AppKanbanBoard = Loadable(lazy(() => import('views/pages/job/Board')));
const AppKanban = Loadable(lazy(() => import('views/pages/job')));
const Calendar = Loadable(lazy(() => import('views/calendar/ConnectedCalendar')));
const Dashboard = Loadable(lazy(() => import('views/pages/landing/admin/Dashboard')));
const ManageUsers = Loadable(lazy(() => import('views/pages/manage-users/ConnectedManageUsers')));
const ManageGroups = Loadable(lazy(() => import('views/pages/manage-groups/ConnectedManageGroups')));
const LazyLoadedJobTemplateDetailView = Loadable(lazy(() => import('views/pages/landing/job-templates/ConnectedJobTemplateDetailView')));
const LazyLoadedConnectedJobTemplatesSearchResults = Loadable(
    lazy(() => import('views/pages/landing/job-templates/search-results/ConnectedJobTemplateSearchResults'))
);
const TaskGroup = Loadable(lazy(() => import('ui-component/Tasks/TaskGroup')));
const LazyLoadedJobDetailView = Loadable(lazy(() => import('views/recruiter/job-application-details/ConnectedJobApplicationDetails')));
const LazyLoadedJobSeekerDetailView = Loadable(lazy(() => import('views/recruiter/job-seeker-details/ConnectedJobSeekerDetails')));
const LazyLoadedCreateEasyJob = Loadable(lazy(() => import('views/recruiter/job-listings/CreateJob')));
// const LazyLoadedCreateJobContainer = Loadable(lazy(() => import('views/recruiter/create-job/CreateJobContainer')));
const LazyLoadedConnectedCreateJob = Loadable(lazy(() => import('views/recruiter/create-job/ConnectedCreateJob')));

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Dashboard />
        },
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: '/sample-page',
            element: <SamplePage />
        },
        {
            path: '/dashboard',
            element: <SamplePage />
        },
        {
            path: '/jobs',
            element: <JobListingsPage />
        },
        {
            path: '/jobs/create',
            element: <LazyLoadedConnectedCreateJob />
        },
        {
            path: '/job/create',
            element: <LazyLoadedCreateEasyJob />
        },
        // {
        //     path: '/question',
        //     // element: <TypeComponent />
        // },
        {
            path: '/question/create',
            element: <MainComponent />
        },
        {
            path: '/assessment',
            element: <Assessment />
        },
        {
            path: '/assessment/description',
            element: <AssessmentDescriptionComponent />
        },
        {
            path: '/tasks',
            element: <TaskGroup />
        },
        {
            path: '/assessment/create',
            element: <CreateAssessmentComponent />
        },
        {
            path: '/dashboard/job/:jobId',
            element: <LazyLoadedJobDetailView />
        },
        {
            path: '/dashboard/job/edit/:jobId',
            element: <LazyLoadedConnectedCreateJob />
        },
        {
            path: '/job-template/:id/:title',
            element: <LazyLoadedJobTemplateDetailView />
        },
        {
            path: '/job-templates',
            element: <LazyLoadedConnectedJobTemplatesSearchResults />
        },
        {
            path: '/job-templates/:category',
            element: <LazyLoadedConnectedJobTemplatesSearchResults />
        },
        {
            path: '/job-templates/:category',
            element: <LazyLoadedConnectedJobTemplatesSearchResults />
        },
        {
            path: '/job-templates/search',
            element: <LazyLoadedConnectedJobTemplatesSearchResults />
        },
        {
            path: '/jobs/job-seeker',
            element: <LazyLoadedJobSeekerDetailView />
        },
        {
            path: '/app/kanban',
            element: <AppKanban />,
            children: [
                {
                    path: 'backlogs',
                    element: <AppKanbanBacklogs />,
                    children: {
                        path: 'jobs',
                        element: <>Hello</>
                    }
                },
                {
                    path: 'board',
                    element: <AppKanbanBoard />
                }
            ]
        },
        {
            path: '/calendar',
            element: <Calendar />
        },
        {
            path: '/manage-users',
            element: <ManageUsers />
        },
        {
            path: '/manage-groups',
            element: <ManageGroups />
        }
    ]
};

export default MainRoutes;
