import React, { useState } from 'react';
import { styled, TextField } from '@mui/material';
import CreateQuestionComponent from '../CreateQuestion/CreateQuestionComponent';
import TypeComponent from '../TypeComponent/TypeComponent';

const FlexContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    backgroundColor: 'white'
});
const BoldTextField = styled(TextField)(({ theme }) => ({
    '& input': {
        fontWeight: theme.typography.fontWeightBold
    },
    '& b, & strong': {
        fontWeight: theme.typography.fontWeightBold
    },
    width: '700px',
    marginLeft: '50px',
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            border: 'none' // Hide the border
        }
    }
}));

const QuestionComponent: React.FC = () => {
    const [editedQuestion, setEditedQuestion] = useState(
        'If a shirt originally costs $60 and is on sale for 30% off, what is the final price of the shirt?'
    );
    const [selectedType, setSelectedType] = useState<string | ''>('radio');
    const [options, setOptions] = useState<string[]>(['Option1', 'Option2', 'Option3', 'Option4']);

    const handleQuestionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditedQuestion(event.target.value);
    };

    const handleTypeChange = (type: string) => {
        setSelectedType(type);
        // Reset options when type changes
        setOptions(['', '', '', '']);
    };

    const handleOptionChange = (index: number, value: string) => {
        const updatedOptions = [...options];
        updatedOptions[index] = value;
        setOptions(updatedOptions);
    };

    return (
        <FlexContainer>
            <CreateQuestionComponent />

            <div style={{ display: 'flex', alignItems: 'center' }}>
                <BoldTextField fullWidth multiline value={editedQuestion} onChange={handleQuestionChange} variant="outlined" />
                <TypeComponent options={options} onChange={handleOptionChange} onTypeChange={handleTypeChange} />
            </div>

            {selectedType === 'radio' && (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '20px', marginLeft: '70px' }}>
                    {options.map((_, index) => (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <input type="radio" name="radioOptions" />
                            <TextField
                                fullWidth
                                multiline
                                value={options[index]}
                                onChange={(event) => handleOptionChange(index, event.target.value)}
                                variant="outlined"
                                size="small"
                                sx={{ flex: 1, minWidth: '500px', marginLeft: '16px' }}
                            />
                        </div>
                    ))}
                </div>
            )}

            {selectedType === 'multipleChoice' && (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '20px', marginLeft: '70px' }}>
                    {options.map((_, index) => (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <input type="checkbox" name="checkboxOptions" />
                            <TextField
                                fullWidth
                                multiline
                                value={options[index]}
                                onChange={(event) => handleOptionChange(index, event.target.value)}
                                variant="outlined"
                                size="small"
                                sx={{ flex: 1, minWidth: '500px', marginLeft: '16px' }}
                            />
                        </div>
                    ))}
                </div>
            )}

            {selectedType === 'short' && (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: '70px' }}>
                    <TextField id="outlined-controlled" label="Answer" variant="standard" sx={{ m: 0, minWidth: 450 }} />
                </div>
            )}

            {selectedType === 'long' && (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: '70px' }}>
                    <TextField multiline id="standard-basic" label="Answer" variant="standard" sx={{ m: 0, minWidth: 450 }} />
                </div>
            )}
        </FlexContainer>
    );
};

export default QuestionComponent;
