// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';

// action - jobs reducer
export const GET_JOBS = 'GET_JOBS';
export const GET_JOB = 'GET_JOB';
export const ADD_JOB = 'ADD_JOB';
export const UPDATE_JOB = 'UPDATE_JOB';
export const DELETE_JOB = 'DELETE_JOB';
export const JOBS_LOADING = 'JOBS_LOADING';

export const FIREBASE_STATE_CHANGED = 'FIREBASE_STATE_CHANGED';
