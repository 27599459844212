import { Grid, Typography, IconButton, useTheme } from '@mui/material';
import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { useProfileStyles } from './Profile.styles';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import useConfig from 'hooks/useConfig';
import { useModal } from 'hooks/useModal';
import { BasicInformationModal } from './BasicInformationModal';
import { useTranslation } from 'hooks/useTranslation';

export const BasicInformationCard: React.FC = () => {
    const theme = useTheme();
    const { borderRadius } = useConfig();

    const styles = useProfileStyles(theme, borderRadius);

    const { open } = useModal(<BasicInformationModal />);
    const { t } = useTranslation();

    return (
        <MainCard>
            <Grid container>
                <Grid item xs={11}>
                    <Typography variant="h4">Basic Information</Typography>
                </Grid>
                <Grid item xs={1}>
                    <IconButton color="primary" aria-label="Edit Basic information" onClick={open}>
                        <ModeEditIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid container sx={styles.basicInformationContainer}>
                <Grid xs={12}>
                    <Grid container>
                        <Grid item xs={4} sx={styles.basicInformationGrid}>
                            <Typography color={'text.secondary'}>{t('Age')}</Typography>
                            <Typography color={'text.primary'}>27 Years</Typography>
                        </Grid>
                        <Grid item xs={4} sx={styles.basicInformationGrid}>
                            <Typography color={'text.secondary'}>Years of Experience</Typography>
                            <Typography color={'text.primary'}>18 Years</Typography>
                        </Grid>
                        <Grid item xs={4} sx={styles.basicInformationGrid}>
                            <Typography color={'text.secondary'}>CTC</Typography>
                            <Typography color={'text.primary'}>32 Million</Typography>
                        </Grid>

                        <Grid item xs={4} sx={styles.basicInformationGrid}>
                            <Typography color={'text.secondary'}>Location</Typography>
                            <Typography color={'text.primary'}>Bengaluru, Kartnataka</Typography>
                        </Grid>

                        <Grid item xs={4} sx={styles.basicInformationGrid}>
                            <Typography color={'text.secondary'}>Availability</Typography>
                            <Typography color={'text.primary'}>Fulltime (40/wk)</Typography>
                        </Grid>

                        <Grid item xs={4} sx={styles.basicInformationGrid}>
                            <Typography color={'text.secondary'}>Contact Info</Typography>
                            <Typography color={'text.primary'}>* * * * * * </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid></Grid>
            </Grid>
            {/* {modalOpen ? (
                <div className="modal">
                    <h2>Modal</h2>
                    <p>This is the modal content.</p>
                    <button onClick={toggleModal}>Close</button>
                </div>
            ) : null} */}
        </MainCard>
    );
};
