import React from 'react';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTime';
import { Card, CardContent, Divider, Grid, Typography, Button } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import GroupsIcon from '@mui/icons-material/Groups';
import { useTranslation } from 'hooks/useTranslation';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';

const BasicChildDetailView: React.FC = () => {
    const { t } = useTranslation();
    return (
        <Grid item xs={12}>
            <Card>
                <CardContent>
                    <Grid container rowSpacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h4">{t('Summary: ')}</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="subtitle1">
                                Software engineers are responsible for designing, developing, testing, and maintaining software systems that
                                meet specific requirements and solve complex problems.Software engineers are involved in the design and
                                development of software applications. They work closely with stakeholders to understand their requirements
                                and translate them into technical specifications.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider sx={{ my: 1.5 }} />
                    <Grid item xs={12}>
                        <Grid container rowSpacing={1} direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <Grid container rowSpacing={1} columnGap={2}>
                                    <Grid item>
                                        <Typography variant="subtitle1" color="text.primary">
                                            <AccessTimeFilledIcon fontSize="inherit" sx={{ mr: 0.5, verticalAlign: 'text-top' }} />
                                            Posted 2 days ago
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1" color="text.primary">
                                            <VisibilityIcon fontSize="inherit" sx={{ mr: 0.5, verticalAlign: 'text-top' }} />
                                            1.5 k Views
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1" color="text.primary">
                                            <GroupsIcon fontSize="inherit" sx={{ mr: 0.5, verticalAlign: 'text-top' }} />
                                            2.5 k Applicants
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container>
                                    <Grid item sx={{ pt: 1 }}>
                                        <Button startIcon={<EmailOutlinedIcon fontSize="small" />}>
                                            <Typography>{t('Contact Recruiter')}</Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" size="large" startIcon={<LayersOutlinedIcon />} disableElevation>
                                            {t('Easy Apply')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};
export default BasicChildDetailView;
