import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import JobSeekerLayout from 'layout/JobseekerLayout';
import Loadable from 'ui-component/Loadable';
import { JobListing } from 'views/job-seeker/job-listing/JobListing';
import { ConnectedProfile } from 'views/job-seeker/profile/ConnectedProfile';
import { JobDetailView } from 'views/job-seeker/job-detail-view/JobDetailView';

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

const JobSeekerRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <JobSeekerLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <SamplePage />
        },
        {
            path: '/onboarding',
            element: <SamplePage />
        },
        {
            path: '/jobs',
            element: <JobListing />
        },
        {
            path: '/job/details',
            element: <JobDetailView />
        },
        {
            path: '/portfolio',
            element: <ConnectedProfile />
        }
    ]
};

export default JobSeekerRoutes;
