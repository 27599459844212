// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Box, Container, Grid, IconButton, Link, Stack, Typography } from '@mui/material'; // Divider

import PublicIcon from '@mui/icons-material/Public';
import Footer from 'ui-component/Footer';
import { useTranslation } from 'hooks/useTranslation';
import { useNavigation } from 'hooks/useNavigation';
// Link - custom style
const FooterLink = styled(Link)(({ theme }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.text.secondary : theme.palette.text.hint,
    '&:hover': {
        color: theme.palette.primary.main
    },
    '&:active': {
        color: theme.palette.primary.main
    }
}));

const FooterSection = () => {
    const theme = useTheme();
    const textColor = theme.palette.mode === 'dark' ? 'text.secondary' : 'text.hint';
    const { t } = useTranslation();
    const { navigateTo } = useNavigation();

    return (
        <>
            <Container sx={{ mb: 15 }}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Grid container spacing={8}>
                            <Grid item xs={12} md={4}>
                                <Stack spacing={{ xs: 2, md: 5 }}>
                                    <Typography variant="h4" color={textColor} sx={{ fontWeight: 500 }}>
                                        {t('About Intellirecruit')}
                                    </Typography>
                                    <Typography variant="body2" color={textColor}>
                                        {t(
                                            'Intellirecruit is an advanced job recruitment platform that utilizes artificial intelligence (AI) and automated systems to transform the hiring process. It works by employing AI-driven candidate screening and matching algorithms to analyze extensive candidate data and identify the most suitable candidates for specific job openings. This automated approach saves recruiters time and effort by providing them with a shortlist of qualified candidates quickly.'
                                        )}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Grid container spacing={{ xs: 5, md: 2 }}>
                                    <Grid item xs={6} sm={3}>
                                        <Stack spacing={{ xs: 3, md: 5 }}>
                                            <Typography variant="h4" color={textColor} sx={{ fontWeight: 500 }}>
                                                {t('Help')}
                                            </Typography>
                                            <Stack spacing={{ xs: 1.5, md: 2.5 }}>
                                                <FooterLink
                                                    onClick={() => navigateTo('/mission')}
                                                    href="#"
                                                    target="_blank"
                                                    underline="none"
                                                >
                                                    {t('Mission')}
                                                </FooterLink>
                                                <FooterLink
                                                    onClick={() => navigateTo('/comparison-with-others')}
                                                    target="_blank"
                                                    underline="none"
                                                >
                                                    {t('Compare')}
                                                </FooterLink>
                                                <FooterLink href="https://blog.intellirecruit.co.in" target="_blank" underline="none">
                                                    {t('Blog')}
                                                </FooterLink>
                                                <FooterLink href="/contact-us" target="_blank" underline="none">
                                                    {t('Contact Us')}
                                                </FooterLink>
                                            </Stack>
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={6} sm={3}>
                                        <Stack spacing={{ xs: 3, md: 5 }}>
                                            <Typography variant="h4" color={textColor} sx={{ fontWeight: 500 }}>
                                                {t('Are you ?')}
                                            </Typography>
                                            <Stack spacing={{ xs: 1.5, md: 2.5 }}>
                                                <FooterLink href="#" target="_blank" underline="none">
                                                    {t('Job seeker')}
                                                </FooterLink>
                                                <FooterLink href="#" target="_blank" underline="none">
                                                    {t('Employer')}
                                                </FooterLink>
                                                <FooterLink href="#" target="_blank" underline="none">
                                                    {t('Local Guide')}
                                                </FooterLink>
                                                <FooterLink href="#" target="_blank" underline="none">
                                                    {t('Consultant')}
                                                </FooterLink>
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <Stack spacing={{ xs: 3, md: 5 }}>
                                            <Typography variant="h4" color={textColor} sx={{ fontWeight: 500 }}>
                                                {t('Features')}
                                            </Typography>
                                            <Stack spacing={{ xs: 1.5, md: 2.5 }}>
                                                <FooterLink href="#" target="_blank" underline="none">
                                                    {t('Application Tracking System')}
                                                </FooterLink>
                                                <FooterLink href="/job-templates" target="_blank" underline="none">
                                                    {t('Job Template Marketplace')}
                                                </FooterLink>
                                                <FooterLink href="#" target="_blank" underline="none">
                                                    {t('Custom carrer sites')}
                                                </FooterLink>
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <Box sx={{ bgcolor: 'dark.dark', py: { xs: 3, sm: 1.5 } }}>
                <Container>
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={{ xs: 1.5, sm: 1, md: 3 }}
                    >
                        <Typography color="text.secondary">
                            {t('copyright')} © {new Date().getFullYear()} Hellolabs Technologies Pvt Ltd
                        </Typography>
                        <Stack direction="row" alignItems="center" spacing={{ xs: 3, sm: 1.5, md: 2 }}>
                            <IconButton
                                size="small"
                                aria-label="Website"
                                component={Link}
                                href="https://Intellirecruit.co.in"
                                target="_blank"
                            >
                                <PublicIcon sx={{ color: 'text.secondary', '&:hover': { color: 'error.main' } }} />
                            </IconButton>
                            <Footer />
                            {/* <IconButton
                                size="small"
                                aria-label="codedTheme Twitter"
                                component={Link}
                                href="https://twitter.com/codedthemes"
                                target="_blank"
                            >
                                <TwitterIcon sx={{ color: 'text.secondary', '&:hover': { color: 'primary.main' } }} />
                            </IconButton>
                            <IconButton
                                size="small"
                                aria-label="codedTheme Dribble"
                                component={Link}
                                href="https://dribbble.com/codedthemes"
                                target="_blank"
                            >
                                <SportsBasketballIcon sx={{ color: 'text.secondary', '&:hover': { color: 'warning.main' } }} />
                            </IconButton> */}
                        </Stack>
                    </Stack>
                </Container>
            </Box>
        </>
    );
};

export default FooterSection;
