// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Grid, Stack, Typography } from '@mui/material';

// third-party
import Slider from 'react-slick';

// assets
import BackgroundImag from 'assets/images/landing/illustrations/victory.png';
import { useTranslation } from 'hooks/useTranslation';

// styles
const HeaderAnimationImage = styled('img')({
    maxWidth: '100%',
    filter: 'drop-shadow(0px 0px 50px rgb(33 150 243 / 30%))'
});

// =============================|| LANDING - STARTUP PROJECT ||============================= //

const RtlInfoSection = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const settings = {
        className: 'center',
        dots: false,
        arrows: false,
        centerPadding: '0',
        centerMode: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        focusOnSelect: true,
        autoplay: true,
        autoplaySpeed: 2000
    };

    return (
        <Box
            className="project-info"
            sx={{
                bgcolor: 'dark.900',
                height: { xs: '80vh', md: 340, lg: 450, xl: 520 },
                overflow: 'hidden',
                position: 'relative',
                pl: { xs: 0, sm: 20 }
            }}
        >
            <Typography
                variant="h1"
                color="primary"
                sx={{
                    display: { sm: 'none' },
                    pt: 3
                    // fontSize: { xs: '1.563rem', md: '2.5rem', xl: '2rem' }
                }}
                textAlign="center"
            >
                {t('Choose Intellirecruit for')}
            </Typography>
            <Grid sx={{ position: 'relative', zIndex: 1 }}>
                <Box sx={{ mt: { xs: 4, md: 6, lg: 12, xl: 15 }, zIndex: 2 }}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-start"
                        spacing={2}
                        sx={{
                            '& .slick-current': {
                                '.MuiTypography-root': { color: theme.palette.primary.main }
                            },
                            '& .slick-slider': {
                                textAlign: 'left',
                                '.MuiTypography-root': {
                                    fontSize: { xs: '1.563rem', md: '2.5rem', xl: '3.125rem' },
                                    cursor: 'pointer'
                                }
                            }
                        }}
                    >
                        <Box
                            sx={{
                                marginTop: { sm: '5%', lg: '4%' },
                                width: { xs: '38%', md: '100%' },
                                paddingLeft: { xs: 2, md: 0, xl: 0 }
                            }}
                        >
                            <Typography
                                variant="h1"
                                sx={{
                                    color: '#fff',
                                    width: { xs: '150px', sm: 'max-content', xl: 'max-content' },
                                    display: { xs: 'none', sm: 'block' }
                                    // fontSize: { xs: '1.563rem', md: '2.5rem', xl: '2rem' }
                                }}
                            >
                                {t('Choose Intellirecruit for')}
                            </Typography>
                        </Box>
                        <Slider {...settings}>
                            <Typography color="text.hint" variant="h4">
                                {t('Multilingual Support')}
                            </Typography>
                            <Typography color="text.hint" variant="h4">
                                {t('Advanced Search and Filtering')}
                            </Typography>
                            <Typography color="text.hint" variant="h4">
                                {t('AI-Powered Job Matching')}
                            </Typography>
                            <Typography color="text.hint" variant="h4">
                                {t('Interview Scheduling and Reminders')}
                            </Typography>
                            <Typography color="text.hint" variant="h4">
                                {t('Analytics and Insights')}
                            </Typography>
                            <Typography color="text.hint" variant="h4">
                                {t('Secure Data Handling')}
                            </Typography>
                            <Typography color="text.hint" variant="h4">
                                {t('Seamless Job boards Integration')}
                            </Typography>
                            <Typography color="text.hint" variant="h4">
                                {t('Seamless Social Media Integration')}
                            </Typography>
                            <Typography color="text.hint" variant="h4">
                                {t('Secure Data Handling')}
                            </Typography>
                        </Slider>
                    </Stack>
                </Box>
            </Grid>
            <HeaderAnimationImage
                src={BackgroundImag}
                alt="Intellirecruit"
                sx={{
                    position: 'absolute',
                    filter: 'none',
                    bottom: { sm: -40, md: -80 },
                    right: { sm: 30, md: 50, lg: 80 },
                    width: { xl: 240, lg: 220, md: 150, sm: 100, xs: 0 }
                }}
            />
        </Box>
    );
};

export default RtlInfoSection;
