import { createContext, ReactNode } from 'react';

// project import
import defaultConfig from 'config';
import useLocalStorage from 'hooks/useLocalStorage';

// types
import { PaletteMode } from '@mui/material';
import { CustomizationProps } from 'types/config';

// import firebase from 'firebase/compat/app';
import 'firebase/compat/remote-config';

// initial state
const initialState: CustomizationProps = {
    ...defaultConfig,
    onChangeLayout: () => {},
    onChangeDrawer: () => {},
    onChangeMenuType: () => {},
    onChangePresetColor: () => {},
    onChangeLocale: () => {},
    onChangeRTL: () => {},
    onChangeContainer: () => {},
    onChangeFontFamily: () => {},
    onChangeBorderRadius: () => {},
    onChangeOutlinedField: () => {}
};

const ConfigContext = createContext(initialState);

type ConfigProviderProps = {
    children: ReactNode;
};

function ConfigProvider({ children }: ConfigProviderProps) {
    const [config, setConfig] = useLocalStorage('intellirecruit-config', {
        layout: initialState.layout,
        drawerType: initialState.drawerType,
        fontFamily: initialState.fontFamily,
        borderRadius: initialState.borderRadius,
        outlinedFilled: initialState.outlinedFilled,
        navType: initialState.navType,
        presetColor: initialState.presetColor,
        locale: initialState.locale,
        rtlLayout: initialState.rtlLayout
    });

    // // firebase initialize
    // if (!firebase.apps.length) {
    //     firebase.initializeApp({
    //         apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    //         authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    //         projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    //         storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    //         messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    //         appId: process.env.REACT_APP_FIREBASE_APP_ID,
    //         measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    //     });
    // }

    // // initialize remote config
    // const remoteConfig = firebase.remoteConfig();
    // remoteConfig.settings.minimumFetchIntervalMillis = 3600000; // fetch once per hour
    // // remoteConfig.defaultConfig = {
    // //     loginFlag: false
    // // };

    // // fetch remote config
    // remoteConfig.fetchAndActivate().then(() => {
    //     const remoteConfigData = remoteConfig.getAll();
    //     setConfig({
    //         ...config,
    //         ...remoteConfigData
    //     });
    // });

    const onChangeLayout = (layout: string) => {
        setConfig({
            ...config,
            layout
        });
    };

    const onChangeDrawer = (drawerType: string) => {
        setConfig({
            ...config,
            drawerType
        });
    };

    const onChangeMenuType = (navType: PaletteMode) => {
        setConfig({
            ...config,
            navType
        });
    };

    const onChangePresetColor = (presetColor: string) => {
        setConfig({
            ...config,
            presetColor
        });
    };

    const onChangeLocale = (locale: string) => {
        setConfig({
            ...config,
            locale
        });
    };

    const onChangeRTL = (rtlLayout: boolean) => {
        setConfig({
            ...config,
            rtlLayout
        });
    };

    const onChangeContainer = () => {
        setConfig({
            ...config,
            container: !config.container
        });
    };

    const onChangeFontFamily = (fontFamily: string) => {
        setConfig({
            ...config,
            fontFamily
        });
    };

    const onChangeBorderRadius = (event: Event, newValue: number | number[]) => {
        setConfig({
            ...config,
            borderRadius: newValue as number
        });
    };

    const onChangeOutlinedField = (outlinedFilled: boolean) => {
        setConfig({
            ...config,
            outlinedFilled
        });
    };

    return (
        <ConfigContext.Provider
            value={{
                ...config,
                onChangeLayout,
                onChangeDrawer,
                onChangeMenuType,
                onChangePresetColor,
                onChangeLocale,
                onChangeRTL,
                onChangeContainer,
                onChangeFontFamily,
                onChangeBorderRadius,
                onChangeOutlinedField
            }}
        >
            {children}
        </ConfigContext.Provider>
    );
}

export { ConfigProvider, ConfigContext };
