import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { DASHBOARD_PATH } from 'config';
import { GuardProps } from 'types';
import { useEffect } from 'react';

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }: GuardProps) => {
    const { isLoggedIn, user, isAdmin } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn) {
            if (isAdmin) {
                navigate(DASHBOARD_PATH, { replace: true });
            } else {
                navigate('/jobs', { replace: true });
            }
        }
    }, [isLoggedIn, navigate, user, isAdmin]);

    return children;
};

export default GuestGuard;
