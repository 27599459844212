import { cloneElement, useState, ReactElement, useMemo } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    AppBar as MuiAppBar,
    Box,
    Button,
    Container,
    Drawer,
    IconButton,
    Link,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Toolbar,
    Typography,
    useScrollTrigger,
    useMediaQuery
} from '@mui/material';

// project imports
import Logo from 'ui-component/Logo';

// assets
import { IconHome2 } from '@tabler/icons';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'hooks/useTranslation';
import LocalizationSection from 'layout/MainLayout/Header/LocalizationSection';
import { useNavigation } from 'hooks/useNavigation';
import useAuth from 'hooks/useAuth';
import Header from 'layout/MainLayout/Header';
import LAYOUT_CONST from 'constant';
import useConfig from 'hooks/useConfig';

// elevation scroll
interface ElevationScrollProps {
    children: ReactElement;
    window?: Window | Node;
}

function ElevationScroll({ children, window }: ElevationScrollProps) {
    const theme = useTheme();
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window!
    });

    return cloneElement(children, {
        elevation: trigger ? 1 : 0,
        style: {
            backgroundColor: theme.palette.mode === 'dark' && trigger ? theme.palette.dark[800] : theme.palette.background.default,
            color: theme.palette.text.dark
        }
    });
}

const AppBar = ({ ...others }) => {
    const [drawerToggle, setDrawerToggle] = useState<boolean>(false);
    const theme = useTheme();
    const { isLoggedIn } = useAuth();

    const drawerToggler = (open: boolean) => (event: any) => {
        if (event.type! === 'keydown' && (event.key! === 'Tab' || event.key! === 'Shift')) {
            return;
        }
        setDrawerToggle(open);
    };
    const { t } = useTranslation();

    const { navigateTo } = useNavigation();

    const EmployerloginClick = () => {
        navigateTo('/employer-login');
    };

    const JobSeekerloginClick = () => {
        navigateTo('/jobseeker-login');
    };

    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const { layout, isLoginEnabled } = useConfig();

    const condition = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd;
    const { user } = useAuth();
    const header = useMemo(
        () => (
            <Toolbar sx={{ p: condition ? '10px' : '16px 0 16px 0', pl: '0px !important', pr: '0px !important' }}>
                <Header />
            </Toolbar>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [layout, matchDownMd]
    );

    if (isLoggedIn && user?.role !== 'ADMIN') {
        return header;
    }

    return (
        <ElevationScroll {...others}>
            <MuiAppBar>
                <Container>
                    <Toolbar sx={{ py: 2.5, px: `0 !important` }}>
                        <Typography component="div" sx={{ flexGrow: 1, textAlign: 'left' }}>
                            <Logo />
                        </Typography>
                        <Stack direction="row" sx={{ display: { xs: 'none', sm: 'block' } }} spacing={{ xs: 1.5, md: 2.5 }}>
                            <Button color="inherit" component={'a'} href="/" target="_blank">
                                {t('Home')}
                            </Button>
                            <Button color="inherit" component={'a'} href="/mission" target="_blank">
                                {t('Mission')}
                            </Button>

                            <Button color="inherit" component={'a'} href="/job-templates" target="_blank">
                                {t('Job Templates')}
                            </Button>

                            {isLoginEnabled?._value === 'true' ? (
                                <>
                                    <Button color="inherit" component={Link} onClick={EmployerloginClick} target="_blank">
                                        {t('Employer Login')}
                                    </Button>
                                    <Button
                                        component={Link}
                                        onClick={JobSeekerloginClick}
                                        disableElevation
                                        variant="contained"
                                        sx={{ backgroundColor: theme.palette.primary[800], borderRadius: 25 }}
                                    >
                                        {t('JobSeeker Login')}
                                    </Button>
                                </>
                            ) : null}
                            <LocalizationSection expand={true} />
                        </Stack>
                        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                            <IconButton color="inherit" onClick={drawerToggler(true)} size="large">
                                <MenuIcon />
                            </IconButton>
                            <Drawer anchor="top" open={drawerToggle} onClose={drawerToggler(false)}>
                                {drawerToggle && (
                                    <Box
                                        sx={{ width: 'auto' }}
                                        role="presentation"
                                        onClick={drawerToggler(false)}
                                        onKeyDown={drawerToggler(false)}
                                    >
                                        <List>
                                            <Link style={{ textDecoration: 'none' }} href="#" target="_blank">
                                                <ListItemButton component="a">
                                                    <ListItemIcon>
                                                        <IconHome2 />
                                                    </ListItemIcon>
                                                    <ListItemText primary={t('Home')} />
                                                </ListItemButton>
                                            </Link>
                                            <Link style={{ textDecoration: 'none' }} href="/mission" target="_blank">
                                                <ListItemButton component="a">
                                                    <ListItemText primary={t('mission')} />
                                                </ListItemButton>
                                            </Link>
                                            <Link style={{ textDecoration: 'none' }} href="/job-templates" target="_blank">
                                                <ListItemButton component="a">
                                                    <ListItemText primary={t('Job Templates')} />
                                                </ListItemButton>
                                            </Link>
                                            {isLoginEnabled?._value === 'true' ? (
                                                <>
                                                    <Link style={{ textDecoration: 'none' }} href="/employer-login" target="_blank">
                                                        <ListItemButton component="a">
                                                            <ListItemText primary={t('Employer Login')} />
                                                        </ListItemButton>
                                                    </Link>
                                                    <Link style={{ textDecoration: 'none' }} href="/jobseeker-login" target="_blank">
                                                        <ListItemButton component="a">
                                                            <ListItemText primary={t('Jobseeker Login')} />
                                                        </ListItemButton>
                                                    </Link>
                                                </>
                                            ) : null}
                                        </List>
                                    </Box>
                                )}
                            </Drawer>
                        </Box>
                    </Toolbar>
                </Container>
            </MuiAppBar>
        </ElevationScroll>
    );
};

export default AppBar;
