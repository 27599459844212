// material-ui
import { Box, InputBase, alpha, styled } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';
import LocalizationSection from './LocalizationSection';
import NotificationSection from './NotificationSection';

// assets
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { useGetUserAccountDetailsQuery } from '@intellirecruit/graphql/dist/Wrappers/queries/useGetUserAccountDetails';
// import { useNavigation } from 'hooks/useNavigation';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import SearchIcon from '@mui/icons-material/Search';
import { MiniMenuSection } from './MiniMenuSection/MiniMenuSection';

interface IHeaderOptions {
    logo: boolean;
    menuButton: boolean;
    localizationSection: boolean;
    searchSection: boolean;
    megaMenuSection: boolean;
    miniMenuSection: boolean;
    createSection: boolean;
    notificationSection: boolean;
}

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto'
    }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    input: {
        background: 'inherit !important'
    },
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: `${theme.transitions.create('width')}`,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '40ch',
            '&:focus': {
                width: '60ch',
                borderBottom: '1px solid'
            }
        }
    }
}));

const Header = () => {
    const { user } = useAuth();
    const [headerOptions, setHeaderOptions] = useState<IHeaderOptions>();
    // const { navigateTo } = useNavigation();

    const accountVariables = {
        userIdentifier: user?.id ?? ''
    };

    const { data, error, loading } = useGetUserAccountDetailsQuery({
        variables: accountVariables,
        fetchPolicy: 'cache-and-network'
    });

    if (error) {
        console.log(error);
    }

    if (!loading && !data?.getUserDetail?.onboarding) {
        // navigateTo('/onboarding');
    }

    useEffect(() => {
        setHeaderOptions({
            logo: true,
            menuButton: false,
            localizationSection: true,
            searchSection: false,
            megaMenuSection: false,
            createSection: false,
            notificationSection: true,
            miniMenuSection: true
        });
    }, []);
    return (
        // <AppBar position="static">
        <Container sx={{ maxWidth: '100% !important' }}>
            <Toolbar disableGutters sx={{ p: 0 }}>
                {headerOptions?.logo ? (
                    <Typography
                        noWrap
                        sx={{
                            display: { xs: 'none', sm: 'flex' },
                            flexGrow: 1
                        }}
                    >
                        <LogoSection />
                    </Typography>
                ) : null}
                <MiniMenuSection />
                <Typography
                    noWrap
                    sx={{
                        display: { xs: 'flex', sm: 'none' },
                        flexGrow: 1
                    }}
                >
                    <LogoSection />
                </Typography>
                <Search>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase placeholder="Search…" inputProps={{ 'aria-label': 'search' }} />
                </Search>

                <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }} alignItems="center" justifyContent="center">
                    {headerOptions?.localizationSection ? (
                        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                            <LocalizationSection />
                        </Box>
                    ) : null}
                    {headerOptions?.notificationSection ? <NotificationSection /> : null}

                    <ProfileSection />
                </Box>
            </Toolbar>
        </Container>
    );
};

export default Header;
