import { AxiosResponse } from 'axios';
import axios from './axios';

export interface HttpError {
    code: number;
    description: string;
    status: string;
}

class HttpService {
    private handleResponse(response: AxiosResponse) {
        if (response.status >= 200 && response.status < 300) {
            return response.data;
        } else if (response.status >= 400 && response.status < 500) {
            throw new Error(`Client error: ${response.status} ${response.statusText}`);
        } else if (response.status >= 500 && response.status < 600) {
            throw new Error(`Server error: ${response.status} ${response.statusText}`);
        } else {
            throw new Error(`Unknown error: ${response.status} ${response.statusText}`);
        }
    }

    public async get(url: string) {
        return await axios.get(url).then(this.handleResponse);
    }

    public async post(url: string, data: any) {
        return await axios.post(url, data).then(this.handleResponse);
    }

    public async put(url: string, data: any) {
        return await axios.put(url, data).then(this.handleResponse);
    }
}

export const httpService = new HttpService();
