import { useRoutes } from 'react-router-dom';

// routes
import AuthenticationRoutes from './AuthenticationRoutes';
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import JobSeekerRoutes from './JobSeekerRoutes';

import useAuth from 'hooks/useAuth';
import UnderConstruction from 'views/pages/maintenance/UnderConstruction';
import GuestRoutes from './GuestRoutes';

const appendLocaleToRoutes = (routes: any, locale: string) => {
    return routes?.children?.map((route: any) => {
        const updatedRoute: any = {
            ...route,
            path: `/${locale}${route.path}`
        };

        if (route.children) {
            if (Array.isArray(route.children)) {
                updatedRoute.children = appendLocaleToRoutes(route.children, locale);
            } else {
                updatedRoute.children = appendLocaleToRoutes([route.children], locale)[0];
            }
        }

        return updatedRoute;
    });
};

const getLocaleRoutes = (locale: string) => {
    return [
        ...appendLocaleToRoutes(LoginRoutes, locale),
        ...appendLocaleToRoutes(AuthenticationRoutes, locale),
        ...appendLocaleToRoutes(JobSeekerRoutes, locale),
        ...appendLocaleToRoutes(MainRoutes, locale)
    ];
};

export default function useThemeRoutes() {
    const { user } = useAuth();
    const formattedRoutesForJobSeeker = user ? JobSeekerRoutes : {};
    const formattedRoutesForAdmin = user?.role === 'ADMIN' || user?.role === 'Admin' || user?.role === 2 ? MainRoutes : {};
    const allLocaleRoutes: any[] = [];
    ['hi', 'bn', 'kn', 'ta', 'te', 'gu', 'mr', 'or', 'pa', 'ml'].map((locale: string) => {
        return allLocaleRoutes.push(...getLocaleRoutes(locale));
    });
    allLocaleRoutes.push({
        path: '*',
        element: (
            <>
                <UnderConstruction />
            </>
        )
    });
    return useRoutes([
        LoginRoutes,
        AuthenticationRoutes,
        formattedRoutesForAdmin,
        formattedRoutesForJobSeeker,
        ...allLocaleRoutes,
        GuestRoutes
    ]);
}
