import { Box, Grid, Link, Tab, Tabs, Typography, useTheme } from '@mui/material';
import useConfig from 'hooks/useConfig';
import React from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { ExperienceCard } from './ExperienceCard';
import { IconFriends, IconInbox, IconPhoto, IconUsers } from '@tabler/icons';
import Chip from 'ui-component/extended/Chip';
import { useProfileStyles } from './Profile.styles';
import { useParams } from 'react-router-dom';

interface TabsProps {
    children?: React.ReactElement | React.ReactNode | string;
    value: string | number;
    index: number;
}

const tabOptions = [
    {
        to: '/user/social-profile/posts',
        icon: <IconInbox stroke={1.5} size="17px" />,
        label: (
            <>
                Experience <Chip label="4" size="small" chipcolor="secondary" sx={{ ml: 1.5 }} />
            </>
        )
    },
    {
        to: '/user/social-profile/follower',
        icon: <IconUsers stroke={1.5} size="17px" />,
        label: (
            <>
                Education <Chip label="3" size="small" chipcolor="secondary" sx={{ ml: 1.5 }} />
            </>
        )
    },
    {
        to: '/user/social-profile/friends',
        icon: <IconFriends stroke={1.5} size="17px" />,
        label: (
            <>
                Projects <Chip label="2" size="small" chipcolor="secondary" sx={{ ml: 1.5 }} />
            </>
        )
    },
    {
        to: '/user/social-profile/gallery',
        icon: <IconPhoto stroke={1.5} size="17px" />,
        label: (
            <>
                Accomplishments <Chip label="10" size="small" chipcolor="secondary" sx={{ ml: 1.5 }} />
            </>
        )
    }
];

function TabPanel({ children, value, index, ...other }: TabsProps) {
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

export const ProfessionalInformationCard: React.FC = () => {
    const theme = useTheme();
    const { borderRadius } = useConfig();
    const styles = useProfileStyles(theme, borderRadius);

    const { tab } = useParams();

    let selectedTab = 0;
    switch (tab) {
        case 'follower':
            selectedTab = 1;
            break;
        case 'friends':
            selectedTab = 2;
            break;
        case 'gallery':
            selectedTab = 3;
            break;
        case 'friend-request':
            selectedTab = 4;
            break;
        case 'posts':
        default:
            selectedTab = 0;
    }

    const [value, setValue] = React.useState<number>(selectedTab);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <MainCard>
            <Typography variant="h4">Professional Details</Typography>
            <Tabs value={value} variant="scrollable" onChange={handleChange} sx={styles.tabs}>
                {tabOptions.map((option, index) => (
                    <Tab
                        key={index}
                        icon={option.icon}
                        label={option.label}
                        iconPosition="start"
                        LinkComponent={Link}
                        {...a11yProps(index)}
                    />
                ))}
            </Tabs>
            <Grid item xs={12}>
                <TabPanel value={value} index={0}>
                    <Grid container spacing={gridSpacing} sx={styles.experienceContainer}>
                        <Grid item xs={12} onClick={() => {}} style={{ cursor: 'pointer' }}>
                            <ExperienceCard />
                        </Grid>
                        <Grid item xs={12} onClick={() => {}} style={{ cursor: 'pointer' }}>
                            <ExperienceCard />
                        </Grid>
                        <Grid item xs={12} onClick={() => {}} style={{ cursor: 'pointer' }}>
                            <ExperienceCard />
                        </Grid>
                        <Grid item xs={12} onClick={() => {}} style={{ cursor: 'pointer' }}>
                            <ExperienceCard />
                        </Grid>
                        <Grid item xs={12} onClick={() => {}} style={{ cursor: 'pointer' }}>
                            <ExperienceCard />
                        </Grid>
                    </Grid>
                </TabPanel>
            </Grid>
        </MainCard>
    );
};
