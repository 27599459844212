// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Avatar, useMediaQuery, IconButton } from '@mui/material';

// project imports
import LAYOUT_CONST from 'constant';
import useConfig from 'hooks/useConfig';
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import MobileSection from './MobileSection';
import ProfileSection from './ProfileSection';
import LocalizationSection from './LocalizationSection';
// import MegaMenuSection from './MegaMenuSection';
import NotificationSection from './NotificationSection';

import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';

// assets
import { IconMenu2 } from '@tabler/icons';
import CreateSection from './CreateSection/CreateSection';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { MiniMenuSection } from './MiniMenuSection/MiniMenuSection';
import { useTranslation } from 'hooks/useTranslation';
import { useGetUserAccountDetailsQuery } from '@intellirecruit/graphql/dist/Wrappers/queries/useGetUserAccountDetails';
// import { useNavigation } from 'hooks/useNavigation';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { getUserToken } from 'utils/getToken';

interface IHeaderOptions {
    logo: boolean;
    menuButton: boolean;
    localizationSection: boolean;
    searchSection: boolean;
    megaMenuSection: boolean;
    miniMenuSection: boolean;
    createSection: boolean;
    notificationSection: boolean;
    debug: boolean;
}

const Header = () => {
    const { t } = useTranslation();
    const theme = useTheme();

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);

    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const { layout } = useConfig();
    const { isAdmin, user } = useAuth();
    const [headerOptions, setHeaderOptions] = useState<IHeaderOptions>();
    // const { navigateTo } = useNavigation();

    const accountVariables = {
        userIdentifier: user?.id ?? ''
    };

    const { data, error, loading } = useGetUserAccountDetailsQuery({
        variables: accountVariables,
        fetchPolicy: 'cache-and-network'
    });

    if (error) {
        console.log(error);
    }

    if (!loading && !data?.getUserDetail?.onboarding) {
        // navigateTo('/onboarding');
    }

    const handleCopyToken = async () => {
        navigator.clipboard.writeText(`Bearer ${(await getUserToken()) ?? ''}`);
    };

    useEffect(() => {
        if (isAdmin) {
            setHeaderOptions({
                logo: false,
                menuButton: false,
                localizationSection: true,
                searchSection: true,
                megaMenuSection: true,
                createSection: true,
                notificationSection: true,
                miniMenuSection: false,
                debug: true
            });
        } else {
            setHeaderOptions({
                logo: true,
                menuButton: false,
                localizationSection: true,
                searchSection: false,
                megaMenuSection: false,
                createSection: false,
                notificationSection: false,
                miniMenuSection: true,
                debug: false
            });
        }
    }, [isAdmin]);
    return (
        <>
            {headerOptions?.logo || headerOptions?.menuButton ? (
                <Box
                    sx={{
                        width: 250,
                        display: 'flex',
                        [theme.breakpoints.down('md')]: {
                            width: 'auto'
                        }
                    }}
                >
                    {headerOptions?.logo ? (
                        <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                            <LogoSection />
                        </Box>
                    ) : null}
                    {headerOptions?.menuButton
                        ? (layout === LAYOUT_CONST.VERTICAL_LAYOUT || (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd)) && (
                              <Avatar
                                  variant="rounded"
                                  sx={{
                                      ...theme.typography.commonAvatar,
                                      ...theme.typography.mediumAvatar,
                                      overflow: 'hidden',
                                      transition: 'all .2s ease-in-out',
                                      background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                                      color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                                      '&:hover': {
                                          background:
                                              theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                                          color:
                                              theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
                                      }
                                  }}
                                  onClick={() => dispatch(openDrawer(!drawerOpen))}
                                  color="inherit"
                              >
                                  <IconMenu2 stroke={1.5} size="20px" />
                              </Avatar>
                          )
                        : null}
                </Box>
            ) : null}

            {headerOptions?.miniMenuSection ? <MiniMenuSection /> : null}
            {/* header search */}
            {headerOptions?.searchSection ? <SearchSection placeholder={t('Search')} /> : null}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            {/* mega-menu */}
            {/* {headerOptions?.megaMenuSection ? (
                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <MegaMenuSection />
                </Box>
            ) : null} */}

            {headerOptions?.debug && process.env.NODE_ENV === 'development' ? (
                <IconButton color="error" size="small" onClick={handleCopyToken}>
                    <ContentCopyIcon fontSize="small" />
                </IconButton>
            ) : null}

            {/* live customization & localization */}
            {headerOptions?.localizationSection ? (
                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <LocalizationSection />
                </Box>
            ) : null}

            {/* Create */}
            {headerOptions?.createSection ? (
                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <CreateSection />
                </Box>
            ) : null}

            {/* notification & profile */}
            {headerOptions?.notificationSection ? <NotificationSection /> : null}
            <ProfileSection />

            {/* mobile header */}
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileSection />
            </Box>
        </>
    );
};

export default Header;
