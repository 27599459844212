import React from 'react';
import { Card, CardContent, Grid, Button, Typography } from '@mui/material';
import { useTranslation } from 'hooks/useTranslation';
import { useTheme } from '@mui/material/styles';
import Avatar from 'ui-component/extended/Avatar';
import { grey } from '@mui/material/colors';
const MoreJobsView: React.FC = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    return (
        <Grid item xs={12}>
            <Card>
                <CardContent>
                    <Grid container sx={{ marginBottom: 5 }}>
                        <Grid item xs={12} rowSpacing={3}>
                            <Grid container rowSpacing={3}>
                                <Grid item xs={10}>
                                    <Typography variant="h2">{t('More Jobs from the Company')}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button>
                                        <Typography variant="h3" sx={{ color: theme.palette.primary.main }}>
                                            {t('View All Jobs')}
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container rowSpacing={3}>
                        <Grid item xs={12}>
                            <Grid container rowSpacing={2}>
                                <Grid item xs={12}>
                                    <Grid container rowSpacing={2}>
                                        <Grid item xs={6}>
                                            <Grid item xs={12}>
                                                <Grid container rowSpacing={2}>
                                                    <Grid item xs={2}>
                                                        <Avatar
                                                            sx={{ bgcolor: grey[100], color: 'white', width: 50, height: 50 }}
                                                            aria-label="Company Logo"
                                                            src="icon.png"
                                                        ></Avatar>
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h3">Senior Software Engineer</Typography>
                                                        </Grid>
                                                        <Typography variant="caption">Hyderabad, Telangana, India</Typography>
                                                        <Typography variant="h6" fontSize={'small'}>
                                                            Google India
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid item xs={12}>
                                                <Grid container rowSpacing={2}>
                                                    <Grid item xs={2}>
                                                        <Avatar
                                                            sx={{ bgcolor: grey[100], color: 'white', width: 50, height: 50 }}
                                                            aria-label="Company Logo"
                                                            src="icon.png"
                                                        ></Avatar>
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h3">Senior Software Engineer</Typography>
                                                        </Grid>
                                                        <Typography variant="caption">Hyderabad, Telangana, India</Typography>
                                                        <Typography variant="h6" fontSize={'small'}>
                                                            Google India
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container rowSpacing={2}>
                                        <Grid item xs={6}>
                                            <Grid item xs={12}>
                                                <Grid container rowSpacing={2}>
                                                    <Grid item xs={2}>
                                                        <Avatar
                                                            sx={{ bgcolor: grey[100], color: 'white', width: 50, height: 50 }}
                                                            aria-label="Company Logo"
                                                            src="icon.png"
                                                        ></Avatar>
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h3">Senior Software Engineer</Typography>
                                                        </Grid>
                                                        <Typography variant="caption">Hyderabad, Telangana, India</Typography>
                                                        <Typography variant="h6" fontSize={'small'}>
                                                            Google India
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid item xs={12}>
                                                <Grid container rowSpacing={2}>
                                                    <Grid item xs={2}>
                                                        <Avatar
                                                            sx={{ bgcolor: grey[100], color: 'white', width: 50, height: 50 }}
                                                            aria-label="Company Logo"
                                                            src="icon.png"
                                                        ></Avatar>
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h3">Senior Software Engineer</Typography>
                                                        </Grid>
                                                        <Typography variant="caption">Hyderabad, Telangana, India</Typography>
                                                        <Typography variant="h6" fontSize={'small'}>
                                                            Google India
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};
export default MoreJobsView;
