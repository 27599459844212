import { ModalContext } from 'contexts/ModalContext';
import React, { useContext, useCallback } from 'react';

export const useModal = (component: React.ReactElement) => {
    const { setModalComponent, removeModalComponent } = useContext(ModalContext);

    const showModal = useCallback(() => {
        setModalComponent(component);
    }, [component, setModalComponent]);

    return {
        open: showModal,
        close: removeModalComponent
    };
};
