import React from 'react';
import { Bookmark, BusinessCenter, Share } from '@mui/icons-material';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTime';
import PinDropIcon from '@mui/icons-material/PinDrop';
import PaymentsIcon from '@mui/icons-material/Payments';
import PeopleIcon from '@mui/icons-material/People';
import { Card, CardContent, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'hooks/useTranslation';
import BasicChildDetailView from './BasicDetailChildView';

const BasicDetailView: React.FC = () => {
    const { t } = useTranslation();
    return (
        <Grid item xs={12}>
            <Card>
                <CardContent>
                    <Grid container rowSpacing={1} direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Grid container rowSpacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h2">Senior Software Engineer</Typography>
                                </Grid>
                                <Grid item xs={12} columnSpacing={2}>
                                    <Grid container rowSpacing={1} columnGap={2}>
                                        <Grid item>
                                            <Typography variant="subtitle1" color="text.secondary">
                                                <AccessTimeFilledIcon fontSize="inherit" sx={{ mr: 0.5, verticalAlign: 'text-top' }} />
                                                Full Time
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1" color="text.secondary">
                                                <BusinessCenter fontSize="inherit" sx={{ mr: 0.5, verticalAlign: 'text-top' }} />
                                                7-9 years
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1" color="text.secondary">
                                                <PinDropIcon fontSize="inherit" sx={{ mr: 0.5, verticalAlign: 'text-top' }} />
                                                Hyderabad Telangana
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1" color="text.secondary">
                                                <PaymentsIcon fontSize="inherit" sx={{ mr: 0.5, verticalAlign: 'text-top' }} />
                                                20 Lakhs per annum
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1" color="text.secondary">
                                                <PeopleIcon fontSize="inherit" sx={{ mr: 0.5, verticalAlign: 'text-top' }} />
                                                10 Openings
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Tooltip placement="bottom" title={t('Click here to Share')}>
                                <IconButton>
                                    <Share />
                                </IconButton>
                            </Tooltip>
                            <Tooltip placement="bottom" title={t('Click here to Bookmark')}>
                                <IconButton>
                                    <Bookmark />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <BasicChildDetailView />
                </CardContent>
            </Card>
        </Grid>
    );
};
export default BasicDetailView;
