import { Typography, Grid, IconButton, useTheme } from '@mui/material';
import useConfig from 'hooks/useConfig';
import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { useProfileStyles } from './Profile.styles';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

export const PersonalDetailsCard: React.FC = () => {
    const theme = useTheme();
    const { borderRadius } = useConfig();
    const styles = useProfileStyles(theme, borderRadius);

    return (
        <MainCard>
            <Grid container>
                <Grid item xs={10}>
                    <Typography variant="h4">Personal Details</Typography>
                </Grid>
                <Grid item xs={2}>
                    <IconButton color="primary" aria-label="Edit Current Industry">
                        <ModeEditIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={styles.basicInformationGrid}>
                <Typography color={'text.secondary'}>Gender</Typography>
                <Typography color={'text.primary'}>Female</Typography>
            </Grid>
            <Grid item xs={12} sx={styles.basicInformationGrid}>
                <Typography color={'text.secondary'}>Marrital Status</Typography>
                <Typography color={'text.primary'}>married</Typography>
            </Grid>
            <Grid item xs={12} sx={styles.basicInformationGrid}>
                <Typography color={'text.secondary'}>Carrer Break</Typography>
                <Typography color={'text.primary'}>Not applicable</Typography>
            </Grid>
            <Grid item xs={12} sx={styles.basicInformationGrid}>
                <Typography color={'text.secondary'}>Disability</Typography>
                <Typography color={'text.primary'}>Not applicable</Typography>
            </Grid>
            <Grid item xs={12} sx={styles.basicInformationGrid}>
                <Typography color={'text.secondary'}>Work Permit</Typography>
                <Typography variant="h6" color={'primary'}>
                    Add Work permit
                </Typography>
            </Grid>
            <Grid item xs={12} sx={styles.basicInformationGrid}>
                <Typography color={'text.secondary'}>Langugages</Typography>
                <Typography color={'primary'}>Add Languages </Typography>
            </Grid>
        </MainCard>
    );
};
