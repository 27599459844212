import { Button, Grid, Typography, Stack, TextField, InputAdornment, Box, Autocomplete } from '@mui/material';
import { ModalContext } from 'contexts/ModalContext';
import React, { useContext, useMemo } from 'react';
import { Modal } from 'ui-component/extended/Modal/Modal';

import { useFormik } from 'formik';
import * as yup from 'yup';
import { gridSpacing } from 'store/constant';
import { openSnackbar } from 'store/slices/snackbar';
import { useDispatch } from 'store';
import AnimateButton from 'ui-component/extended/AnimateButton';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import currencyList from 'types/currencies.json';
import dailingCodesList from 'types/dailingCodes.json';
import Avatar from 'ui-component/extended/Avatar';

const validationSchema = yup.object({
    age: yup
        .number()
        .positive('Age must be a positive number')
        .integer('Age must be an integer')
        .min(18, 'Age must be at least 18 years old')
        .max(60, 'Age must not exceed 60 years old')
        .required('Age is required'),
    ctc: yup
        .string()
        .matches(/^[1-9]\d*$/, 'CTC must be a numeric value with a digit')
        .required('CTC is required'),
    experienceYears: yup
        .number()
        .positive()
        .min(0, 'Years must be atleast 0')
        .max(55, 'Years must not exceed 55')
        .required('Years of experience is required'),
    experienceMonths: yup
        .number()
        .positive()
        .min(0, 'Months must be atleast 0')
        .max(11, 'Months must not exceed 11')
        .required('Months of experience is required'),
    availability: yup.string().required('Availability is required'),
    // contactInfo: yup.string().required('Contact information is required'),
    extension: yup.object().required('Extension must be valid'),
    number: yup.number().required('Primary contact is required'),
    location: yup.string().nullable().required('Location is required')
});

export const BasicInformationModal: React.FC = () => {
    const dispatch = useDispatch();

    const { removeModalComponent } = useContext(ModalContext);

    const masterCurrencyList = useMemo(() => {
        return currencyList.currencies.flatMap((currency) => currency.code);
    }, []);

    const closeModal = () => {
        removeModalComponent();
    };

    const formik = useFormik({
        initialValues: {
            age: 25,
            experienceYears: 2,
            experienceMonths: 5,
            ctcCurrency: 'INR',
            ctc: 50000,
            location: 'Bengaluru, Kartnataka',
            extension: { code: 'IN', label: 'India', phone: '91' },
            number: '8973459893'
        },
        validationSchema,
        onSubmit: (values) => {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Submit Success',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
        }
    });

    return (
        <Modal title="Basic Information">
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Typography variant="caption">Age</Typography>
                                <TextField
                                    fullWidth
                                    id="age"
                                    name="age"
                                    value={formik.values.age}
                                    onChange={formik.handleChange}
                                    error={formik.touched.age && Boolean(formik.errors.age)}
                                    helperText={formik.touched.age && formik.errors.age}
                                />
                            </Grid>

                            <Grid item xs={8}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography variant="caption">Experience in years</Typography>
                                        <TextField
                                            fullWidth
                                            id="experienceYears"
                                            name="experienceYears"
                                            value={formik.values.experienceYears}
                                            onChange={formik.handleChange}
                                            error={formik.touched.experienceYears && Boolean(formik.errors.experienceYears)}
                                            helperText={formik.touched.experienceYears && formik.errors.experienceYears}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="caption">Experience in months</Typography>
                                        <TextField
                                            fullWidth
                                            id="experienceMonths"
                                            name="experienceMonths"
                                            value={formik.values.experienceMonths}
                                            onChange={formik.handleChange}
                                            error={formik.touched.experienceMonths && Boolean(formik.errors.experienceMonths)}
                                            helperText={formik.touched.experienceMonths && formik.errors.experienceMonths}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Typography variant="caption">CTC Currency</Typography>
                                <Autocomplete
                                    fullWidth
                                    value={formik.values.ctcCurrency}
                                    disableClearable
                                    onChange={(event, newValue) => {
                                        const jobExist = masterCurrencyList.includes(newValue);
                                        if (!jobExist) {
                                            const matchData = newValue.match(/"((?:\\.|[^"\\])*)"/);
                                            formik.setFieldValue('ctcCurrency', matchData && matchData[1]);
                                        } else {
                                            formik.setFieldValue('ctcCurrency', newValue);
                                        }
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    autoHighlight
                                    handleHomeEndKeys
                                    id="currency-list"
                                    options={masterCurrencyList}
                                    getOptionLabel={(option) => {
                                        let value = option;
                                        const jobExist = masterCurrencyList.includes(option);
                                        if (!jobExist) {
                                            const matchData = option.match(/"((?:\\.|[^"\\])*)"/);
                                            if (matchData && matchData[1]) value = matchData && matchData[1];
                                        }
                                        return value;
                                    }}
                                    renderOption={(props, option) => {
                                        return (
                                            <Box component="li" {...props}>
                                                {option}
                                            </Box>
                                        );
                                    }}
                                    freeSolo
                                    renderInput={() => (
                                        <TextField
                                            // {...params}
                                            variant="filled"
                                            name="ctcCurrency"
                                            error={formik.touched.ctcCurrency && Boolean(formik.errors.ctcCurrency)}
                                            helperText={
                                                formik.touched.ctcCurrency && formik.errors.ctcCurrency && formik.errors.ctcCurrency
                                            }
                                            placeholder="Select Currency"
                                            inputProps={{
                                                // ...params.InputProps,
                                                sx: { bgcolor: 'grey.0' },
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <ArrowDropDown sx={{ color: 'text.primary' }} />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="caption">Current CTC</Typography>
                                <TextField
                                    fullWidth
                                    id="ctc"
                                    name="ctc"
                                    value={formik.values.ctc}
                                    onChange={formik.handleChange}
                                    error={formik.touched.ctc && Boolean(formik.errors.ctc)}
                                    helperText={formik.touched.ctc && formik.errors.ctc}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Typography variant="caption">Dailing Code</Typography>
                                <Autocomplete
                                    fullWidth
                                    value={formik.values.extension}
                                    disableClearable
                                    onChange={(event, newValue) => {
                                        console.log(newValue);
                                        formik.setFieldValue('extension', newValue);
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    autoHighlight
                                    handleHomeEndKeys
                                    id="extension-list"
                                    options={dailingCodesList.dialing_codes}
                                    getOptionLabel={(option) => (typeof option === 'string' ? `+${option}` : `+${option?.phone}`)}
                                    renderOption={(props, option) => {
                                        return (
                                            <Box
                                                component="li"
                                                sx={{ width: 330, '& > img': { mr: 2, flexShrink: 0 } }}
                                                {...props}
                                                key={option.label}
                                            >
                                                <Avatar
                                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                    alt=""
                                                />
                                                {option.label} ({option.code}) +{option.phone}
                                            </Box>
                                        );
                                    }}
                                    freeSolo
                                    renderInput={() => (
                                        <TextField
                                            // {...params}
                                            name="extension"
                                            error={formik.touched.extension && Boolean(formik.errors.extension)}
                                            helperText={
                                                formik.touched.extension?.code &&
                                                formik.errors.extension?.code &&
                                                formik.errors.extension.code
                                            }
                                            placeholder="Select Extension"
                                            InputProps={{
                                                // ...params.InputProps,
                                                sx: { bgcolor: 'grey.0' },
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <ArrowDropDown sx={{ color: 'text.primary' }} />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="caption">Phone Number</Typography>
                                <TextField
                                    fullWidth
                                    id="number"
                                    name="number"
                                    value={formik.values.number}
                                    onChange={formik.handleChange}
                                    error={formik.touched.number && Boolean(formik.errors.number)}
                                    helperText={formik.touched.number && formik.errors.number}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="caption">Current Location</Typography>
                        <TextField
                            fullWidth
                            id="location"
                            name="location"
                            value={formik.values.location}
                            onChange={formik.handleChange}
                            error={formik.touched.location && Boolean(formik.errors.location)}
                            helperText={formik.touched.location && formik.errors.location}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="flex-end">
                            <AnimateButton>
                                <Button variant="text" onClick={closeModal}>
                                    cancel
                                </Button>
                            </AnimateButton>
                            <AnimateButton>
                                <Button variant="contained" type="submit">
                                    Save
                                </Button>
                            </AnimateButton>
                        </Stack>
                    </Grid>
                </Grid>
            </form>
        </Modal>
    );
};
