import { Grid, Avatar, Typography, Button } from '@mui/material';
import React from 'react';
import { gridSpacing } from 'store/constant';

export const ExperienceCard: React.FC = () => {
    return (
        <Grid container alignItems="center" spacing={gridSpacing} sx={{ flexWrap: 'nowrap' }}>
            <Grid item>
                <Avatar alt={'Oracle'} sx={{ width: 48, height: 48 }} />
            </Grid>
            <Grid item sm zeroMinWidth>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Typography variant="h4" component="div">
                            Oracle Pvt Ltd
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="caption">Senior Manager</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="caption">May 2014 - Present | Bangalore, Karnataka</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Button size="small">View Project</Button>
            </Grid>
        </Grid>
    );
};
