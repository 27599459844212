import React from 'react';

// material-ui
import { styled } from '@mui/material/styles';
import { Card, CardContent, Grid, Typography } from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';
import MailerSubscriber from './MailerSubscriber';

// assets
import FiberManualRecordTwoToneIcon from '@mui/icons-material/FiberManualRecordTwoTone';

const PageContentWrapper = styled('div')(({ theme }) => ({
    maxWidth: 550,
    margin: '0 0 0 auto',
    [theme.breakpoints.down('lg')]: {
        margin: '0 auto'
    },
    [theme.breakpoints.up(1400)]: {
        maxWidth: 600
    }
}));

const ComingSoonCard = styled(Card)(({ theme }) => ({
    minHeight: '70vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('lg')]: {
        display: 'block'
    },
    [theme.breakpoints.up(1200)]: {
        overflow: 'hidden',
        maxHeight: '100vh'
    },
    [theme.breakpoints.up(1400)]: {
        alignItems: 'center'
    }
}));

const ComingSoon1 = () => {
    return (
        <ComingSoonCard>
            <CardContent sx={{ padding: { xs: 3, xl: 10 }, margin: '0 auto' }}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <PageContentWrapper>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <Typography variant="h2" component="div" color="primary">
                                        Coming Soon
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="h1" component="div">
                                                Intellirecruit
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography sx={{ fontSize: '1.125rem' }}>
                                                Harnessing Artificial Intelligence for efficient hiring
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={gridSpacing}>
                                        <Grid item>
                                            <Typography
                                                variant="h5"
                                                component="div"
                                                color="secondary"
                                                sx={{ display: 'flex', alignItems: 'center' }}
                                            >
                                                <FiberManualRecordTwoToneIcon sx={{ mr: 0.625, fontSize: '1rem' }} />
                                                All Languages
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                variant="h5"
                                                component="div"
                                                color="secondary"
                                                sx={{ display: 'flex', alignItems: 'center' }}
                                            >
                                                <FiberManualRecordTwoToneIcon sx={{ mr: 0.625, fontSize: '1rem' }} />
                                                All Jobs
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                variant="h5"
                                                component="div"
                                                color="secondary"
                                                sx={{ display: 'flex', alignItems: 'center' }}
                                            >
                                                <FiberManualRecordTwoToneIcon sx={{ mr: 0.625, fontSize: '1rem' }} />
                                                All Recruitments
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                variant="h5"
                                                component="div"
                                                color="secondary"
                                                sx={{ display: 'flex', alignItems: 'center' }}
                                            >
                                                <FiberManualRecordTwoToneIcon sx={{ mr: 0.625, fontSize: '1rem' }} />
                                                All Categories
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                variant="h5"
                                                component="div"
                                                color="secondary"
                                                sx={{ display: 'flex', alignItems: 'center' }}
                                            >
                                                <FiberManualRecordTwoToneIcon sx={{ mr: 0.625, fontSize: '1rem' }} />
                                                All Locations
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <MailerSubscriber />
                                </Grid>
                            </Grid>
                        </PageContentWrapper>
                    </Grid>
                </Grid>
            </CardContent>
        </ComingSoonCard>
    );
};

export default ComingSoon1;
