import React, { useState, useMemo } from 'react';
import { Container, Typography, Grid, Button, ButtonGroup, CardMedia, Link, Stack, useTheme } from '@mui/material';
import { useTranslation } from 'hooks/useTranslation';
import AIPoweredInterviewsEmployerImage from 'assets/images/landing/illustrations/ai-powered-interviews.png';
import CustomCarrerEmployerImage from 'assets/images/landing/illustrations/custom-carrer-website.png';
import ApplicationTrackingEmployerImage from 'assets/images/landing/illustrations/application-tracking-employer.png';
import CollegeHiringEmployerImage from 'assets/images/landing/illustrations/college-hiring-graduation-employer.png';
import JobFairEmployerImage from 'assets/images/landing/illustrations/job-fairs-employer.png';
import InterviewAIAssistantJobSeekerImage from 'assets/images/landing/illustrations/navigator-job-seeker.png';
import MasterfulPersonalBrandingJobSeekerImage from 'assets/images/landing/illustrations/masterful-personal-branding.png';
import AnimateButton from 'ui-component/extended/AnimateButton';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import BusinessIcon from '@mui/icons-material/Business';
import EastIcon from '@mui/icons-material/East';
import { IRIcon } from 'ui-component/Icons';

export const BenefitsSection: React.FC = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState<string>('employer');
    const theme = useTheme();

    const handleTabClick = (id: string) => {
        setActiveTab(id);
    };
    const listSX = {
        display: 'flex',
        alignItems: 'center',
        gap: '0.7rem',
        padding: '10px 0',
        fontSize: '1rem',
        color: theme.palette.grey[900],
        svg: { color: theme.palette.secondary.main }
    };

    const data = useMemo(
        () => [
            {
                audienceType: 'job-seeker',
                title: t('Intellirecruit AI Assistant'),
                description: t(
                    'Elevate your job search with Intellirecruit AI Assistant, your AI-powered assistant for personalized job recommendations, real-time insights, and interview readiness. Your career journey, supercharged.'
                ),
                mediaAlign: 'right',
                image: InterviewAIAssistantJobSeekerImage,
                benefits: [
                    {
                        title: t('Your Personalized Navigator'),
                        description: t(
                            'Intellirecruit AI Assistant understands your career aspirations and preferences. It scours the job landscape to find positions that resonate with your goals, ensuring every recommendation is tailored just for you.'
                        ),
                        icon: 'explore'
                    },
                    {
                        title: t('Personalized Guidance'),
                        description: t('Tailored job recommendations aligned with your career goals and preferences.'),
                        icon: 'star'
                    },
                    {
                        title: t('Real-Time Insights'),
                        description: t(
                            'Stay ahead of the curve with up-to-the-minute insights into job market trends, salary ranges, and demand for specific skills, empowering you to make informed decisions.'
                        ),
                        icon: 'insights'
                    },
                    {
                        title: t('Effortless Applications'),
                        description: t('Simplified application management with tracking and reminders'),
                        icon: 'task_alt'
                    },
                    {
                        title: t('Resume Enhancement'),
                        description: t("AI-powered suggestions for optimizing your resume's impact"),
                        icon: 'description'
                    },
                    {
                        title: t('Interview Excellence'),
                        description: t('Practice interviews with AI simulations and receive feedback'),
                        icon: 'question_answer'
                    },
                    {
                        title: t('Confidentiality Assurance'),
                        description: t(
                            "Your job search remains confidential. AI Assistant ensures your current employer won't know about your explorations until you're ready to share the news."
                        ),
                        icon: 'lock'
                    }
                ],
                knowMore: {
                    title: t('Know More'),
                    link: 'https://intellirecruit.co.in'
                }
            },
            {
                audienceType: 'job-seeker',
                title: t('Masterful Personal Branding'),
                description: t(
                    " Discover how crafting a compelling personal brand can set you apart in today's competitive job market. Elevate your online presence, showcase your unique strengths, and create a lasting impact that resonates with employers and peers alike."
                ),
                mediaAlign: 'left',
                image: MasterfulPersonalBrandingJobSeekerImage,
                benefits: [
                    {
                        title: t('Distinctive Identity'),
                        description: t(
                            'Forge a distinctive personal brand that reflects your values, skills, and aspirations, making you instantly recognizable and memorable.'
                        ),
                        icon: 'fingerprint'
                    },
                    {
                        title: t('Career Alignment'),
                        description: t(
                            'Align your personal brand with your career goals, ensuring every element of your online presence contributes to your professional advancement.'
                        ),
                        icon: 'work'
                    },
                    {
                        title: t('Credibility Boost'),
                        description: t(
                            'Establish yourself as an industry thought leader and trusted expert, boosting your credibility and positioning you as an authority in your field.'
                        ),
                        icon: 'verified'
                    },
                    {
                        title: t('Networking Magnet'),
                        description: t(
                            'Attract valuable connections and opportunities by showcasing your expertise, enabling you to grow a network that supports your career growth.'
                        ),
                        icon: 'group'
                    },
                    {
                        title: t('Employer Attraction'),
                        description: t(
                            'Impress potential employers with a consistent and engaging personal brand that highlights your strengths, enhancing your desirability as a candidate.'
                        ),
                        icon: 'star'
                    },
                    {
                        title: t('Storytelling Power'),
                        description: t(
                            'Craft a compelling narrative that communicates your journey, experiences, and achievements, capturing the essence of who you are as a professional.'
                        ),
                        icon: 'book'
                    },
                    {
                        title: t('Enhanced Online Visibility'),
                        description: t(
                            "Optimize your online presence to ensure you're easily discoverable by recruiters, colleagues, and industry peers."
                        ),
                        icon: 'visibility'
                    },
                    {
                        title: t('Confident Communication'),
                        description: t(
                            'Develop the skills to effectively communicate your value proposition, whether in interviews, networking events, or presentations.'
                        ),
                        icon: 'message'
                    },
                    {
                        title: t('Long-Term Investment'),
                        description: t(
                            'Your personal brand is an asset that can lead to continuous opportunities and growth throughout your career journey.'
                        ),
                        icon: 'trending_up'
                    },
                    {
                        title: t('Self-Empowerment'),
                        description: t(
                            'Embrace your personal brand as a tool for self-empowerment, building a foundation of confidence that radiates in every professional interaction.'
                        ),
                        icon: 'power'
                    }
                ],
                knowMore: {
                    title: t('Know More'),
                    link: 'https://intellirecruit.co.in'
                }
            },
            {
                audienceType: 'employer',
                title: t('Elevate Hiring with AI-Powered Interviews'),
                description: t(
                    'Discover how harnessing the power of AI in interviews can revolutionize your hiring process. Streamline candidate evaluations, uncover hidden talents, and make data-driven decisions to secure the best-fit candidates for your organization.'
                ),
                mediaAlign: 'right',
                image: AIPoweredInterviewsEmployerImage,
                benefits: [
                    {
                        title: t('In-depth Candidate Insights'),
                        secondaryTitle: t('Unlock a deeper understanding of candidates.'),
                        description: t(
                            "Leverage AI to analyze verbal and non-verbal cues, sentiment, and language patterns, gaining valuable insights into candidates' personalities and compatibility with your company culture."
                        ),
                        icon: 'visibility'
                    },
                    {
                        title: t('Effortless Screening'),
                        secondaryTitle: t('Sift through candidates effortlessly.'),
                        description: t(
                            'Automate the initial screening process by allowing AI to assess resumes, portfolios, and applications. Save time and resources by focusing your attention on the most promising candidates.'
                        ),
                        icon: 'search'
                    },
                    {
                        title: t('Bias-Free Selection'),
                        secondaryTitle: t('Ensure fairness and diversity.'),
                        description: t(
                            "AI helps mitigate unconscious bias by focusing on candidates' qualifications and responses rather than personal traits. This promotes a more inclusive and equitable hiring environment."
                        ),
                        icon: 'verified_user'
                    },
                    {
                        title: t('Predictive Performance Analysis'),
                        secondaryTitle: t('Make informed decisions.'),
                        description: t(
                            "Utilize AI algorithms to predict a candidate's potential performance based on historical data and patterns. Make data-driven choices that align with your company's goals."
                        ),
                        icon: 'trending_up'
                    },
                    {
                        title: t('Seamless Collaboration'),
                        secondaryTitle: t('Streamline the evaluation process.'),
                        description: t(
                            'Collaborate effortlessly with team members and hiring managers by centralizing candidate data and interview feedback in a user-friendly AI-powered platform.'
                        ),
                        icon: 'group'
                    },
                    {
                        title: t('Customized Interview Frameworks'),
                        secondaryTitle: t('Tailor interviews to your needs.'),
                        description: t(
                            'Develop interview questions and scenarios that align with the specific role and organizational requirements. AI can help identify the most relevant questions for each candidate.'
                        ),
                        icon: 'format_list_numbered'
                    },
                    {
                        title: t('Real-time Language Translation'),
                        secondaryTitle: t('Break down language barriers.'),
                        description: t(
                            'Interview candidates from around the world without communication obstacles. AI-powered language translation ensures seamless communication, fostering a global talent pool.'
                        ),
                        icon: 'translate'
                    }
                ],
                knowMore: {
                    title: t('Know More'),
                    link: 'https://intellirecruit.co.in'
                }
            },
            {
                audienceType: 'employer',

                title: t('Elevate Your Brand with a Custom Career Website'),
                description: t(
                    'Discover how a tailored career website can enhance your brand, attract top talent, and provide a seamless candidate experience. From showcasing your company culture to presenting job opportunities in a user-friendly manner, a custom career website is the gateway to building a strong and compelling online presence.'
                ),
                mediaAlign: 'left',
                image: CustomCarrerEmployerImage,
                benefits: [
                    {
                        title: t('Branding Consistency'),
                        description: t(
                            "Align your online presence with your brand identity. Ensure a consistent and visually appealing representation of your company's values, mission, and culture through custom design elements, colors, and imagery."
                        ),
                        icon: 'palette'
                    },
                    {
                        title: t('Engaging Company Culture'),
                        description: t(
                            'Showcase what makes your company unique. Create an interactive platform to share employee testimonials, behind-the-scenes content, and insights into your workplace environment, allowing candidates to connect with your company culture.'
                        ),
                        icon: 'group'
                    },
                    {
                        title: t('User-Centric Experience'),
                        description: t(
                            'Simplify navigation and application processes. Craft an intuitive and user-friendly interface that guides candidates through job listings, application steps, and frequently asked questions, enhancing their overall experience.'
                        ),
                        icon: 'touch_app'
                    },
                    {
                        title: t('Personalized Job Recommendations'),
                        description: t(
                            'Match candidates with suitable opportunities. Implement AI-driven algorithms that analyze candidate profiles and preferences, suggesting relevant job openings and increasing the chances of finding the perfect fit.'
                        ),
                        icon: 'light_bulb'
                    },
                    {
                        title: t('Storytelling Through Content'),
                        description: t(
                            "Convey your company's story effectively. Utilize multimedia content such as videos, blog posts, and interactive timelines to narrate your company's journey, values, achievements, and future aspirations."
                        ),
                        icon: 'book'
                    },
                    {
                        title: t('Mobile-Friendly Design'),
                        description: t(
                            'Reach candidates on any device. Ensure your career website is responsive and optimized for mobile devices, enabling candidates to explore job opportunities seamlessly from smartphones and tablets.'
                        ),
                        icon: 'smart_phone'
                    },
                    {
                        title: t('Application Tracking'),
                        description: t(
                            'Keep candidates informed. Integrate applicant tracking systems that provide candidates with real-time updates on their application status, creating transparency and reducing uncertainty.'
                        ),
                        icon: 'assignment_turned_in'
                    },
                    {
                        title: t('Seamless Integration'),
                        description: t(
                            'Connect with your existing systems. Integrate your career website with your HR software, allowing for streamlined candidate data management, interview scheduling, and communication.'
                        ),
                        icon: 'sync'
                    },
                    {
                        title: t('Analytics and Insights'),
                        description: t(
                            'Gain valuable recruitment insights. Utilize analytics tools to track website traffic, applicant sources, and conversion rates, enabling data-driven decisions for continuous improvement.'
                        ),
                        icon: 'insights'
                    },
                    {
                        title: t('Recruitment Marketing'),
                        description: t(
                            'Attract passive candidates. Use your custom career website as a hub for recruitment marketing campaigns, enticing passive candidates to explore opportunities and engage with your company.'
                        ),
                        icon: 'campaign'
                    }
                ],
                knowMore: {
                    title: t('Know More'),
                    link: 'https://intellirecruit.co.in'
                }
            },
            {
                audienceType: 'employer',
                title: t('Application Tracking System: Streamlined Candidate Management'),
                mediaAlign: 'right',
                image: ApplicationTrackingEmployerImage,
                description: t(
                    'Keep candidates informed. Integrate applicant tracking systems that provide candidates with real-time updates on their application status, creating transparency and reducing uncertainty.'
                ),
                benefits: [
                    {
                        title: t('Real-Time Updates'),
                        secondarytitle: t('Stay in the Know'),
                        description: t(
                            'Offer candidates instant notifications about their application progress, from submission to review stages. Eliminate the anxiety of waiting by providing timely status updates.'
                        ),
                        icon: 'access_time'
                    },
                    {
                        title: t('Centralized Data'),
                        secondarytitle: t('Efficient Information Management'),
                        description: t(
                            'Collect and store candidate data in a centralized platform. Easily access and manage resumes, contact details, interview notes, and evaluations in one location.'
                        ),
                        icon: 'storage'
                    },
                    {
                        title: t('Collaborative Evaluation'),
                        secondarytitle: t('Enhance Team Communication'),
                        description: t(
                            'Facilitate collaboration among hiring team members. Allow them to share feedback, ratings, and comments on candidates, promoting informed decision-making.'
                        ),
                        icon: 'group_add'
                    },
                    {
                        title: t('Automated Workflows'),
                        secondarytitle: t('Effortless Progression'),
                        description: t(
                            'Automate the progression of candidates through the hiring stages. Set up predefined workflows to ensure consistent and efficient candidate management.'
                        ),
                        icon: 'compare_arrows'
                    },
                    {
                        title: t('Customized Communication'),
                        secondarytitle: t('Personalized Interaction'),
                        description: t(
                            'Send automated, personalized emails to candidates at different stages of the process. Keep them engaged and informed while maintaining a professional touch.'
                        ),
                        icon: 'email'
                    },
                    {
                        title: t('Data-Driven Insights'),
                        secondarytitle: t('Informed Decision-Making'),
                        description: t(
                            'Leverage analytics to gain insights into your hiring process. Identify bottlenecks, track conversion rates, and fine-tune your recruitment strategy for optimal results.'
                        ),
                        icon: 'insights'
                    },
                    {
                        title: t('Enhanced Compliance'),
                        secondarytitle: t('Adherence to Regulations'),
                        description: t(
                            'Ensure compliance with hiring regulations and data privacy standards. ATS solutions often include features that help you maintain legal and ethical hiring practices.'
                        ),
                        icon: 'security'
                    },
                    {
                        title: t('Efficient Communication'),
                        secondarytitle: t('Candidate Engagement'),
                        description: t(
                            'Use the ATS to facilitate communication with candidates. Schedule interviews, provide feedback, and answer queries to create a positive candidate experience.'
                        ),
                        icon: 'chat'
                    },
                    {
                        title: t('Custom Reports'),
                        secondarytitle: t('Tailored Insights'),
                        description: t(
                            'Generate customized reports on recruitment metrics. Evaluate your sourcing strategies, candidate pool quality, and overall recruitment performance.'
                        ),
                        icon: 'bar_chart'
                    }
                ],
                knowMore: {
                    title: t('Know More'),
                    link: 'https://intellirecruit.co.in'
                }
            },
            {
                audienceType: 'employer',
                mediaAlign: 'left',
                image: CollegeHiringEmployerImage,
                title: t('College Hiring: Connecting with Future Talent'),
                description: t(
                    'Discover the power of recruiting directly from universities and colleges. College hiring opens doors to fresh talent, innovative perspectives, and enthusiastic candidates ready to embark on their professional journeys.'
                ),
                benefits: [
                    {
                        title: t('Early Talent Acquisition'),
                        secondarytitle: t('Nurturing Potential'),
                        description: t(
                            "Identify and secure promising candidates before they enter the competitive job market. Build strong relationships with students who align with your company's values and goals."
                        ),
                        icon: 'school'
                    },
                    {
                        title: t('Diverse Skillsets'),
                        secondarytitle: t('Embracing Versatility'),
                        description: t(
                            'Tap into a diverse pool of talents from various disciplines. College graduates bring fresh perspectives, adaptability, and a willingness to learn.'
                        ),
                        icon: 'layers'
                    },
                    {
                        title: t('Company Visibility'),
                        secondarytitle: t('Showcasing Your Brand'),
                        description: t(
                            "Create a strong presence on campuses through career fairs, workshops, and presentations. Enhance your brand's recognition among students seeking meaningful career opportunities."
                        ),
                        icon: 'visibility'
                    },
                    {
                        title: t('Internship Pipelines'),
                        secondarytitle: t('Nurturing Future Leaders'),
                        description: t(
                            'Establish a pipeline of potential full-time employees through internships. Give students a taste of your company culture, allowing them to grow into valuable team members.'
                        ),
                        icon: 'timeline'
                    },
                    {
                        title: t('Fresh Perspectives'),
                        secondarytitle: t('Fostering Innovation'),
                        description: t(
                            "Infuse your teams with new ideas and perspectives. College hires often bring innovative thinking that can contribute to your company's growth and evolution."
                        ),
                        icon: 'light_bulb'
                    },
                    {
                        title: t('Customized Training'),
                        secondarytitle: t('Molding Talent'),
                        description: t(
                            "Mold early-career hires to fit your company's specific needs. Provide tailored training and mentorship to help them develop essential skills for success."
                        ),
                        icon: 'build'
                    },
                    {
                        title: t('Long-Term Investment'),
                        secondarytitle: t('Growing Together'),
                        description: t(
                            "Invest in building a loyal workforce from the ground up. College hires tend to stay with companies longer, fostering stability and contributing to your company's future."
                        ),
                        icon: 'trending_up'
                    },
                    {
                        title: t('Campus Relationships'),
                        secondarytitle: t('Cultivating Partnerships'),
                        description: t(
                            'Develop strong relationships with academic institutions. Collaborate on projects, research, and guest lectures to establish a mutually beneficial partnership.'
                        ),
                        icon: 'people'
                    },
                    {
                        title: t('Fresh Enthusiasm'),
                        secondarytitle: t('Infusing Energy'),
                        description: t(
                            'Leverage the enthusiasm and energy of recent graduates. Their excitement for new challenges can drive positive changes and elevate team morale.'
                        ),
                        icon: 'emoji_emotions'
                    }
                ],
                knowMore: {
                    title: t('Know More'),
                    link: 'https://intellirecruit.co.in'
                }
            },
            {
                audienceType: 'employer',
                mediaAlign: 'right',
                image: JobFairEmployerImage,
                title: t('Job Fairs: Connecting Opportunities and Talent'),
                description: t(
                    "Explore the dynamic world of job fairs, where employers and job seekers converge under one roof. Engage with a diverse talent pool, showcase your company culture, and identify candidates who align with your organization's values."
                ),
                benefits: [
                    {
                        title: t('Networking Hub'),
                        secondarytitle: t('Building Connections'),
                        description: t(
                            'Connect directly with potential candidates and industry peers. Job fairs provide a platform to expand your professional network and create lasting relationships.'
                        ),
                        icon: 'network_check'
                    },
                    {
                        title: t('Face-to-Face Interactions'),
                        secondarytitle: t('Personalized Engagement'),
                        description: t(
                            'Engage in real-time conversations with candidates. Assess soft skills, communication abilities, and cultural fit through direct interactions.'
                        ),
                        icon: 'chat_bubble'
                    },
                    {
                        title: t('Diverse Talent Pool'),
                        secondarytitle: t('Welcoming Variety'),
                        description: t(
                            "Meet candidates with different backgrounds, experiences, and skills. Job fairs attract a wide range of talents that can contribute to your company's growth."
                        ),
                        icon: 'layers'
                    },
                    {
                        title: t('Company Exposure'),
                        secondarytitle: t('Showcasing Your Brand'),
                        description: t(
                            "Enhance your company's visibility among job seekers. Stand out as an employer of choice by creating an appealing booth and engaging presentations."
                        ),
                        icon: 'visibility'
                    },
                    {
                        title: t('Instant Screening'),
                        secondarytitle: t('Quick Candidate Evaluation'),
                        description: t(
                            "Assess candidates' suitability on the spot. Use initial conversations to gauge their interest, motivation, and alignment with your company's values."
                        ),
                        icon: 'how_to_reg'
                    },
                    {
                        title: t('Recruitment Marketing'),
                        secondarytitle: t('Strategic Attraction'),
                        description: t(
                            "Market your job openings effectively. Attract passive job seekers and those exploring new opportunities by highlighting your company's strengths."
                        ),
                        icon: 'campaign'
                    },
                    {
                        title: t('Real-Time Feedback'),
                        secondarytitle: t('Immediate Insights'),
                        description: t(
                            'Receive instant feedback from candidates about their perceptions of your company and recruitment process. Use this information to make necessary adjustments.'
                        ),
                        icon: 'feedback'
                    },
                    {
                        title: t('Candidate Impressions'),
                        secondarytitle: t('Creating Memories'),
                        description: t(
                            'Make a memorable impression on candidates. Engaging booth designs, interactive activities, and meaningful conversations can leave a lasting positive impact.'
                        ),
                        icon: 'memory'
                    },
                    {
                        title: t('Talent Pipeline'),
                        secondarytitle: t('Nurturing Relationships'),
                        description: t(
                            'Build a pipeline of potential candidates for future openings. Collect contact information and maintain communication to tap into this talent pool later.'
                        ),
                        icon: 'timeline'
                    }
                ],
                knowMore: {
                    title: t('Know More'),
                    link: 'https://intellirecruit.co.in'
                }
            }
        ],
        [t]
    );

    return (
        <Container
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <Grid container justifyContent={'center'} textAlign={'center'} spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h1">{t('Solution - Unlocking Possibilities')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2" sx={{ fontSize: '1.1rem' }}>
                        {t('Your Journey, Our Expertise: Innovation in Career Solutions')}
                    </Typography>
                </Grid>
                <Grid item xs={8} sx={{ mt: 4, display: { xs: 'none', sm: 'block' } }}>
                    <ButtonGroup disableElevation variant="contained" aria-label="Disabled elevation buttons">
                        <Button
                            sx={{
                                p: 2,
                                borderRadius: 4,
                                pl: 10,
                                pr: 10,
                                backgroundColor: activeTab === 'job-seeker' ? theme.palette.primary[800] : theme.palette.primary[200]
                            }}
                            onClick={() => handleTabClick('job-seeker')}
                        >
                            <WorkOutlineIcon />
                            <Typography variant="h3" color="white" sx={{ pl: 2 }}>
                                {t('Jobseeker')}
                            </Typography>
                        </Button>
                        <Button
                            sx={{
                                p: 2,
                                borderRadius: 4,
                                pl: 10,
                                pr: 10,
                                backgroundColor: activeTab === 'employer' ? theme.palette.primary[800] : theme.palette.primary[200]
                            }}
                            onClick={() => handleTabClick('employer')}
                        >
                            <BusinessIcon />
                            <Typography variant="h3" color="white" sx={{ pl: 2 }}>
                                {t('Employer')}
                            </Typography>
                        </Button>
                    </ButtonGroup>
                </Grid>
                <Grid item xs={12} sx={{ mt: 4, display: { xs: 'block', sm: 'none' } }}>
                    <Typography variant="h3" color="primary" sx={{ pl: 2 }}>
                        {t('Employer / Retail / Business Customer Solutions')}
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'none' }, pt: '0 !important' }}>
                    {data
                        ?.filter((section) => section.audienceType === 'employer')
                        .map((section) => (
                            <Grid item xs={12} sx={{ mt: 16, p: '0 !important' }} key={section.title}>
                                <Grid container spacing={12}>
                                    <Grid item xs={12} sx={{ pt: '0 !important' }}>
                                        <Typography variant="h2" sx={{ fontSize: { xs: '1.5rem', sm: '2.125rem' }, mb: 2 }}>
                                            {section?.title}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} sx={{ pt: '0 !important' }}>
                                        <Stack sx={{ mb: 5, mx: 'auto' }}>
                                            <CardMedia component="img" image={section.image} alt="Layer" />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} md={6} sx={{ pt: '0 !important' }}>
                                        <Grid container spacing={2.5} textAlign={'left'}>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="text.primary"
                                                    sx={{
                                                        fontSize: '1rem',
                                                        zIndex: '99',
                                                        width: { xs: '100%', sm: '100%', md: 'calc(100% - 20%)' }
                                                    }}
                                                >
                                                    {section?.description}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    {section?.benefits.map((benefit) => (
                                                        <Grid item xs={12} key={benefit?.title}>
                                                            <Typography sx={listSX}>
                                                                <IRIcon iconName={benefit?.icon ?? ''} size={20} />
                                                                {benefit?.title}
                                                            </Typography>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                                {/* <Stack direction="row">
                                                    <AnimateButton>
                                                        <Button
                                                            startIcon={<EastIcon />}
                                                            sx={{ boxShadow: 'none', my: 4, backgroundColor: theme.palette.primary[800] }}
                                                            variant="contained"
                                                            component={Link}
                                                            href={section?.knowMore?.link}
                                                            target="_blank"
                                                        >
                                                            {section?.knowMore?.title}
                                                        </Button>
                                                    </AnimateButton>
                                                </Stack> */}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                </Grid>

                <Grid item xs={12} sx={{ mt: 4, display: { xs: 'block', sm: 'none' } }}>
                    <Typography variant="h3" color="primary" sx={{ pl: 2 }}>
                        {t('Job Seeker Solutions')}
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'none' }, pt: '0 !important' }}>
                    {data
                        ?.filter((section) => section.audienceType === 'job-seeker')
                        .map((section) => (
                            <Grid item xs={12} sx={{ mt: 16, p: '0 !important' }} key={section.title}>
                                <Grid container spacing={12}>
                                    <Grid item xs={12} sx={{ pt: '0 !important' }}>
                                        <Typography variant="h2" sx={{ fontSize: { xs: '1.5rem', sm: '2.125rem' }, mb: 2 }}>
                                            {section?.title}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} sx={{ pt: '0 !important' }}>
                                        <Stack sx={{ mb: 5, mx: 'auto' }}>
                                            <CardMedia component="img" image={section.image} alt="Layer" />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} md={6} sx={{ pt: '0 !important' }}>
                                        <Grid container spacing={2.5} textAlign={'left'}>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="text.primary"
                                                    sx={{
                                                        fontSize: '1rem',
                                                        zIndex: '99',
                                                        width: { xs: '100%', sm: '100%', md: 'calc(100% - 20%)' }
                                                    }}
                                                >
                                                    {section?.description}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    {section?.benefits.map((benefit) => (
                                                        <Grid item xs={12} key={benefit?.title}>
                                                            <Typography sx={listSX}>
                                                                <IRIcon iconName={benefit?.icon ?? ''} size={20} />
                                                                {benefit?.title}
                                                            </Typography>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                                {/* <Stack direction="row">
                                                    <AnimateButton>
                                                        <Button
                                                            startIcon={<EastIcon />}
                                                            sx={{ boxShadow: 'none', my: 4, backgroundColor: theme.palette.primary[800] }}
                                                            variant="contained"
                                                            component={Link}
                                                            href={section?.knowMore?.link}
                                                            target="_blank"
                                                        >
                                                            {section?.knowMore?.title}
                                                        </Button>
                                                    </AnimateButton>
                                                </Stack> */}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                </Grid>
                {data
                    ?.filter((section) => section.audienceType === activeTab)
                    .map((section) => (
                        <Grid item xs={12} sx={{ mt: 16, display: { xs: 'none', sm: 'block' } }} key={section.title}>
                            <Grid container spacing={12}>
                                <Grid item xs={12} md={6} order={section?.mediaAlign === 'left' ? 0 : 2}>
                                    <Stack sx={{ mb: 5, mx: 'auto' }}>
                                        <CardMedia component="img" image={section.image} alt="Layer" />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid container spacing={2.5} textAlign={'left'}>
                                        <Grid item xs={12}>
                                            <Typography variant="h2" sx={{ fontSize: { xs: '1.5rem', sm: '2.125rem' }, mb: 2 }}>
                                                {section?.title}
                                            </Typography>
                                            <Typography
                                                variant="subtitle2"
                                                color="text.primary"
                                                sx={{
                                                    fontSize: '1rem',
                                                    zIndex: '99',
                                                    width: { xs: '100%', sm: '100%', md: 'calc(100% - 20%)' }
                                                }}
                                            >
                                                {section?.description}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                {section?.benefits.map((benefit) => (
                                                    <Grid item xs={6} key={benefit?.title}>
                                                        <Typography sx={listSX}>
                                                            <IRIcon iconName={benefit?.icon ?? ''} size={20} />
                                                            {benefit?.title}
                                                        </Typography>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                            {/* <Stack direction="row">
                                                <AnimateButton>
                                                    <Button
                                                        startIcon={<EastIcon />}
                                                        sx={{ boxShadow: 'none', my: 4, backgroundColor: theme.palette.primary[800] }}
                                                        variant="contained"
                                                        component={Link}
                                                        href={section?.knowMore?.link}
                                                        target="_blank"
                                                    >
                                                        {section?.knowMore?.title}
                                                    </Button>
                                                </AnimateButton>
                                            </Stack> */}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
            </Grid>
        </Container>
    );
};
