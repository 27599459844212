import React, { useState, useCallback, ReactElement, createContext, ReactNode } from 'react';

export interface IModalContext {
    setModalComponent: (modalComponent: ReactElement) => void;
    removeModalComponent: () => void;
}

const DefaultModalComponent = <></>;

export const ModalContext = createContext({} as IModalContext);

interface IModalProviderProps {
    children: ReactNode;
}
export const ModalProvider: React.FC<IModalProviderProps> = ({ children }) => {
    const [modalComponent, setModalComponent] = useState<ReactElement>(DefaultModalComponent);

    const contextValue: IModalContext = {
        setModalComponent: useCallback((component: ReactElement) => {
            setModalComponent(component);
        }, []),
        removeModalComponent: useCallback(() => setModalComponent(DefaultModalComponent), [])
    };

    return (
        <ModalContext.Provider value={contextValue}>
            {children}
            {modalComponent}
        </ModalContext.Provider>
    );
};
