import React, { useEffect, useRef, useState } from 'react';
import { useGetAvailableLanguages } from '../hooks/useGetAvailableLanguages';
import useConfig from 'hooks/useConfig';
const defaultLanguage = 'en';
const Footer = () => {
    const [open, setOpen] = useState(false);
    const { locale, onChangeLocale } = useConfig();
    const anchorRef = useRef<any>(null);
    const [selectedLanguage, setSelectedLanguage] = useState<string>(defaultLanguage);
    const availableLanguages = useGetAvailableLanguages();
    const handleListItemClick = (
        event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement, MouseEvent> | undefined,
        lng: string
    ) => {
        setSelectedLanguage(lng);
        onChangeLocale(lng);
        setOpen(false);
    };
    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    useEffect(() => {
        setSelectedLanguage(locale);
    }, [locale]);

    const languagesToShow = availableLanguages.slice(0, 6);
    return (
        <footer>
            {languagesToShow.map((lang: any, index: number) => {
                const isSelected = lang.locale === selectedLanguage;
                const linkStyle = {
                    color: isSelected ? 'black' : 'gray', // Change color based on selection
                    textDecoration: 'none',
                    marginRight: index !== languagesToShow.length - 1 ? '10px' : 0
                };
                return (
                    <React.Fragment key={lang.locale}>
                        <a
                            href="#"
                            style={linkStyle}
                            onClick={(event) => {
                                event.preventDefault();
                                handleListItemClick(event, lang.locale);
                            }}
                        >
                            {lang.language}
                        </a>
                        {index !== languagesToShow.length - 1 && <span>&nbsp;|&nbsp;</span>}
                    </React.Fragment>
                );
            })}
        </footer>
    );
};

export default Footer;
