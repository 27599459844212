import React from 'react';
// routing
import Routes from 'routes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
// import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';

// react/jsx-no-undef
// auth provider
// import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';
import { ModalProvider } from 'contexts/ModalContext';

import { ApolloProvider } from '@apollo/client';
// import { setContext } from '@apollo/client/link/context';
// import useConfig from 'hooks/useConfig';
import { graphqlClient } from 'graphql/GraphqlClient';
import Trace from 'trace/Trace';

const App: React.FC = () => {
    Trace.init();
    return (
        <ApolloProvider client={graphqlClient.getClient()}>
            <ThemeCustomization>
                {/* RTL layout */}
                {/* <RTLLayout> */}
                <Locales>
                    <NavigationScroll>
                        <AuthProvider>
                            <ModalProvider>
                                <>
                                    <Routes />
                                    <Snackbar />
                                </>
                            </ModalProvider>
                        </AuthProvider>
                    </NavigationScroll>
                </Locales>
                {/* </RTLLayout> */}
            </ThemeCustomization>
        </ApolloProvider>
    );
};

export default App;
