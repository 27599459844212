import { FunctionComponent } from 'react';
import styles from './RectangleComponent.module.css';
import VerticalMoreIcon from '../assets/Vertical More.svg';
import CodeIcon from '../assets/code.svg';
const RectangleComponent: FunctionComponent = () => {
    return (
        <div className={styles.rectangleParent}>
            <div className={styles.groupChild} />
            <div className={styles.codingAssessmentParent}>
                <div className={styles.codingAssessment}>Coding Assessment</div>
                <div className={styles.publishedAt231}>Published at: 23 Oct 2022</div>
                <img className={styles.messageIcon1} alt="" src={CodeIcon} />
                {/* <img className={styles.groupItem} alt="" src="Group 131.png" /> */}
                <img className={styles.verticalMoreIcon1} alt="" src={VerticalMoreIcon} />
                <div className={styles.tagsSoftwareEngineering1}>Tags: Software engineering, React, UI Development</div>
                <div className={styles.groupParent}>
                    <div className={styles.questionsParent}>
                        <b className={styles.questions}>Questions</b>
                        <div className={styles.div}>23</div>
                    </div>
                    <div className={styles.responsesParent}>
                        <b className={styles.questions}>Responses</b>
                        <div className={styles.div1}>6543</div>
                    </div>
                </div>
                <div className={styles.rectangleGroup}>
                    <div className={styles.groupInner} />
                    <div className={styles.published}>Published</div>
                </div>
            </div>
        </div>
    );
};

export default RectangleComponent;
