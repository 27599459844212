import { Typography, Stack, Button, MenuItem, Box, IconButton } from '@mui/material';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import PersonIcon from '@mui/icons-material/Person';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import React, { useMemo } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { getMiniMenuSectionStyles } from './MiniMenuSection.styles';
import { useNavigation } from 'hooks/useNavigation';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';

export const MiniMenuSection: React.FC = () => {
    const { t } = useTranslation();
    const styles = getMiniMenuSectionStyles();
    const { navigateTo } = useNavigation();

    const actions = useMemo(
        () => [
            {
                name: 'jobs',
                displayName: t('Find Work'),
                Icon: BusinessCenterIcon,
                link: '/jobs'
            },
            {
                name: 'companies',
                displayName: t('Companies'),
                Icon: CorporateFareIcon,
                link: '/companies'
            },
            {
                name: 'applications',
                displayName: t('Applications'),
                Icon: WorkHistoryIcon,
                link: '/applications'
            },
            {
                name: 'profile',
                displayName: t('Profile'),
                Icon: PersonIcon,
                link: '/portfolio'
            }
        ],
        [t]
    );

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleNavClick = (path: string) => {
        navigateTo(path);
        setAnchorElNav(null);
    };

    return (
        <>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                >
                    <MenuIcon />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                        display: { xs: 'block', md: 'none' }
                    }}
                    color="primary"
                >
                    {actions.map((action) => (
                        <MenuItem key={action.name} onClick={handleCloseNavMenu}>
                            <Button sx={styles.button} onClick={() => navigateTo(action.link)}>
                                <Stack sx={styles.container}>
                                    <action.Icon color="primary" fontSize="inherit" sx={styles.icon} />
                                </Stack>
                                <Typography variant="subtitle1" color="text.primary" sx={styles.text}>
                                    {action.displayName}
                                </Typography>
                            </Button>
                        </MenuItem>
                    ))}
                </Menu>
            </Box>
            <Box sx={{ flexGrow: 9, display: { xs: 'none', md: 'flex' } }}>
                {actions.map((action) => (
                    <Button
                        key={action.name}
                        onClick={() => handleNavClick(action.link)}
                        sx={{ my: 2, color: 'white', display: { lg: 'flex' } }}
                    >
                        <Stack sx={styles.container}>
                            <action.Icon color="primary" fontSize="inherit" sx={styles.icon} />
                        </Stack>
                        <Typography variant="subtitle1" color="text.primary" sx={styles.text}>
                            {action.displayName}
                        </Typography>
                    </Button>
                ))}
            </Box>
        </>
    );
};
