import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { useTheme } from '@mui/material';
import useConfig from 'hooks/useConfig';
import { useProfileStyles } from './Profile.styles';
import { BasicInformationCard } from './BasicInformationCard';
import { ProfessionalInformationCard } from './ProfessionalInformationCard';
import { ProfileCard } from './ProfileCard';
import { CareerPreferences } from './CareerPreferences';
import { PersonalDetailsCard } from './PersonalDetailsCard';

export const Profile: React.FC = () => {
    const theme = useTheme();
    const { borderRadius } = useConfig();
    const styles = useProfileStyles(theme, borderRadius);

    const [isLoading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(false);
    }, []);

    return (
        <Grid container spacing={gridSpacing} sx={styles.rootContainer}>
            <Grid item xs={12}>
                <Typography variant="h3">User profile</Typography>
            </Grid>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={3} sx={styles.profileSection}>
                    <ProfileCard loading={isLoading} />
                </Grid>
                <Grid item xs={5}>
                    <Grid container spacing={gridSpacing} sx={styles.profileDetailsSection}>
                        <Grid xs={12}>
                            <BasicInformationCard />
                        </Grid>
                        <Grid xs={12} sx={styles.basicDetailsContainer}>
                            <ProfessionalInformationCard />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Grid container sx={styles.profileExtraDetailsContainer} spacing={3}>
                        <Grid item xs={12}>
                            <CareerPreferences />
                        </Grid>
                        <Grid item xs={12}>
                            <PersonalDetailsCard />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
