// third-party
import { combineReducers } from 'redux';

// project imports
import snackbarReducer from './slices/snackbar';
import menuReducer from './slices/menu';
import autoPilotInterview from './slices/AutopilotInterview';
import calendarReducer from './slices/calendar';
import jobsReducer from './Jobs/jobsReducer';
import kanbanReducer from './kanban';
import jobTemplateReducer from './Jobs/JobTemplateReducer';
import contactUsReducer from './ContactUs/contactUsReducer';
import surveyReducer from './Survey/surveyReducer';

const reducer = combineReducers({
    snackbar: snackbarReducer,
    menu: menuReducer,
    jobs: jobsReducer,
    chat: autoPilotInterview,
    autoPilotInterview: autoPilotInterview,
    kanban: kanbanReducer,
    calendar: calendarReducer,
    jobTemplate: jobTemplateReducer,
    contactUs: contactUsReducer,
    survey: surveyReducer
});

export default reducer;
