// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from 'store';

// types
import { DefaultRootStateProps } from 'types';
import { ChatHistory } from 'types/chat';

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['autoPilotInterview'] = {
    error: null,
    loading: false,
    chats: [],
    user: {},
    users: [],
    job: {}
};

const slice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET USER
        getUserSuccess(state, action) {
            state.user = { ...action.payload?.candidate, id: action.payload?.id };
            state.job = action.payload?.job;
            state.chats = action.payload?.messages ?? [];
        },

        // GET USER CHATS
        getUserChatsSuccess(state, action) {
            let chats = [...state.chats];
            let message: ChatHistory = {
                to: state.user.id,
                from: 'assistant',
                role: 'assistant',
                content: action?.payload.replace(/\n/g, '<br />')
            };
            chats.push(message);
            state.chats = [...chats];
        },

        addMessage(state, action) {
            let chats = [...state.chats];
            let message: ChatHistory = action.payload;
            chats.push(message);
            state.chats = [...chats];
        },

        startLoading(state) {
            state.loading = true;
        },
        stopLoading(state) {
            state.loading = false;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getInterviewDetails(key: string) {
    return async () => {
        try {
            dispatch(slice.actions.startLoading());
            const response = await axios.get(`/autopilot/interview/${key}`);
            dispatch(slice.actions.getUserSuccess(response.data));
            dispatch(slice.actions.stopLoading());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.stopLoading());
        }
    };
}

export function getUserChats(user: string | undefined) {
    return async () => {
        try {
            // const response = await axios.post('/api/chat/filter', { user });
            // dispatch(slice.actions.getUserChatsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.stopLoading());
        }
    };
}

export function insertChat(key: string, message?: ChatHistory) {
    return async () => {
        try {
            message && dispatch(slice.actions.addMessage(message));
            dispatch(slice.actions.startLoading());
            const response = await axios.post('/autopilot/interview', { key, message: message?.content ?? '' });
            dispatch(slice.actions.getUserChatsSuccess(response.data));
            dispatch(slice.actions.stopLoading());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.stopLoading());
        }
    };
}
