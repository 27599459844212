import { NavigateOptions, useNavigate } from 'react-router-dom';

export const useNavigation = () => {
    const navigate = useNavigate();

    // Function to navigate to a specified URL
    const navigateTo = (url: string, options?: NavigateOptions) => {
        navigate(url, options);
    };

    return { navigateTo };
};
