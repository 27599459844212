import React from 'react';
import User1 from 'assets/images/users/img-user.png';
import Cover from 'assets/images/profile/img-profile-bg.png';
import { CardMedia, Grid, Avatar, Typography, IconButton, useTheme } from '@mui/material';
import useConfig from 'hooks/useConfig';
import { gridSpacing } from 'store/constant';
import { AddCard } from 'ui-component/cards/AddCard/AddCard';
import MainCard from 'ui-component/cards/MainCard';
import ImagePlaceholder from 'ui-component/cards/Skeleton/ImagePlaceholder';
import Chip from 'ui-component/extended/Chip';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

import { useProfileStyles } from './Profile.styles';
import { IProfileCardProps } from './Profile.types';
import useAuth from 'hooks/useAuth';
import { useModal } from 'hooks/useModal';
import { SkillModal } from './SkillModal';

export const ProfileCard: React.FC<IProfileCardProps> = ({ loading }) => {
    const theme = useTheme();
    const { borderRadius } = useConfig();
    const styles = useProfileStyles(theme, borderRadius);
    const { user } = useAuth();

    const { open: openSkillModal } = useModal(<SkillModal />);

    return (
        <MainCard contentSX={styles.headerContainer}>
            {loading ? (
                <ImagePlaceholder sx={styles.loadingImagePlaceholder} />
            ) : (
                <CardMedia component="img" image={Cover} sx={styles.backgroundImage} alt="profile-background" />
            )}
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} md={3}>
                    {loading ? <ImagePlaceholder sx={styles.imagePlaceholder} /> : <Avatar alt="User 1" src={User1} sx={styles.avatar} />}
                </Grid>
                <Grid item xs={12} md={9} sx={styles.headerTextContainer}>
                    <Grid container>
                        <Grid item xs={9}>
                            <Typography variant="h3">{user?.name}</Typography>
                            <Typography variant="subtitle2">Job Role</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <IconButton color="primary" aria-label="Edit Current Industry">
                                <ModeEditIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container sx={styles.aboutContainer}>
                <Grid item>
                    <Grid container>
                        <Grid item xs={11}>
                            <Typography variant="h4">About</Typography>
                        </Grid>
                    </Grid>
                    <Typography variant="body1">
                        To associate myself with a technically driven esteemed organisation by pursuing a challenging, dynamic,
                        responsible,applying my technical skills and enthusiasm in a position for career growth in my technical field of
                        interests.
                    </Typography>
                </Grid>
            </Grid>
            <Grid container sx={styles.skillsRootContainer}>
                <Grid item>
                    <Grid container>
                        <Grid item xs={10}>
                            <Typography variant="h4">Skill Tags</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton color="primary" aria-label="Edit Skills" onClick={openSkillModal}>
                                <ModeEditIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                    <Typography variant="h5" sx={styles.featuredSkillsText}>
                        Featured Tags
                    </Typography>
                    <Grid container sx={styles.skillsContainer} spacing={1}>
                        <Grid item>
                            <Chip label="Leadership and team management" chipcolor="primary" />
                        </Grid>
                        <Grid item>
                            <Chip label="Strategic planning and execution" chipcolor="primary" />
                        </Grid>
                        <Grid item>
                            <Chip label="Financial acumen and budget management" chipcolor="primary" />
                        </Grid>
                        <Grid item>
                            <Chip label="Effective communication and presentation" chipcolor="primary" />
                        </Grid>
                        <Grid item>
                            <Chip label="Problem-solving and decision-making" chipcolor="primary" />
                        </Grid>
                        <Grid item>
                            <Chip label="Change management and adaptability" chipcolor="primary" />
                        </Grid>
                    </Grid>

                    <Typography variant="h5" sx={styles.featuredSkillsText}>
                        Skills
                    </Typography>
                    <Grid container sx={styles.skillsContainer} spacing={1}>
                        <Grid item>
                            <Chip label="Relationship building and stakeholder management" chipcolor="secondary" />
                        </Grid>
                        <Grid item>
                            <Chip label="Analytical and critical thinking" chipcolor="secondary" />
                        </Grid>
                        <Grid item>
                            <Chip label="Project management" chipcolor="secondary" />
                        </Grid>
                        <Grid item>
                            <Chip label="Risk assessment and mitigation" chipcolor="secondary" />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container sx={styles.resumeContainer}>
                <Grid item xs={12}>
                    <Typography variant="h4">Resume</Typography>
                </Grid>
                <Grid item sx={styles.resumeChipGrid} xs={12}>
                    <AddCard primaryText="Add/ Update resume" styles={{}} />
                    <Grid container>
                        <Grid item xs={9}>
                            <Chip label="resume_sukantjain_updated" chipcolor="primary" onDelete={() => {}} clickable />
                        </Grid>

                        <Grid item xs={3}>
                            <Typography color={'text.secondary'}>3 months ago</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </MainCard>
    );
};
