import {
    MonetizationOn,
    Work,
    Campaign,
    Pets,
    Palette,
    Business,
    CleaningServices,
    Computer,
    Build,
    LocalHospital,
    People,
    Gavel,
    BusinessCenter,
    BuildCircle,
    Public,
    Waves,
    Face,
    Security,
    Home,
    Restaurant,
    LocalMall,
    Science,
    PeopleAlt,
    FitnessCenter,
    LocalShipping,
    LocalGroceryStore,
    School,
    AirplanemodeActive,
    Nature
} from '@mui/icons-material';
import { useTranslation } from 'hooks/useTranslation';

export const useGetJobCategories = () => {
    const { t } = useTranslation();
    return [
        {
            id: 'ACCOUNTING_AND_FINANCE',
            uri: 'accounting-and-finance',
            title: t('Accounting and Finance'),
            description: t(
                'Accountant, Financial Analyst, Finance Manager, Tax Consultant, Financial Controller, Auditor, Investment Banker'
            ),
            icon: <MonetizationOn />,
            color1: '#ff6b6b',
            color2: '#ff5e5e'
        },
        {
            id: 'ADMINISTRATIVE_AND_OFFICE',
            uri: 'administrative-and-office',
            title: t('Administrative and Office'),
            description: t(
                'Administrative Assistant, Office Manager, Executive Assistant, Data Entry Clerk, Administrative Coordinator, Receptionist, Office Administrator'
            ),
            icon: <Work />,
            color1: '#74b9ff',
            color2: '#54a0ff'
        },
        {
            id: 'ADVERTISING_AND_MARKETING',
            uri: 'advertising-and-marketing',
            title: t('Advertising and Marketing'),
            description: t(
                'Marketing Manager, Advertising Copywriter, Digital Marketing Specialist, Social Media Manager, Content Marketing Strategist, Brand Manager, SEO Specialist'
            ),
            icon: <Campaign />,
            color1: '#fdcb6e',
            color2: '#e1b12c'
        },
        {
            id: 'ANIMAL_CARE',
            uri: 'animal-care',
            title: t('Animal Care'),
            description: t(
                'Veterinarian, Animal Shelter Worker, Veterinary Technician, Animal Trainer, Pet Groomer, Animal Caretaker, Animal Control Officer'
            ),
            icon: <Pets />,
            color1: '#55efc4',
            color2: '#00b894'
        },
        {
            id: 'ART_FASHION_AND_DESIGN',
            uri: 'art-fashion-and-design',
            title: t('Art, Fashion, and Design'),
            description: t(
                'Designer, Fashion Stylist, Graphic Designer, Fashion Designer, Interior Designer, Web Designer, UX/UI Designer'
            ),
            icon: <Palette />,
            color1: '#a29bfe',
            color2: '#6c5ce7'
        },
        {
            id: 'BUSINESS_OPERATIONS',
            uri: 'business-operations',
            title: t('Business Operations'),
            description: t(
                'Business Operations Manager, Operations Analyst, Project Manager, Operations Manager, Business Development Manager, Supply Chain Analyst'
            ),
            icon: <Business />,
            color1: '#fab1a0',
            color2: '#ff7675'
        },
        {
            id: 'CLEANING_AND_FACILITIES',
            uri: 'cleaning-and-facilities',
            title: t('Cleaning and Facilities'),
            description: t(
                'Custodial Staff, Facilities Coordinator, Janitor, Housekeeping Supervisor, Building Maintenance Worker, Groundskeeper'
            ),
            icon: <CleaningServices />,
            color1: '#fd79a8',
            color2: '#e84393'
        },
        {
            id: 'COMPUTER_AND_IT',
            uri: 'computer-and-it',
            title: t('Computer and IT'),
            description: t(
                'Systems Administrator, Software Developer, Web Developer, IT Support Specialist, Data Analyst, Network Engineer, Cybersecurity Analyst'
            ),
            icon: <Computer />,
            color1: '#a29bfe',
            color2: '#6c5ce7'
        },
        {
            id: 'CONSTRUCTION',
            uri: 'construction',
            title: t('Construction'),
            description: t(
                'General Laborer, Construction Project Manager, Construction Worker, Carpenter, Electrician, Plumber, Civil Engineer'
            ),
            icon: <Build />,
            color1: '#fab1a0',
            color2: '#ff7675'
        },
        {
            id: 'CUSTOMER_SERVICE',
            uri: 'customer-service',
            title: t('Customer Service'),
            description: t(
                'Cashier, Customer Support Specialist, Customer Service Representative, Call Center Agent, Sales Support Associate, Help Desk Technician'
            ),
            icon: <LocalGroceryStore />,
            color1: '#a29bfe',
            color2: '#6c5ce7'
        },
        {
            id: 'EDUCATION',
            uri: 'education',
            title: t('Education'),
            description: t(
                'School Teacher, Education Coordinator, Teaching Assistant, Principal, Education Consultant, Curriculum Developer'
            ),
            icon: <School />,
            color1: '#fdcb6e',
            color2: '#e1b12c'
        },
        {
            id: 'ENTERTAINMENT_AND_TRAVEL',
            uri: 'entertainment_and_travel',
            title: t('Entertainment and Travel'),
            description: t(
                'Flight Attendant, Entertainment Coordinator, Event Planner, Travel Agent, Tour Guide, Cruise Ship Staff, Ticket Sales Agent'
            ),
            icon: <AirplanemodeActive />,
            color1: '#55efc4',
            color2: '#00b894'
        },
        {
            id: 'FARMING_AND_OUTDOORS',
            uri: 'farming_and_outdoors',
            title: t('Farming and Outdoors'),
            description: t('Park Ranger, Farm Worker, Agricultural Technician, Gardener, Forester, Landscaper, Wildlife Biologist'),
            icon: <Nature />,
            color1: '#fd79a8',
            color2: '#e84393'
        },
        {
            id: 'HEALTHCARE',
            uri: 'healthcare',
            title: t('Healthcare'),
            description: t(
                'Registered Nurse, Medical Laboratory Technologist, Nurse Practitioner, Physician, Medical Assistant, Physical Therapist, Radiologic Technologist'
            ),
            icon: <LocalHospital />,
            color1: '#74b9ff',
            color2: '#54a0ff'
        },
        {
            id: 'HUMAN_RESOURCES',
            uri: 'human-resources',
            title: t('Human Resources'),
            description: t(
                'Human Resources Director, HR Specialist, HR Manager, Recruitment Specialist, Training and Development Manager, HR Generalist, Compensation Analyst'
            ),
            icon: <People />,
            color1: '#fdcb6e',
            color2: '#e1b12c'
        },
        {
            id: 'INSTALLATION_MAINTENANCE_AND_REPAIR',
            uri: 'installation-maintenance-and-repair',
            title: t('Installation, Maintenance, and Repair'),
            description: t(
                'Electrician, Maintenance Technician, HVAC Technician, Appliance Repair Technician, Auto Mechanic, Plumber, Industrial Machinery Mechanic'
            ),
            icon: <BuildCircle />,
            color1: '#a29bfe',
            color2: '#6c5ce7'
        },
        {
            id: 'LEGAL',
            uri: 'legal',
            title: t('Legal'),
            description: t('Law Clerk, Paralegal, Attorney, Legal Assistant, Legal Secretary, Legal Consultant, Court Reporter'),
            icon: <Gavel />,
            color1: '#fab1a0',
            color2: '#ff7675'
        },
        {
            id: 'MANAGEMENT',
            uri: 'management',
            title: t('Management'),
            description: t(
                'Store Manager, Project Manager, Operations Manager, Business Development Manager, General Manager, Team Leader, Director'
            ),
            icon: <BusinessCenter />,
            color1: '#74b9ff',
            color2: '#54a0ff'
        },
        {
            id: 'MANUFACTURING_AND_WAREHOUSE',
            uri: 'manufacturing-and-warehouse',
            title: t('Manufacturing and Warehouse'),
            description: t(
                'Assembly Technician, Warehouse Supervisor, Production Worker, Quality Control Inspector, Machine Operator, Logistics Coordinator, Materials Handler'
            ),
            icon: <BuildCircle />,
            color1: '#a29bfe',
            color2: '#6c5ce7'
        },
        {
            id: 'MEDIA_COMMUNICATIONS_AND_WRITING',
            uri: 'media-communications-and-writing',
            title: t('Media, Communications, and Writing'),
            description: t(
                'Media Relations, Content Writer, Public Relations Specialist, Social Media Coordinator, Journalist, Copywriter, Editor'
            ),
            icon: <Public />,
            color1: '#55efc4',
            color2: '#00b894'
        },
        {
            id: 'OIL_GAS_AND_MINING',
            uri: 'oil-gas-and-mining',
            title: t('Oil, Gas, and Mining'),
            description: t(
                'Offshore Driller, Mining Engineer, Petroleum Engineer, Geologist, Oil Rig Worker, Mining Technician, Oilfield Operator'
            ),
            icon: <Waves />,
            color1: '#fd79a8',
            color2: '#e84393'
        },
        {
            id: 'PERSONAL_CARE_AND_SERVICES',
            uri: 'personal-care-and-services',
            title: t('Personal Care and Services'),
            description: t(
                'Hair Stylist, Personal Trainer, Makeup Artist, Massage Therapist, Esthetician, Nail Technician, Fitness Instructor'
            ),
            icon: <Face />,
            color1: '#74b9ff',
            color2: '#54a0ff'
        },
        {
            id: 'PROTECTIVE_SERVICES',
            uri: 'protective-services',
            title: t('Protective Services'),
            description: t(
                'Security Guard, Police Officer, Firefighter, Private Investigator, Correctional Officer, Border Patrol Agent, Security Specialist'
            ),
            icon: <Security />,
            color1: '#fab1a0',
            color2: '#ff7675'
        },
        {
            id: 'REAL_ESTATE',
            uri: 'real-estate',
            title: t('Real Estate'),
            description: t(
                "Buyer's Agent, Property Manager, Real Estate Broker, Leasing Consultant, Real Estate Appraiser, Real Estate Agent, Realtor"
            ),
            icon: <Home />,
            color1: '#55efc4',
            color2: '#00b894'
        },
        {
            id: 'RESTAURANT_AND_HOSPITALITY',
            uri: 'restaurant-and-hospitality',
            title: t('Restaurant and Hospitality'),
            description: t(
                'Restaurant Server, Hotel Front Desk Clerk, Chef, Bartender, Waiter/Waitress, Event Coordinator, Hotel Housekeeper'
            ),
            icon: <Restaurant />,
            color1: '#fdcb6e',
            color2: '#e1b12c'
        },
        {
            id: 'SALES_AND_RETAIL',
            uri: 'sales-and-retail',
            title: t('Sales and Retail'),
            description: t(
                'Sales Associate, Retail Store Manager, Sales Representative, Sales Manager, Account Executive, Retail Sales Associate, Sales Consultant'
            ),
            icon: <LocalMall />,
            color1: '#fd79a8',
            color2: '#e84393'
        },
        {
            id: 'SCIENCE_AND_ENGINEERING',
            uri: 'science-and-engineering',
            title: t('Science and Engineering'),
            description: t(
                'Lab Technician, Civil Engineer, Research Scientist, Biomedical Engineer, Chemical Engineer, Environmental Scientist, Electrical Engineer'
            ),
            icon: <Science />,
            color1: '#74b9ff',
            color2: '#54a0ff'
        },
        {
            id: 'SOCIAL_SERVICES_AND_NON_PROFIT',
            uri: 'social-services-and-non-profit',
            title: t('Social Services and Non-Profit'),
            description: t(
                'Case Worker, Non-Profit Program Coordinator, Social Worker, Counselor, Community Outreach Specialist, Fundraising Coordinator, Volunteer Coordinator'
            ),
            icon: <PeopleAlt />,
            color1: '#fdcb6e',
            color2: '#e1b12c'
        },
        {
            id: 'SPORTS_FITNESS_AND_RECREATION',
            uri: 'sports-fitness-and-recreation',
            title: t('Sports, Fitness, and Recreation'),
            description: t(
                'Personal Trainer, Sports Coach, Fitness Instructor, Athlete, Recreation Coordinator, Sports Manager, Yoga Instructor'
            ),
            icon: <FitnessCenter />,
            color1: '#55efc4',
            color2: '#00b894'
        },
        {
            id: 'TRANSPORTATION_AND_LOGISTICS',
            uri: 'transportation-and-logistics',
            title: t('Transportation and Logistics'),
            description: t(
                'Truck Driver, Logistics Coordinator, Delivery Driver, Supply Chain Analyst, Transportation Manager, Warehouse Associate, Freight Broker'
            ),
            icon: <LocalShipping />,
            color1: '#74b9ff',
            color2: '#54a0ff'
        }
    ];
};
