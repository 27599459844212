import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IJobTemplateJob, ISearchJobTemplateResponse } from '@intellirecruit/graphql/src/Types.generated';
import { dispatch } from 'store';
import { httpService } from 'utils/http-service';

interface JobTemplateState {
    jobTemplatesSearchResponse: ISearchJobTemplateResponse;
    jobTemplates: Array<IJobTemplateJob>;
    jobTemplate: IJobTemplateJob;
    nextToken: string;
    loading: boolean;
    moreLoading: boolean;
    error: string | null;
}

const initialState: JobTemplateState = {
    jobTemplatesSearchResponse: {},
    jobTemplates: [],
    jobTemplate: {} as IJobTemplateJob,
    nextToken: '',
    loading: false,
    moreLoading: false,
    error: null
};

const jobTemplateSlice = createSlice({
    name: 'jobTemplates',
    initialState,
    reducers: {
        startLoading(state) {
            state.loading = true;
            state.error = null;
        },
        startMoreLoading(state) {
            state.moreLoading = true;
            state.error = null;
        },
        hasError(state, action) {
            console.log(action.payload.error);
            state.error = action.payload.error;
        },
        stopLoading(state) {
            state.loading = false;
        },
        stopMoreLoading(state) {
            state.moreLoading = false;
        },
        getJobTemplateDetailsSuccess(state, action: PayloadAction<IJobTemplateJob>) {
            state.jobTemplate = action.payload;
            state.loading = false;
            state.error = null;
        },
        appendJobTemplates(state, action: PayloadAction<ISearchJobTemplateResponse>) {
            if (action.payload.templates) {
                const templates = action.payload.templates.filter((template): template is IJobTemplateJob => template !== null);
                state.jobTemplatesSearchResponse = action.payload;
                state.nextToken = action.payload.pageInfo?.nextToken ?? '';
                state.jobTemplates = [...state.jobTemplates, ...templates];
            }
        },
        resetJobTemplates(state) {
            state.jobTemplates = [];
        }
    }
});

export const getJobTemplate = (id: string, locale: string = 'en') => {
    return async () => {
        dispatch(jobTemplateSlice.actions.startLoading());
        try {
            const response = await httpService.get(`/job-template/${id}?hl=${locale}`);
            dispatch(jobTemplateSlice.actions.getJobTemplateDetailsSuccess(response));
        } catch (error) {
            dispatch(jobTemplateSlice.actions.hasError(error));
        }
        dispatch(jobTemplateSlice.actions.stopLoading());
    };
};

export const searchJobTemplates = (searchText: string, category: string, nextToken: string, locale: string = 'en') => {
    return async () => {
        nextToken.length === 0 ? dispatch(jobTemplateSlice.actions.startLoading()) : dispatch(jobTemplateSlice.actions.startMoreLoading());
        try {
            const response = await httpService.get(
                `/job-template/search/params?searchText=${searchText}&categories=${category}&pageToken=${nextToken}&hl=${locale}`
            );
            dispatch(jobTemplateSlice.actions.getJobTemplateDetailsSuccess(response));
            dispatch(jobTemplateSlice.actions.appendJobTemplates(response));
        } catch (error) {
            dispatch(jobTemplateSlice.actions.hasError(error));
        }
        nextToken.length === 0 ? dispatch(jobTemplateSlice.actions.stopLoading()) : dispatch(jobTemplateSlice.actions.stopMoreLoading());
    };
};

export const resetJobTemplates = () => {
    return async () => {
        dispatch(jobTemplateSlice.actions.resetJobTemplates());
    };
};

export default jobTemplateSlice.reducer;
