import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Avatar, Box, Button, Tooltip, CardActions, CardHeader, IconButton, Grid, Divider } from '@mui/material';
import { grey } from '@mui/material/colors';

import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import VisibilityIcon from '@mui/icons-material/Visibility';
import GroupIcon from '@mui/icons-material/Group';
// import ShareIcon from '@mui/icons-material/Share';
import ShareCard from './ShareCard';

import { Stack } from '@mui/system';
import Chip from 'ui-component/extended/Chip';
import { AccessTimeFilled, CheckCircle } from '@mui/icons-material';

const bull = (
    <Box component="span" sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
        •
    </Box>
);
interface JobCardProps {
    color: string;
}
export const JobCard = ({ color }: JobCardProps) => {
    return (
        <Card variant="outlined" sx={{ bgcolor: `${color[50]}`, border: `1px solid ${color[100]}` }}>
            {/* <CardActionArea> */}
            <CardHeader
                avatar={
                    <Avatar
                        sx={{ bgcolor: grey[100], color: 'white', width: 50, height: 50 }}
                        aria-label="Company Logo"
                        src="icon.png"
                    ></Avatar>
                }
                action={
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Tooltip title="Save for later" placement="bottom">
                            <IconButton aria-label="save for later">
                                <BookmarkBorderIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Share in your network" placement="bottom">
                            <ShareCard />
                        </Tooltip>
                    </Box>
                }
                title={
                    <Grid container direction="row-reverse">
                        <Grid item xs={9}>
                            <Grid container alignItems="center">
                                <Grid item xs={3}>
                                    <Divider sx={{ height: 32, color: 'black', marginRight: 150, marginTop: 1 }} orientation="vertical" />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1" fontSize={'medium'}>
                                        <AccessTimeFilled
                                            fontSize="inherit"
                                            sx={{ mr: 0.5, fontSize: '0.875rem', verticalAlign: 'text-top' }}
                                        />
                                        Posted 2 Days ago
                                    </Typography>{' '}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="h3">
                                Senior Software Engineer <CheckCircle sx={{ color: 'success.dark', width: 20, height: 20, marginTop: 2 }} />
                            </Typography>
                        </Grid>
                    </Grid>
                }
                subheader={<Typography variant="body1">Intellirecruit</Typography>}
                sx={{ padding: '10px 30px 0px 10px' }}
            />
            <CardContent sx={{ padding: '15px 20px' }}>
                <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
                    We are seeking a highly skilled Senior Software Engineer to design and implement functional software solutions.
                    Collaborating with upper management, you will play a key role in defining software requirements ...
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" sx={{ paddingBottom: 2, fontWeight: 'bold', fontSize: 13 }}>
                    Technical Design {bull} .NET Programming {bull} System Design {bull} Process {bull} Good Communication skills
                </Typography>

                <Stack sx={{ padding: '5px 0px 1px 0px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={9}>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <Chip icon={<AccessTimeIcon color="secondary" />} label="Full Time" chipcolor="secondary" clickable />
                                </Grid>
                                <Grid item>
                                    <Chip
                                        icon={<BusinessCenterIcon color="secondary" />}
                                        label="7 - 9 Years"
                                        chipcolor="secondary"
                                        clickable
                                    />
                                </Grid>
                                <Grid item>
                                    <Chip
                                        icon={<LocationOnIcon color="secondary" />}
                                        label="Hyderabad, Telangana"
                                        chipcolor="secondary"
                                        clickable
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Grid container spacing={2} justifyContent={'flex-end'} sx={{ paddingRight: 2 }}>
                                <Grid item>
                                    <Typography
                                        variant="subtitle2"
                                        color="text.secondary"
                                        sx={{
                                            mt: 0.5,
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            display: 'block',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        <VisibilityIcon
                                            fontSize="inherit"
                                            sx={{ mr: 0.5, fontSize: '0.875rem', verticalAlign: 'text-top' }}
                                        />
                                        290 views
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="subtitle2"
                                        color="text.secondary"
                                        sx={{
                                            mt: 0.5,
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            display: 'block',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        <GroupIcon fontSize="inherit" sx={{ mr: 0.5, fontSize: '0.875rem', verticalAlign: 'text-top' }} />
                                        43 applicants
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Stack>
            </CardContent>
            {/* </CardActionArea> */}
            <CardActions sx={{ padding: 1, borderTop: `1px solid ${color[100]}`, paddingLeft: 2 }}>
                <Typography variant="caption" sx={{ marginRight: 'auto', paddingRight: 3 }}>
                    Posted on: 2 days ago
                </Typography>
                <Grid sx={{ paddingRight: 3 }}>
                    <Button size="small" sx={{ color: color[800] }}>
                        Contact Recruiter
                    </Button>
                    <Button size="small" sx={{ color: color[800] }}>
                        Job Apply
                    </Button>
                </Grid>
            </CardActions>
        </Card>
    );
};
