import { FunctionComponent } from 'react';
import styles from './AddNewAssessment.module.css';
import { useNavigate } from 'react-router-dom';
import AddCircleIcon from '../assets/add_circle.svg';
const AddNewAssessment: FunctionComponent = () => {
    const history = useNavigate(); // Initialize the useHistory hook

    const handleCreateAssessment = () => {
        history('/assessment/description'); // Navigate to the "/create" route
    };
    return (
        <div className={styles.rectangleParent} onClick={handleCreateAssessment}>
            <div className={styles.groupChild} />
            <img className={styles.addCircleIcon} alt="" src={AddCircleIcon} />
            <div className={styles.createNewAssessment1}>Create New Assessment</div>
        </div>
    );
};

export default AddNewAssessment;
