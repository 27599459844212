import { IAddCardStyles } from './AddCard.types';

export const useAddCardStyles = (): IAddCardStyles => {
    return {
        rootContainer: {
            p: 0,
            m: 2,
            ml: 0
        },
        gridItemRootContainer: {
            p: '0px !important'
        }
    };
};
