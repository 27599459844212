// assets
import {
    IconBrandChrome,
    IconCalendarEvent,
    IconLayoutDashboard,
    IconBriefcase,
    IconFileStack,
    IconFile3d,
    IconListDetails,
    IconUsers
} from '@tabler/icons';
import { FormattedMessage } from 'react-intl';
import { NavItemType } from 'types';

// constant
const icons = {
    IconLayoutDashboard,
    IconCalendarEvent,
    IconBriefcase,
    IconFileStack,
    IconFile3d,
    IconListDetails,
    IconUsers
};

const other: NavItemType = {
    id: 'admin-menu',
    icon: IconBrandChrome,
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="Dashboard" />,
            type: 'item',
            url: '/dashboard',
            icon: icons.IconLayoutDashboard,
            breadcrumbs: false
        },
        {
            id: 'projects',
            title: <FormattedMessage id="Projects" />,
            type: 'item',
            url: '/projects',
            icon: icons.IconFileStack,
            breadcrumbs: true
        },
        {
            id: 'job-listings',
            title: <FormattedMessage id="Job Listings" />,
            type: 'item',
            url: '/jobs',
            icon: icons.IconBriefcase,
            breadcrumbs: false
        },
        {
            id: 'talent-pool',
            title: <FormattedMessage id="Talent Pool" />,
            type: 'item',
            url: '/talent-pool',
            icon: icons.IconUsers,
            breadcrumbs: false
        },
        {
            id: 'calendar',
            title: <FormattedMessage id="Calendar" />,
            type: 'item',
            url: '/calendar',
            icon: icons.IconCalendarEvent,
            breadcrumbs: true
        },
        {
            id: 'job-templates',
            title: <FormattedMessage id="Job Templates" />,
            type: 'item',
            url: '/job-templates',
            icon: icons.IconFile3d,
            breadcrumbs: true
        },
        {
            id: 'tasks',
            title: <FormattedMessage id="Tasks" />,
            type: 'item',
            url: '/tasks',
            icon: icons.IconListDetails,
            breadcrumbs: false
        }
    ]
};

export default other;
