import ReportIcon from '@mui/icons-material/Report';
import React from 'react';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import {
    FingerprintOutlined,
    Work,
    Verified,
    Group,
    Book,
    Visibility,
    Message,
    TrendingUp,
    Power,
    Explore,
    Star,
    Insights,
    TaskAlt,
    Description,
    Lock,
    Forum,
    WorkHistory,
    School,
    Leaderboard,
    SyncAlt,
    BusinessCenter,
    HowToReg,
    Directions,
    WorkOutline,
    GroupAdd,
    FolderSpecial,
    Public,
    PeopleAlt,
    MonetizationOn,
    SwapHoriz,
    Timeline,
    Business,
    Create,
    Search,
    VerifiedUser,
    FormatListNumbered,
    Translate,
    Palette,
    TouchApp,
    Lightbulb,
    Smartphone,
    AssignmentTurnedIn,
    Sync,
    Campaign,
    AccessTime,
    Storage,
    CompareArrows,
    Email,
    Security,
    Chat,
    BarChart,
    Layers,
    Build,
    People,
    EmojiEmotions,
    NetworkCheck,
    ChatBubble,
    Feedback,
    Memory
} from '@mui/icons-material';

interface IRIconProps {
    iconName: string;
    size?: number;
}

type IconMapping = {
    [key: string]: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
};

const iconMappings: IconMapping = {
    fingerprint: FingerprintOutlined,
    work: Work,
    verified: Verified,
    group: Group,
    star: Star,
    book: Book,
    visibility: Visibility,
    message: Message,
    trending_up: TrendingUp,
    power: Power,
    explore: Explore,
    insights: Insights,
    task_alt: TaskAlt,
    description: Description,
    interview: Forum,
    lock: Lock,
    question_answer: WorkHistory,
    school: School,
    leaderBoard: Leaderboard,
    sync_alt: SyncAlt,
    business_center: BusinessCenter,
    how_to_reg: HowToReg,
    directions: Directions,
    work_outline: WorkOutline,
    group_add: GroupAdd,
    folder_special: FolderSpecial,
    public: Public,
    people_alt: PeopleAlt,
    monetization_on: MonetizationOn,
    swap_horiz: SwapHoriz,
    timeline: Timeline,
    business: Business,
    create: Create,
    search: Search,
    verified_user: VerifiedUser,
    format_list_numbered: FormatListNumbered,
    translate: Translate,
    palette: Palette,
    touch_app: TouchApp,
    light_bulb: Lightbulb,
    smart_phone: Smartphone,
    assignment_turned_in: AssignmentTurnedIn,
    sync: Sync,
    campaign: Campaign,
    access_time: AccessTime,
    storage: Storage,
    compare_arrows: CompareArrows,
    email: Email,
    security: Security,
    chat: Chat,
    bar_chart: BarChart,
    layers: Layers,
    build: Build,
    people: People,
    emoji_emotions: EmojiEmotions,
    network_check: NetworkCheck,
    chat_bubble: ChatBubble,
    feedback: Feedback,
    memory: Memory
};
export const IRIcon: React.FC<IRIconProps> = ({ iconName }) => {
    const IconComponent = iconMappings[iconName];

    if (IconComponent) {
        return IconComponent && <IconComponent />;
    } else {
        // Return a placeholder or default icon if the requested icon is not found
        return <ReportIcon />;
    }
};
