import { gridSpacing } from 'store/constant';
import { IProfileStyles } from './Profile.types';
import { Theme } from '@mui/system';
import { grey } from '@mui/material/colors';

export const useProfileStyles = (theme: Theme, borderRadius: number): IProfileStyles => {
    return {
        headerContainer: {
            p: 0,
            mb: 3,
            paddingBottom: '0px !important',
            [theme.breakpoints.down('lg')]: {
                textAlign: 'center'
            }
        },
        headerTextContainer: {
            margin: '-60px 0 0 120px'
        },
        loadingImagePlaceholder: {
            borderRadius: `${borderRadius}px`,
            overflow: 'hidden',
            mb: 3,
            height: { xs: 85, sm: 150, md: 150 }
        },
        imagePlaceholder: {
            margin: '-70px 0 0 20px',
            borderRadius: '16px',
            [theme.breakpoints.down('lg')]: {
                margin: '-70px auto 0'
            },
            [theme.breakpoints.down('md')]: {
                margin: '-60px auto 0'
            },
            width: { xs: 72, sm: 70, md: 90 },
            height: { xs: 72, sm: 70, md: 90 }
        },
        avatar: {
            margin: '-70px 0 0 20px',
            borderRadius: '16px',
            [theme.breakpoints.down('lg')]: {
                margin: '-70px auto 0'
            },
            [theme.breakpoints.down('md')]: {
                margin: '-60px auto 0'
            },
            width: { xs: 72, sm: 100, md: 90 },
            height: { xs: 72, sm: 100, md: 90 }
        },
        profileSection: {
            mt: gridSpacing,
            ml: 2
        },
        profileDetailsSection: {
            mt: gridSpacing,
            ml: 1
        },
        rootContainer: {
            pl: 3,
            background: grey[100]
        },
        tabs: {
            // marginTop: 2.5,
            '& .MuiTabs-flexContainer': {
                border: 'none'
            },
            '& a': {
                minHeight: 'auto',
                minWidth: 10,
                py: 1.5,
                px: 1,
                mr: 2.25,
                color: 'grey.700',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
            },
            '& a.Mui-selected': {
                color: 'primary.main'
            },
            '& a > svg': {
                marginBottom: '4px !important',
                mr: 1.25
            }
        },
        backgroundImage: {
            borderRadius: `${borderRadius}px`,
            overflow: 'hidden',
            mb: 3
        },
        basicInformationContainer: {
            pt: 3
        },
        basicInformationGrid: {
            p: 2
        },
        basicDetailsContainer: {
            pt: 3
        },
        basicDetailsGrid: {},
        experienceContainer: {
            pt: 3
        },
        aboutContainer: {
            p: 3
        },
        skillsRootContainer: {
            p: 3
        },
        skillsContainer: {
            pt: 1
        },
        featuredSkillsText: {
            pt: 2
        },
        profileExtraDetailsContainer: { mt: 0, ml: 2 },
        resumeContainer: {
            p: 3
        },
        resumeChipGrid: {
            pt: 2
        }
    };
};
