import React, { useState } from 'react';

// material-ui
import {
    Button,
    CardContent,
    Checkbox,
    FormControlLabel,
    Grid,
    Theme,
    Typography,
    useMediaQuery,
    Box,
    Slider,
    InputAdornment,
    TextField,
    useTheme
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Accordion from 'ui-component/extended/Accordion';
import SearchIcon from '@mui/icons-material/Search';

import { gridSpacing } from 'store/constant';

const valuetext = (value: number) => {
    return `$ ${value}`;
};

export const SalaryRangeSlider: React.FC = () => {
    const [value, setValue] = useState<number[]>([40]);

    const handleChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue as number[]);
    };
    return (
        <Box>
            <Grid item xs={12} container spacing={2} alignItems="center" sx={{ mt: 2.5 }}>
                <Grid item>
                    <Typography variant="body1">15K</Typography>
                </Grid>
                <Grid item xs>
                    <Slider
                        defaultValue={40}
                        getAriaLabel={() => 'Salary Range'}
                        value={value}
                        color="primary"
                        onChange={handleChange}
                        getAriaValueText={valuetext}
                        valueLabelDisplay="auto"
                        min={15}
                        max={60}
                    />
                </Grid>
                <Grid item>
                    <Typography variant="body1">60K</Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export const JobType: React.FC = () => {
    const theme = useTheme();
    return (
        <>
            <Grid item xs={6}>
                <FormControlLabel
                    control={<Checkbox sx={{ color: theme.palette.primary.main, borderRadius: 6 }} checked />}
                    label="Full-Time"
                />
                <FormControlLabel control={<Checkbox />} label="Contract" />
                <FormControlLabel control={<Checkbox />} label="Intern" />
            </Grid>
        </>
    );
};
export const Role: React.FC = () => {
    const theme = useTheme();
    return (
        <>
            <Grid item xs={6}>
                <FormControlLabel
                    control={<Checkbox sx={{ color: theme.palette.primary.main, borderRadius: 6 }} checked />}
                    label="Full-Time"
                />
                <FormControlLabel control={<Checkbox />} label="Contract" />
                <FormControlLabel control={<Checkbox />} label="Intern" />
            </Grid>
        </>
    );
};

export const Skill: React.FC = () => {
    const theme = useTheme();
    return (
        <>
            <Grid item xs={6}>
                <FormControlLabel
                    control={<Checkbox sx={{ color: theme.palette.primary.main, borderRadius: 6 }} checked />}
                    label="Full-Time"
                />
                <FormControlLabel control={<Checkbox />} label="Contract" />
                <FormControlLabel control={<Checkbox />} label="Intern" />
            </Grid>
        </>
    );
};
export const Industry: React.FC = () => {
    const theme = useTheme();
    return (
        <>
            <Grid item xs={6}>
                <FormControlLabel
                    control={<Checkbox sx={{ color: theme.palette.primary.main, borderRadius: 6 }} checked />}
                    label="Full-Time"
                />
                <FormControlLabel control={<Checkbox />} label="Contract" />
                <FormControlLabel control={<Checkbox />} label="Intern" />
            </Grid>
        </>
    );
};

export const CompanyType: React.FC = () => {
    const theme = useTheme();
    return (
        <>
            <Grid item xs={6}>
                <FormControlLabel
                    control={<Checkbox sx={{ color: theme.palette.primary.main, borderRadius: 6 }} checked />}
                    label="Full-Time"
                />
                <FormControlLabel control={<Checkbox />} label="Contract" />
                <FormControlLabel control={<Checkbox />} label="Intern" />
            </Grid>
        </>
    );
};
export const ExperienceLevel: React.FC = () => {
    return (
        <>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox />}
                            label={
                                <>
                                    <Typography variant="subtitle1"> Entry Level</Typography>
                                </>
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item>
                        <FormControlLabel control={<Checkbox />} label="Experienced" />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item>
                        <FormControlLabel control={<Checkbox />} label="Lead" />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item>
                        <FormControlLabel control={<Checkbox />} label="Managerial" />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export const Location = () => {
    return (
        <>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox checked />}
                            label={
                                <>
                                    <Typography variant="subtitle1"> Onsite</Typography>
                                </>
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item>
                        <TextField
                            sx={{ width: { xs: '250', md: 'auto' } }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                )
                            }}
                            placeholder="Search Locations"
                            size="small"
                            onChange={() => {}}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item>
                        <FormControlLabel control={<Checkbox />} label="remote" />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

// ==============================|| PRODUCT GRID - FILTER ||============================== //

const JobFilter = ({ filter, handelFilter }: { filter: any; handelFilter: (type: string, params: string, rating?: number) => void }) => {
    const matchDownLG = useMediaQuery((theme: Theme) => theme.breakpoints.down('xl'));
    const handleClearAll = () => {
        window.location.reload();
    };
    const theme = useTheme();
    const filterData = [
        {
            id: 'salary',
            defaultExpand: true,
            title: 'Salary Range',
            content: <SalaryRangeSlider />
        },
        {
            id: 'jobType',
            defaultExpand: true,
            title: 'Job Type',
            content: <JobType />
        },
        {
            id: 'experienceLevel',
            defaultExpand: true,
            title: 'Experience Level',
            content: <ExperienceLevel />
        },
        {
            id: 'location',
            defaultExpand: true,
            title: 'Location',
            content: <Location />
        },
        {
            id: 'industry',
            defaultExpand: true,
            title: 'Industry',
            content: <Industry />
        },
        {
            id: 'role',
            defaultExpand: true,
            title: 'Role',
            content: <Role />
        },
        {
            id: 'skill',
            defaultExpand: true,
            title: 'Skill',
            content: <Skill />
        },
        {
            id: 'companyType',
            defaultExpand: true,
            title: 'Company Type',
            content: <CompanyType />
        }
        // {
        //     id: 'price',
        //     defaultExpand: true,
        //     title: 'Price',
        //     content: <Price price={filter.price} handelFilter={handelFilter} />
        // },
        // {
        //     id: 'rating',
        //     defaultExpand: true,
        //     title: 'Rating',
        //     content: <RatingSection rating={filter.rating} handelFilter={handelFilter} />
        // }
    ];

    return (
        <MainCard border={!matchDownLG} content={false} sx={{ overflow: 'visible' }}>
            <CardContent sx={{ p: 0, height: matchDownLG ? '100vh' : 'auto' }}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} sx={{ marginLeft: 2, marginTop: 2 }}>
                        <Grid container>
                            <Grid item xs={8}>
                                <Typography variant="h4">Filters</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Button onClick={() => handleClearAll}>
                                    <Typography variant="h4" sx={{ color: theme.palette.primary.main }}>
                                        Clear All
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Accordion data={filterData} />
                    </Grid>
                </Grid>
            </CardContent>
        </MainCard>
    );
};

export default JobFilter;
