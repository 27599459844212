const LAYOUT_CONST = {
    VERTICAL_LAYOUT: 'vertical',
    HORIZONTAL_LAYOUT: 'horizontal',
    DEFAULT_DRAWER: 'default',
    MINI_DRAWER: 'mini-drawer'
};

export enum Headers {
    AcceptLanguage = 'accept-language',
    Authorization = 'Authorization',
    ClientRequestId = 'ClientRequestId'
}

export default LAYOUT_CONST;
