import Buffer from './Buffer';
import axios from 'axios';
import {
    ITelemetryData,
    ITelemetryViewData,
    ITelemetryActionData,
    ITelemetryErrorData,
    ITelemetryPayload,
    ITelemetryLevel
} from './Trace.types';

class Trace {
    private static axiosService = axios.create({ baseURL: 'https://traces.intellirecruit.co.in' }); //https://traces.intellirecruit.co.in

    private static buffer: Buffer<any> | null = null;

    public static init() {
        this.buffer = new Buffer<any>();
        setInterval(() => {
            this.send();
        }, 2000);
    }

    public static getBrowserData() {
        const { userAgent } = window.navigator;

        const browserData = {
            browserName: window.navigator.userAgent.split(' ')[0],
            browserVersion: window.navigator.userAgent,
            os: window.navigator.platform,
            osType:
                userAgent.indexOf('Win') !== -1
                    ? 'Windows'
                    : userAgent.indexOf('Mac') !== -1
                    ? 'MacOS'
                    : userAgent.indexOf('X11') !== -1
                    ? 'UNIX'
                    : userAgent.indexOf('Linux') !== -1
                    ? 'Linux'
                    : 'Unknown',
            osVersion:
                userAgent.indexOf('Windows NT 10.0') !== -1
                    ? '10'
                    : userAgent.indexOf('Windows NT 6.2') !== -1
                    ? '8'
                    : userAgent.indexOf('Windows NT 6.1') !== -1
                    ? '7'
                    : userAgent.indexOf('Windows NT 6.0') !== -1
                    ? 'Vista'
                    : userAgent.indexOf('Windows NT 5.1') !== -1
                    ? 'XP'
                    : 'Unknown'
        };
        return browserData;
    }

    public static logView(event: ITelemetryViewData) {
        const payload = {} as ITelemetryPayload;
        payload.component = {
            currentComponent: event.currentComponent,
            nextComponent: event.nextComponent,
            previousComponent: event.previousComponent
        };
        payload.event = {
            eventData: event.eventData,
            subEventData: event.subEventData
        };
        payload.scenario = {
            scenario: event.scenario,
            scenarioData: event.scenarioData
        };
        payload.utm = {
            campaign: event.campaign,
            content: event.content,
            medium: event.medium,
            source: event.source,
            term: event.term
        };
        payload.metadata = {
            browserName: event.browserName,
            browserVersion: event.browserVersion,
            environment: event.environment,
            host: event.host,
            name: event.name,
            platform: event.platform,
            sessionId: event.sessionId,
            time: event.time,
            version: event.version
        };

        this.buffer?.enqueue({ ...payload, level: ITelemetryLevel.View });
    }

    public static logAction(event: ITelemetryActionData) {
        const payload = {} as ITelemetryPayload;
        payload.component = {
            currentComponent: event.currentComponent,
            nextComponent: event.nextComponent,
            previousComponent: event.previousComponent
        };
        payload.event = {
            eventData: event.eventData,
            subEventData: event.subEventData
        };
        payload.scenario = {
            scenario: event.scenario,
            scenarioData: event.scenarioData
        };
        payload.action = {
            actionType: event.actionType,
            gestureIndex: event.gestureIndex
        };
        payload.metadata = {
            browserName: event.browserName,
            browserVersion: event.browserVersion,
            environment: event.environment,
            host: event.host,
            name: event.name,
            platform: event.platform,
            sessionId: event.sessionId,
            time: event.time,
            version: event.version
        };

        this.buffer?.enqueue({ ...payload, level: ITelemetryLevel.Action });
    }

    public static logError(event: ITelemetryErrorData) {
        const payload = {} as ITelemetryPayload;
        payload.component = {
            currentComponent: event.currentComponent,
            nextComponent: event.nextComponent,
            previousComponent: event.previousComponent
        };
        payload.scenario = {
            scenario: event.scenario,
            scenarioData: event.scenarioData
        };
        payload.metadata = {
            browserName: event.browserName,
            browserVersion: event.browserVersion,
            environment: event.environment,
            host: event.host,
            name: event.name,
            platform: event.platform,
            sessionId: event.sessionId,
            time: event.time,
            version: event.version
        };
        payload.error = {
            errorCode: event.errorCode,
            errorMessage: event.errorMessage,
            errorStack: event.errorStack
        };

        this.buffer?.enqueue({ ...payload, level: ITelemetryLevel.Error });
    }

    public static log(event: ITelemetryData) {
        this.buffer?.enqueue(event);
    }

    public static send() {
        const data = [];
        for (let i = 0; i < 10; i++) {
            const event = this.buffer?.dequeue();
            if (event) {
                data.push(event);
            } else {
                break;
            }
        }
        if (data?.length > 0) {
            this.axiosService.post('/log', data);
        }
    }
}

export default Trace;
