import React, { useState, useEffect } from 'react';

// third-party
import { IntlProvider, MessageFormatElement } from 'react-intl';
import useConfig from 'hooks/useConfig';
import { useLocation } from 'react-router-dom';
// import { useParams } from 'react-router-dom';

// load locales files
const loadLocaleData = (locale: string) => {
    if (['hi', 'bn', 'kn', 'ta', 'te', 'gu', 'mr', 'or', 'pa', 'ml'].includes(locale)) {
        return import(`localizers/locales/${locale}`);
    }
    return import('localizers/locales/en-us.json');
};

interface LocalsProps {
    children: React.ReactNode;
}

const Locales = ({ children }: LocalsProps) => {
    const { locale, onChangeLocale } = useConfig();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const hl = queryParams.get('hl') || '';

    useEffect(() => {
        if (hl) {
            onChangeLocale(hl);
        }
    }, [hl]);

    const [messages, setMessages] = useState<Record<string, string> | Record<string, MessageFormatElement[]> | undefined>();

    useEffect(() => {
        // const currentPath: string = window.location.pathname;
        // const userPreferredLocale: string = currentPath.split('/')[1];

        loadLocaleData(locale).then((d: { default: Record<string, string> | Record<string, MessageFormatElement[]> | undefined }) => {
            setMessages(d.default);
        });
    }, [locale]);

    return (
        <>
            {messages && (
                <IntlProvider locale={locale} defaultLocale="en" messages={messages}>
                    {children}
                </IntlProvider>
            )}
        </>
    );
};

export default Locales;
