import React from 'react';
import { Card, CardContent, Grid, Typography, Avatar, Button } from '@mui/material';
import { useTranslation } from 'hooks/useTranslation';
import { grey } from '@mui/material/colors';

const SimilarPeopleAppliedView: React.FC = () => {
    const { t } = useTranslation();
    return (
        <Grid item xs={12}>
            <Card>
                <CardContent>
                    <Grid container rowSpacing={4}>
                        <Grid item xs={12}>
                            <Typography variant="h3" sx={{ marginBottom: 4 }}>
                                {t('People applied for this job also applied for')}
                            </Typography>
                            <Grid container rowSpacing={4}>
                                <Grid item xs={12}>
                                    <Grid container rowSpacing={2}>
                                        <Grid item xs={2}>
                                            <Avatar
                                                sx={{ bgcolor: grey[100], color: 'white', width: 50, height: 50 }}
                                                aria-label="Company Logo"
                                                src="icon.png"
                                            ></Avatar>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Grid item xs={12}>
                                                <Typography variant="h3">Senior Software Engineer</Typography>
                                            </Grid>
                                            <Typography variant="caption">Hyderabad, Telangana, India</Typography>
                                            <Typography variant="h6" fontSize={'small'}>
                                                Google India
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container rowSpacing={2}>
                                        <Grid item xs={2}>
                                            <Avatar
                                                sx={{ bgcolor: grey[100], color: 'white', width: 50, height: 50 }}
                                                aria-label="Company Logo"
                                                src="icon.png"
                                            ></Avatar>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Grid item xs={12}>
                                                <Typography variant="h3">Senior Software Engineer</Typography>
                                            </Grid>
                                            <Typography variant="caption">Hyderabad, Telangana, India</Typography>
                                            <Typography variant="h6" fontSize={'small'}>
                                                Google India
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container rowSpacing={2}>
                                        <Grid item xs={2}>
                                            <Avatar
                                                sx={{ bgcolor: grey[100], color: 'white', width: 50, height: 50 }}
                                                aria-label="Company Logo"
                                                src="icon.png"
                                            ></Avatar>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Grid item xs={12}>
                                                <Typography variant="h3">Senior Software Engineer</Typography>
                                            </Grid>
                                            <Typography variant="caption">Hyderabad, Telangana, India</Typography>
                                            <Typography variant="h6" fontSize={'small'}>
                                                Google India
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid textAlign={'center'} sx={{ mt: 1 }}>
                                <Button>
                                    <Typography>{t('View All Jobs')}</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};
export default SimilarPeopleAppliedView;
