import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ModalContext } from 'contexts/ModalContext';
import { IModalProps } from './Modal.types';

export const Modal: React.FC<IModalProps> = ({ title, children, maxWidth }) => {
    const { removeModalComponent } = React.useContext(ModalContext);

    const handleClose = () => {
        removeModalComponent();
    };

    return (
        <Dialog open={true} onClose={handleClose} maxWidth={maxWidth}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{children}</DialogContentText>
            </DialogContent>
        </Dialog>
    );
};
