import { Card, CardContent, Grid } from '@mui/material';
import React from 'react';
import BasicDetailView from './BasicDetailView';
import ExtraDetailView from './ExtraDetailView';
import SimilarDetailView from './SimilarDetailView';
import SimilarPeopleAppliedView from './SimilarPeopleAppliedView';
import SimilarNearDetailview from './SimilarNearDetailView';
import AboutView from './AboutView';
import MoreJobsView from './MoreJobsView';

const JobBasicDetail: React.FC = () => {
    return (
        <Grid container rowSpacing={2}>
            <Grid item xs={12}>
                <Grid container columnSpacing={2}>
                    <Grid item xs={9}>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                <BasicDetailView />
                            </Grid>
                            <Grid item xs={12}>
                                <Card>
                                    <CardContent>
                                        <ExtraDetailView />
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12}>
                                <Card>
                                    <CardContent>
                                        <AboutView />
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12}>
                                <Card>
                                    <CardContent>
                                        <MoreJobsView />
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container rowSpacing={2}>
                            <Grid item>
                                <SimilarDetailView />
                            </Grid>
                            <Grid item>
                                <SimilarPeopleAppliedView />
                            </Grid>
                            <Grid item>
                                <SimilarNearDetailview />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default JobBasicDetail;
