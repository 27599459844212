import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { styled } from '@mui/material/styles';
import { Box, Button, Grid, Link, Stack, Typography } from '@mui/material';

// third party
import { motion } from 'framer-motion';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';

import { useTranslation } from 'hooks/useTranslation';
// import { Trans } from 'react-i18next';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EastIcon from '@mui/icons-material/East';
import lgSVG from 'assets/images/landing/header/lg.svg';
import { blue } from '@mui/material/colors';
import { TypeAnimation } from 'react-type-animation';

// styles
const HeaderImage = styled('img')(({ theme }) => ({
    maxWidth: '75%',
    borderRadius: 20,
    transformOrigin: theme.direction === 'rtl' ? '100% 50%' : '0 70%',
    [theme.breakpoints.down('sm')]: {
        top: '80vh',
        maxWidth: '100%'
    }
}));

const HeaderSectionLatest = () => {
    const { t } = useTranslation();

    const headerSX = { fontSize: { xs: '2.5rem', sm: '1.5rem', md: '2rem', lg: '3rem' } };

    return (
        // <Container>
        <Grid sx={{ height: '92vh', display: 'flex', alignItems: 'center' }} container>
            <Grid
                container
                spacing={6}
                sx={{
                    marginTop: { sm: '3%', md: '0', lg: '4%' },
                    width: { xs: '100%', sm: '100%', md: '58%', lg: '58%' },
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    zIndex: 2
                }}
            >
                <Grid item xs={12}>
                    <motion.div
                        initial={{ opacity: 0, translateY: 50 }}
                        animate={{ opacity: 1, translateY: 0 }}
                        transition={{ type: 'spring', stiffness: 150, damping: 30 }}
                    >
                        <Stack spacing={1}>
                            <Typography textAlign={'center'} variant="h1" sx={headerSX}>
                                <TypeAnimation
                                    sequence={[
                                        t('One Job Platform for all Jobs'),
                                        3000,
                                        t('One Job Platform for all Recruitments'),
                                        3000,
                                        t('One Job Platform for all Employers'),
                                        3000,
                                        t('One Job Platform for all Job Categories'),
                                        3000,
                                        t('One Job Platform for all Agencies'),
                                        3000,
                                        t('One Job Platform for all Collar workers'),
                                        3000,
                                        t('One Job Platform for all Languages'),
                                        3000
                                    ]}
                                    speed={50}
                                    style={{ display: 'inline-block' }}
                                    repeat={Infinity}
                                />
                            </Typography>
                        </Stack>
                    </motion.div>
                </Grid>
                <Grid item xs={12} sx={{ mt: -2.5, textAlign: { xs: 'center', md: 'center' } }}>
                    <motion.div
                        initial={{ opacity: 0, translateY: 50 }}
                        animate={{ opacity: 1, translateY: 0 }}
                        transition={{ type: 'spring', stiffness: 150, damping: 30, delay: 0.2 }}
                    >
                        <Typography
                            textAlign={'center'}
                            color="text.primary"
                            variant="body1"
                            sx={{ fontSize: { xs: '1rem', md: '1.125rem' } }}
                        >
                            <TypeAnimation
                                sequence={[3000, t('Harnessing Artificial Intelligence for efficient Hiring, Job Search')]}
                                speed={50}
                                style={{ display: 'inline-block' }}
                                repeat={Infinity}
                            />
                        </Typography>
                    </motion.div>
                </Grid>
                <Grid item xs={12}>
                    <motion.div
                        initial={{ opacity: 0, translateY: 50 }}
                        animate={{ opacity: 1, translateY: 0 }}
                        transition={{ type: 'spring', stiffness: 150, damping: 30, delay: 0.4 }}
                    >
                        <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
                            <Grid item>
                                <AnimateButton>
                                    <Button
                                        component={RouterLink}
                                        to="/contact-us"
                                        target="_blank"
                                        size="large"
                                        variant="contained"
                                        color="primary"
                                        disableElevation
                                        startIcon={<AssignmentIcon />}
                                        sx={{ borderRadius: 25, backgroundColor: blue[400] }}
                                    >
                                        {t('Contact Us')}
                                    </Button>
                                </AnimateButton>
                            </Grid>
                            <Grid item>
                                <AnimateButton>
                                    <Button
                                        to="/pilot-launch-survey"
                                        component={RouterLink}
                                        target="_blank"
                                        size="large"
                                        variant="contained"
                                        disableElevation
                                        startIcon={<AssignmentIcon />}
                                        sx={{ borderRadius: 25, backgroundColor: blue[800] }}
                                    >
                                        {t('Pilot Survey')}
                                    </Button>
                                </AnimateButton>
                            </Grid>
                            {/* <Grid item>
                                <Button
                                    component={Link}
                                    href="#"
                                    target="_blank"
                                    size={'large'}
                                    variant="contained"
                                    // color="secondary"
                                    disableElevation
                                    endIcon={<EastIcon />}
                                    sx={{ borderRadius: 25, backgroundColor: blue[800] }}
                                >
                                    {t('Get started')}
                                </Button>
                            </Grid> */}
                        </Grid>
                    </motion.div>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex' }, position: 'relative', mt: 'auto' }}>
                <Box
                    sx={{
                        zIndex: 0,
                        scale: { sm: '0.5', md: '0.8', lg: '1.3' },
                        marginTop: { md: '1%', lg: 0 },
                        position: { sm: 'fixed', md: 'absolute' },
                        bottom: { md: '-85px', lg: '-10px' },
                        left: { md: '-20%', lg: '15%' },
                        right: { md: '-60%', lg: '-30%' }
                    }}
                >
                    <HeaderImage src={lgSVG} alt="Intellirecruit" />
                </Box>
            </Grid>
        </Grid>
        // </Container>
    );
};

export default HeaderSectionLatest;
