import { useIntl, IntlShape } from 'react-intl';

interface TranslationHook {
    t: (id: string, values?: { [key: string]: any }) => string;
}

export const useTranslation = (): TranslationHook => {
    const intl: IntlShape = useIntl();

    const t = (id: string, values?: { [key: string]: any }): any => {
        return intl.formatMessage({ id }, values);
    };

    return { t };
};

export const useDirectTranslate = (id: string, values?: { [key: string]: any }) => {
    const intl: IntlShape = useIntl();
    return intl.formatMessage({ id }, values);
};
