import React, { createContext, useEffect, useReducer } from 'react';

// third-party
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

// project imports
import Loader from 'ui-component/Loader';

import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// types
import { FirebaseContextType, InitialLoginContextProps } from 'types/auth';
// import { useNavigation } from 'hooks/useNavigation';
import { getAuth, GoogleAuthProvider, signInWithCredential } from 'firebase/auth';

// firebase initialize
if (!firebase.apps.length) {
    firebase.initializeApp({
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    });
}

// const
const initialState: InitialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    isAdmin: false,
    user: null
};

const FirebaseContext = createContext<FirebaseContextType | null>(null);

export const FirebaseProvider = ({ children }: { children: React.ReactElement }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);
    // const { navigateTo } = useNavigation();

    useEffect(
        () =>
            firebase.auth().onAuthStateChanged(async (user) => {
                if (user) {
                    const tokenResult = await user.getIdTokenResult();
                    const isAdmin =
                        tokenResult.claims.role === 'ADMIN' || tokenResult.claims.role === 'Admin' || tokenResult.claims.role === 2;

                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user: {
                                id: user.uid,
                                email: user.email!,
                                name: user.displayName || 'user',
                                role: tokenResult.claims.role
                            },
                            isAdmin
                        }
                    });
                    // A user is signed in or has just signed up
                    if (user.metadata.creationTime === user.metadata.lastSignInTime) {
                        // This is a newly created user
                        // navigateTo('/onboarding');
                    }
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch]
    );

    const firebaseEmailPasswordSignIn = (email: string, password: string) => firebase.auth().signInWithEmailAndPassword(email, password);

    const firebaseGoogleSignIn = () => {
        const provider = new firebase.auth.GoogleAuthProvider();

        return firebase.auth().signInWithPopup(provider);
    };

    const googleOneTapSignIn = (response: any) => {
        const auth = getAuth();
        if (response) {
            const cred = GoogleAuthProvider.credential(response.credential);

            // Sign in with credential from the Google user.
            return signInWithCredential(auth, cred);
        }
    };

    const firebaseRegister = async (email: string, password: string) => firebase.auth().createUserWithEmailAndPassword(email, password);

    const logout = () => firebase.auth().signOut();

    const resetPassword = async (email: string) => {
        await firebase.auth().sendPasswordResetEmail(email);
    };

    const updateProfile = () => {};
    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <FirebaseContext.Provider
            value={{
                ...state,
                firebaseRegister,
                firebaseEmailPasswordSignIn,
                login: () => {},
                firebaseGoogleSignIn,
                googleOneTapSignIn,
                logout,
                resetPassword,
                updateProfile
            }}
        >
            {children}
        </FirebaseContext.Provider>
    );
};

export default FirebaseContext;
