import { dispatch } from 'store';
import { IContactUs } from 'types/contactus';
import { openSnackbar } from 'store/slices/snackbar';
import { httpService } from 'utils/http-service';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    error: false,
    data: undefined
};

const slice = createSlice({
    name: 'contactUs',
    initialState: initialState,
    reducers: {
        startLoading(state) {
            state.loading = true;
        },
        hasError(state) {
            state.error = true;
        },
        success(state, action) {
            state.data = action.payload;
            state.error = false;
        },
        stopLoading(state) {
            state.loading = false;
        }
    }
});

export const createContactUs = (payload: Partial<IContactUs>) => {
    return async () => {
        try {
            dispatch(slice.actions.startLoading());
            const response = await httpService.post('/contact-us', payload);
            if (response) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Request sent successfully.',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: false
                    })
                );
                dispatch(slice.actions.success(response));
            } else {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Request failed. Please try again.',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }

            dispatch(slice.actions.stopLoading());
        } catch (error) {
            dispatch(slice.actions.stopLoading());
            dispatch(slice.actions.hasError());
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Request failed. Please try again.',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    }
                })
            );
        }
    };
};

export default slice.reducer;
